import { Button, Stack, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateSelector from "../../../../../shared/components/DateSelector";
import SwipePuller from "../../../../../shared/components/SwipePuller";
import { useLocalization } from "../../../../hooks/useLocalization";
import { resetBalanceFilters, updateBalanceFilters } from "../../../../store/state/dashboard/dashboardSlice";
import {
  selectDashboardBalanceFilters,
  selectDashboardBalanceFiltersApplied,
  selectDashboardBalanceInitialData,
  selectDashboardLoading,
} from "../../../../store/state/dashboard/selectors";
import { fetchBalance } from "../../../../store/state/dashboard/thunk";
import {
  clientCodeSelector,
  financialFundsSelector,
  financialInvestorsSelector,
} from "../../../../store/state/user/selectors";
import { AppDispatch } from "../../../../store/store";
import FundSelectFilter from "../../../common/filters/FundSelectFilter";
import InvestorSelectFilter from "../../../common/filters/InvestorSelectFilter";
import SwipeableFiltersDrawer from "../../common/SwipeableFiltersDrawer";
import { mobileOptionsMenuProps } from "./types";

interface BalanceFiltersDrawerProps {
  open: boolean;
  onClose: () => void;
}

const BalanceFiltersDrawer = ({ open, onClose }: BalanceFiltersDrawerProps) => {
  const dispatch: AppDispatch = useDispatch();
  const balanceLocale = useLocalization().dashboard.balance;
  const balanceInitialDataResponse = useSelector(selectDashboardBalanceInitialData);
  const investors = useSelector(financialInvestorsSelector("BALANCE"));
  const funds = useSelector(financialFundsSelector("BALANCE"));
  const loading = useSelector(selectDashboardLoading);
  const filters = useSelector(selectDashboardBalanceFilters);
  const filtersApplied = useSelector(selectDashboardBalanceFiltersApplied);
  const client = useSelector(clientCodeSelector);

  const clearFilters = () => {
    dispatch(resetBalanceFilters());
    const reportingDate = filters.initialDateIso !== undefined ? parseISO(filters.initialDateIso) : undefined;
    dispatch(fetchBalance({ reportingDate, client }));
  };

  const setSelected = (name: "investors" | "funds") => (values: string | string[]) => {
    dispatch(updateBalanceFilters({ ...filters, [name]: [...values] }));
  };

  const setSelectedReportingDate = (reportingDate: Date) => {
    dispatch(updateBalanceFilters({ ...filters, reportingDateIso: reportingDate.toISOString() }));
    dispatch(fetchBalance({ reportingDate, client }));
  };

  const reportingDateOptions: Date[] = useMemo(() => {
    return balanceInitialDataResponse ? balanceInitialDataResponse.reportingPeriods.map((rp) => new Date(rp)) : [];
  }, [balanceInitialDataResponse]);

  return (
    <SwipeableFiltersDrawer
      open={open}
      onClose={onClose}
      children={
        <>
          <SwipePuller />

          <Stack direction="row" mt={1} py={1} px={2} alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="textPrimary">
              {balanceLocale.filter_title}
            </Typography>
            {filtersApplied && (
              <Button disabled={loading} variant="text" onClick={clearFilters}>
                {balanceLocale.actions.clear_all}
              </Button>
            )}
          </Stack>

          <Stack spacing={2} px={2} pb={3}>
            <InvestorSelectFilter
              investors={investors}
              disabled={loading}
              onSelected={(investors) => setSelected("investors")(investors)}
              selectedValues={filters.investors}
              menuProps={mobileOptionsMenuProps}
            />
            <FundSelectFilter
              funds={funds}
              disabled={loading}
              onSelected={(funds) => setSelected("funds")(funds)}
              selectedValues={filters.funds}
              menuProps={mobileOptionsMenuProps}
            />
            <DateSelector
              placeholder={balanceLocale.reportingPeriod}
              initialDate={filters.reportingDateIso}
              optionDates={reportingDateOptions}
              disabled={loading}
              onChange={setSelectedReportingDate}
              menuProps={mobileOptionsMenuProps}
              isMobile
            />
          </Stack>
        </>
      }
    />
  );
};

export default BalanceFiltersDrawer;
