import { InboxCategoryTags, InboxStructureTree } from "../../../../store/state/inboxStructure/types";
import CategoryItem from "./CategoryItem";
import { useMemo } from "react";

interface Props {
  selectedItem: string;
  inboxStructure: InboxStructureTree;
  onClick: (item: string) => void;
}

export const CustomCategoryTags = (props: Props) => {
  const { inboxStructure, selectedItem, onClick } = props;

  const categoryTags = useMemo(() => {
    const tags = inboxStructure["categoryTags"] as InboxCategoryTags[];
    if (!tags || tags.length === 0) {
      return [];
    }
    return tags;
  }, [inboxStructure]);

  return (
    <>
      {categoryTags.map((item) => (
        <CategoryItem key={item.id} item={item} selectedItem={selectedItem} onClick={() => onClick(item.id)} />
      ))}
    </>
  );
};
export default CustomCategoryTags;
