import { InboxGridConfiguration } from "../inboxStructure/types";

export interface IMessagesState {
  inboxGridConfig: InboxGridConfiguration;
  inboxGridConfigError: boolean;
  inboxGridConfigLoading: boolean;
  messages: Message[];
  currentMessage?: Message;
  loading: MessageLoadingState;
  filterConfiguration?: IFilterConfiguration;
}

export interface Message {
  id: string;
  type: MessageType;
  externalId: string;
  sourceMessageRequestId: string;
  recipient: IRecipient;
  categoryId: string;
  validFrom?: string;
  validTo?: string;
  sendDate: string;
  content: IContent;
  isRead: boolean;
  isImportant: boolean;
  tags?: ITag[] | undefined;
  messageLevel: string;
  hasAttachments: boolean;
  numberOfAttachments: number;
  attachments?: IAttachment[];
  publishingStatus: string;
}

export enum MessageType {
  "Letter" = "Letter",
  "Document" = "Document",
}

export interface IRecipient {
  id: string;
  title: string;
  type: string;
}

export interface IContent {
  subject: string;
  htmlBody?: string;
}

export interface ISearchCriteria {
  paging: {
    page: number;
    size: number;
    totals: boolean;
  };
  isImportant: boolean | undefined;
  investors?: string[];
  entities?: ITag[];
  categories?: string[];
  from?: string;
  to?: string;
  query?: string;
}

export interface ITag {
  caption: string;
  childTags?: ITag[] | undefined;
}

export enum FilterConfigurationLoadingState {
  None = 0,
  Loading = 1,
  Loaded = 2,
  Error = 3,
}

export interface MessageLoadingState {
  markAsImportantUpdating: boolean;
  filterConfiguration: FilterConfigurationLoadingState;
}

export interface IAttachment {
  id: string;
  fileName: string;
  fileSize: number;
  fileSizeAsString: string;
}

export interface FileDownloadInfo {
  id: string;
  fileName: string;
  fileSize: number;
  downloadUrl: string;
  documentViewSessionId?: string;
}

export interface ICategoriesFilterConfiguration {
  key: string;
  value: string;
}

export interface IFilterConfiguration {
  funds: ITag[];
  investors: string[];
  categories: ICategoriesFilterConfiguration[];
}

export interface TranslateIdsRequest {
  externalMessageId: string;
  externalAttachmentId: string;
}

export interface TranslateIdsResponse {
  messageId: string;
  attachmentId: string;
}

export enum systemTags {
  "Inbox" = "Inbox",
  "Important" = "Important",
}

export const isSystemTag = (tag: string) => {
  return systemTags.Important === tag || systemTags.Inbox === tag;
};
