import { Button } from "@mui/material";

interface Props {
  onClick: () => void;
}

const CloseFilterButton = ({ onClick }: Props) => (
  <Button variant="text" size="large" color="secondary" sx={{ pl: "1rem", pb: ".5rem" }} onClick={onClick}>
    Close
  </Button>
);

export default CloseFilterButton;
