import { Breakpoints, Breakpoint as MuiBreakpoint, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC, PropsWithChildren } from "react";

interface Props {
  from?: MuiBreakpoint | number;
  to?: MuiBreakpoint | number;
}

const Breakpoint: FC<PropsWithChildren<Props>> = (props) => {
  const getMediaQuery = ({ from, to }: Props, breakpoints: Breakpoints) => {
    if (from === undefined) {
      return to === undefined ? "" : breakpoints.down(to);
    }
    if (to === undefined) {
      return breakpoints.up(from);
    }
    return breakpoints.between(from, to);
  };

  const theme = useTheme();
  const shouldRender = useMediaQuery(getMediaQuery(props, theme.breakpoints));
  return shouldRender ? <>{props.children}</> : null;
};

export default Breakpoint;
