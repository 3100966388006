import { Typography } from "@mui/material";

const NoDataResult = ({ text }: { text?: string | undefined }) => {
  return (
    <Typography color="secondary" sx={{ p: 1, textAlign: "center" }}>
      {text || "No Data"}
    </Typography>
  );
};

export default NoDataResult;
