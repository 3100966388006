import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Divider, ListItemIcon, ListItemText, MenuItem, MenuProps, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { DescriptiveDate } from "../../../../shared/utilities/dateFilterHelper";
import CustomDatePopup, { customDescriptiveDate } from "./CustomDatePopup";
import FilterAutocomplete from "./FilterAutocomplete";

interface Props {
  menuProps?: Partial<MenuProps>;
  descriptiveDate?: DescriptiveDate;
  onSelected: (date: DescriptiveDate) => void;
  options: DescriptiveDate[];
  disabled?: boolean;
  hideCustomDateRange?: boolean;
}

export const DateSelectFilter = ({
  descriptiveDate,
  menuProps,
  onSelected,
  disabled,
  options,
  hideCustomDateRange,
}: Props) => {
  const [customDateElRef, setCustomDateElRef] = useState<Element | null>(null);
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);

  const dates = useMemo(() => {
    const customDate =
      descriptiveDate?.title === customDescriptiveDate.title
        ? { ...customDescriptiveDate, ...descriptiveDate }
        : { ...customDescriptiveDate };

    return [...options, customDate];
  }, [descriptiveDate, options]);

  const onFilterDateSelected = useCallback(
    (date: string) => {
      const descriptiveDate = dates.find((d) => d.title === date);
      if (descriptiveDate) {
        onSelected(descriptiveDate);
      }
    },
    [dates, onSelected]
  );

  const onCustomFilterDateSelected = useCallback(
    (descriptiveDate: DescriptiveDate) => {
      if (descriptiveDate) {
        onSelected(descriptiveDate);
      }
    },
    [onSelected]
  );

  return (
    <>
      <FilterAutocomplete
        disabled={disabled}
        refEl={(r) => setCustomDateElRef(r)}
        selectedValues={descriptiveDate?.title || ""}
        multiple={false}
        onSelected={(value) => {
          const title = value as string;
          if (title === customDescriptiveDate.title) {
            setShowCustomDateRange(true);
          } else {
            onFilterDateSelected(title);
          }
        }}
        startIcon={<CalendarTodayOutlinedIcon color="secondary" />}
        placeholder={"Date"}
        renderValue={(values) => {
          const selected = values as string;
          if (selected === "") {
            return "Date";
          }
          const descriptiveDate = dates.find((d) => d.title === selected);
          if (descriptiveDate) {
            return descriptiveDate.description || descriptiveDate.title;
          }
          return selected;
        }}
        menuProps={menuProps}
      >
        {dates.map((date: DescriptiveDate) => {
          const isSelected = descriptiveDate?.title === date.title;
          return [
            date.title === customDescriptiveDate.title ? <Divider /> : null,
            <MenuItem
              key={date.title}
              value={date.title}
              selected={isSelected}
              sx={{ display: "flex", flex: 1 }}
              onClick={() => {
                if (date.title === customDescriptiveDate.title) {
                  setShowCustomDateRange(true);
                }
              }}
            >
              <ListItemIcon sx={{ visibility: isSelected ? "inherit" : "hidden" }}>
                <CheckOutlinedIcon color="primary" />
              </ListItemIcon>

              <ListItemText disableTypography>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="17rem">
                  <Typography>{date.title}</Typography>
                  <Typography color="text.secondary">{date.description}</Typography>
                </Stack>
              </ListItemText>
            </MenuItem>,
          ];
        })}
      </FilterAutocomplete>

      {!hideCustomDateRange && (
        <CustomDatePopup
          descriptiveDate={descriptiveDate}
          options={options}
          anchorEl={customDateElRef}
          open={showCustomDateRange}
          menuProps={menuProps}
          onFilterDateSelected={onCustomFilterDateSelected}
          onClose={() => setShowCustomDateRange(false)}
        />
      )}
    </>
  );
};

export default DateSelectFilter;
