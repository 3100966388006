import { Stack } from "@mui/material";
import BankAccountCard from "./BankAccountCard";
import { useBankAccountsContext } from "./BankAccountsContext";

const BankAccountCardsView = () => {
  const { bankAccounts } = useBankAccountsContext();

  return (
    <Stack spacing={1}>
      {bankAccounts.map((bankAccount) => (
        <BankAccountCard key={bankAccount.id} bankAccount={bankAccount} />
      ))}
    </Stack>
  );
};

export default BankAccountCardsView;
