import { MenuItem, Select } from "@mui/material";
import { InvestorPermissions } from "../../../store/state/user/types";

interface Props {
  selectedId: string;
  investors: InvestorPermissions[];
  onChange: (investorId: string) => void;
}

const InvestorsMenuSelect = ({ selectedId, investors, onChange }: Props) => {
  return (
    <Select
      value={selectedId}
      onChange={({ target }) => onChange(target.value)}
      sx={{ width: "100%", fontWeight: 500 }}
      MenuProps={{ sx: { maxWidth: "25rem" } }}
    >
      {investors.map((investor) => {
        return (
          <MenuItem key={investor.investorId} value={investor.investorId} sx={{ whiteSpace: "normal" }}>
            {investor.investorTitle}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default InvestorsMenuSelect;
