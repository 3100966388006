import { useBankAccountsContext } from "./BankAccountsContext";
import FundAssignmentsTable from "./FundAssignmentsTable";
import { getFundAssignmentsTableRows } from "./fundAssignmentsTableDataProvider";
import { useBankSettingsNavigation } from "./navigation";

const FundAssignmentsView = () => {
  const { bankAccounts, fundAssignments, availableFundInvestorIds, fundInvestors } = useBankAccountsContext();
  const { navigateToChangeFundAssignmentsPage } = useBankSettingsNavigation();

  const handleChangeAssignment = (fundInvestorId: string, fundName: string, bankAccountId: string) => {
    navigateToChangeFundAssignmentsPage({
      fundInvestorId,
      fundName,
      bankAccountId,
      bankAccounts,
    });
  };

  const tableRows = getFundAssignmentsTableRows({
    availableFundInvestorIds,
    fundAssignments,
    fundInvestors,
  });

  return <FundAssignmentsTable rows={tableRows} onChangeAssignment={handleChangeAssignment} />;
};

export default FundAssignmentsView;
