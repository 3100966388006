import { Typography } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { filterCategoriesSelector } from "../../../store/state/messages/selectors";

export const MessageLevelComponent = (props: { level: string; value: string }) => {
  const { level, value } = props;

  const categories = useSelector(filterCategoriesSelector);

  const categoriesDict = useMemo(() => {
    return Object.assign({}, ...(categories || []).map((x) => ({ [x.key]: x.value }))) as { [key: string]: string };
  }, [categories]);

  const color = useMemo(() => {
    switch (level) {
      case "Fund":
        return "rgba(33, 150, 243, 0.1)";

      case "Investor":
        return "rgba(76, 175, 80, 0.1)";

      default:
        return "rgba(175, 76, 76, 0.1)";
    }
  }, [level]);

  return (
    <Typography
      variant="caption"
      sx={{ backgroundColor: color, borderRadius: "16px", px: ".6rem", lineHeight: "1.7em" }}
    >
      {categoriesDict[value] || ""}
    </Typography>
  );
};
