import ArrowRightIcon from "@mui/icons-material/ArrowForwardRounded";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ClientPermissionSet } from "../../store/state/user/types";

interface Props {
  clientPermissions: ClientPermissionSet;
}

const ClientSelectionCard = ({ clientPermissions }: Props) => {
  return (
    <Paper variant="outlined" square={false}>
      <Box width="100%" height="3rem" p={1} display="flex" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <img
            alt="Client Logo Small"
            style={{ width: 32, height: 32 }}
            src={`${clientPermissions.portalSettings?.logoMarkUrl}`}
          />
          <Typography variant="subtitle1">{clientPermissions.clientTitle}</Typography>
        </Stack>
        <Button
          to={`/${clientPermissions.clientCode}`}
          component={RouterLink}
          variant="text"
          endIcon={<ArrowRightIcon />}
        >
          Open
        </Button>
      </Box>
    </Paper>
  );
};

export default ClientSelectionCard;
