import { useSelector } from "react-redux";
import { useClientContext } from "../../../../contexts/ClientContext";
import { hasPermissionSelector, workspaceInvestorSelector } from "../../../../store/state/user/selectors";
import { RootState } from "../../../../store/store";
import LpSettingsWrapper from "../LpSettingsWrapper";
import EditAccountSettings from "./EditAccountSettings";

export const AccountSettings = () => {
  const { clientCode, isFeatureEnabled } = useClientContext();
  const workspaceInvestor = useSelector(workspaceInvestorSelector);

  const hasEditPermissions = useSelector(
    (state: RootState) =>
      !!workspaceInvestor &&
      hasPermissionSelector(state, clientCode, workspaceInvestor.investorId, "ManageInvestorSettings")
  );

  const canEdit = hasEditPermissions && isFeatureEnabled("AccountSettingsEditor");

  return (
    <LpSettingsWrapper title="Investor Details" subtitle={workspaceInvestor?.investorTitle}>
      {workspaceInvestor && (
        <EditAccountSettings
          key={workspaceInvestor.investorId}
          canEdit={canEdit}
          investorId={workspaceInvestor.investorId}
        />
      )}
    </LpSettingsWrapper>
  );
};

export default AccountSettings;
