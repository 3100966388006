import { useCallback, useState } from "react";
import useEventListener from "./useEventListener";

interface Params {
  sensitivity: number;
}

export const useScrollEvent = ({ sensitivity }: Params) => {
  const [pageOffset, setPageOffset] = useState(window.scrollY);

  const onScroll = useCallback(() => {
    if (Math.abs(window.scrollY - pageOffset) >= sensitivity) {
      setPageOffset(window.scrollY);
    }
  }, [pageOffset, sensitivity]);

  useEventListener(window, "scroll", onScroll);
  return pageOffset;
};
