import { MenuItem } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  selectedItem: string;
  item: string;
  onClick: () => void;
}

export const Item = (props: PropsWithChildren<Props>) => {
  const { item, selectedItem, children, onClick } = props;
  return (
    <MenuItem
      key={item}
      sx={(theme) => ({ borderRadius: "4px", height: "42px", px: "6px", color: theme.palette.text.secondary })}
      selected={selectedItem === item}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  );
};
export default Item;
