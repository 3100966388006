import React, { PropsWithChildren, useContext } from "react";
import { useSelector } from "react-redux";
import { defined } from "../../shared/utilities/typeHelper";
import { permissionsSelector } from "../store/state/user/selectors";
import { InvestorRelationPortalFeatures, PortalSettings } from "../store/state/user/types";

const ClientContext = React.createContext<Client | undefined>(undefined);

interface Client extends Props {
  isFeatureEnabled: (feature: InvestorRelationPortalFeatures) => boolean;
  isAnyFeatureEnabled: (features: InvestorRelationPortalFeatures[]) => boolean;
  hasAccessByPermissions: (feature: InvestorRelationPortalFeatures) => boolean;
}

interface Props {
  clientCode: string;
  clientTitle: string;
  fund: string;
  portalSettings: PortalSettings | undefined;
  isImpersonationMode: boolean;
}

const getEnabledFeatures = (features: string | undefined) =>
  features === undefined
    ? new Set<InvestorRelationPortalFeatures>()
    : new Set<InvestorRelationPortalFeatures | string>(features.split(",").map((f) => f.trim()));

export const ClientContextProvider = ({
  clientCode,
  clientTitle,
  fund,
  portalSettings,
  isImpersonationMode,
  children,
}: PropsWithChildren<Props>) => {
  const permissions = useSelector(permissionsSelector);
  const enabledLiveFeatures = getEnabledFeatures(portalSettings?.enabledFeatures);
  const enabledPreviewFeatures = getEnabledFeatures(portalSettings?.enabledPreviewOnlyFeatures);

  const hasAccessByPermissions = (feature: InvestorRelationPortalFeatures) => {
    const clientPermissions = permissions.find((p) => p.clientCode === clientCode);
    if (!clientPermissions) {
      return false;
    }

    switch (feature) {
      case "Inbox": {
        return clientPermissions.messageCategoryIds.length > 0;
      }

      case "Dashboards": {
        return clientPermissions.financialPermissions.some((fp) => fp.investor.length > 0);
      }

      case "Fundraising": {
        return clientPermissions.fundraisings.length > 0;
      }

      default: {
        return true;
      }
    }
  };

  const isFeatureEnabled = (feature: InvestorRelationPortalFeatures) =>
    hasAccessByPermissions(feature) &&
    (enabledLiveFeatures.has(feature) || (isImpersonationMode && enabledPreviewFeatures.has(feature)));

  const isAnyFeatureEnabled = (features: InvestorRelationPortalFeatures[]) =>
    features.some((feature) => isFeatureEnabled(feature));

  return (
    <ClientContext.Provider
      value={{
        clientCode,
        clientTitle,
        fund,
        portalSettings,
        isImpersonationMode,
        hasAccessByPermissions,
        isFeatureEnabled,
        isAnyFeatureEnabled,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => {
  const client = useContext(ClientContext);
  return defined(client);
};
