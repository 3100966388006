import { LinearProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  selectCashFlowData,
  selectCashFlowError,
  selectCashFlowLoading,
  selectCashFlowRequestData,
} from "../../../store/state/dashboard/selectors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { fetchCashFlowData } from "../../../store/state/dashboard/thunk";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import CenteredWrapper from "../../../../shared/components/CenteredWrapper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import { logError } from "../../../../shared/logging";
import { CapCallOrDistribution } from "../../../store/state/dashboard/types";

interface CashFlowsViewProps {
  renderItemsElement: (capCalls: CapCallOrDistribution[]) => React.ReactNode;
}

const CashFlowsView = ({ renderItemsElement }: CashFlowsViewProps) => {
  const cashFlowLading = useSelector(selectCashFlowLoading);
  const cashFlowError = useSelector(selectCashFlowError);
  const cashFlowCapCalls = useSelector(selectCashFlowData);
  const cashFlowRequest = useSelector(selectCashFlowRequestData);
  const dispatch: AppDispatch = useDispatch();

  if (cashFlowError) {
    logError(cashFlowError, "CashFlowWidget");
  }

  const loadNext = () => {
    if (cashFlowRequest) {
      dispatch(
        fetchCashFlowData({
          ...cashFlowRequest,
          paging: { page: cashFlowRequest.paging.page + 1, size: cashFlowRequest.paging.size },
        })
      );
    }
  };

  const capCalls = cashFlowCapCalls
    ? [...new Map(cashFlowCapCalls.map((item) => [item["id"], item])).values()]
    : undefined;

  const hasMore =
    capCalls &&
    cashFlowRequest &&
    capCalls.length > 0 &&
    cashFlowRequest.paging.page * cashFlowRequest.paging.size < capCalls.length;

  const showInfiniteLoader = hasMore && cashFlowRequest.paging.page > 0;

  return (
    <>
      {!cashFlowError && capCalls !== undefined && (
        <InfiniteScroll
          dataLength={capCalls.length}
          next={loadNext}
          hasMore={!!hasMore}
          loader={showInfiniteLoader && <LinearProgress sx={{ width: "33%", margin: "0 auto", my: 3 }} />}
        >
          {capCalls && renderItemsElement(capCalls)}
        </InfiniteScroll>
      )}
      {cashFlowLading && (
        <CenteredWrapper position={"fixed"}>
          <InlineLoader />
        </CenteredWrapper>
      )}
      {cashFlowError && (
        <CenteredWrapper>
          <DataLoadingFailed />
        </CenteredWrapper>
      )}
    </>
  );
};

export default CashFlowsView;
