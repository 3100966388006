import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  title: string;
}

const SettingsMenuSection = ({ title, children }: PropsWithChildren<Props>) => (
  <Stack spacing={0.5} width="100%">
    <Typography variant="subtitle2">{title}</Typography>
    {children}
  </Stack>
);

export default SettingsMenuSection;
