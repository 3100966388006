import { createTheme, darken, ThemeProvider } from "@mui/material";
import { DefaultTheme } from "@mui/styles";
import { ReactNode, useCallback } from "react";
import { lightThemeOptions } from "../../shared/theme";
import { useClientContext } from "../contexts/ClientContext";

const CustomThemeProvider = (props: { children?: ReactNode | undefined }) => {
  const { clientTitle, portalSettings } = useClientContext();

  const applyFavicon = useCallback((url: string | undefined) => {
    const favicon = document.head.querySelector<HTMLLinkElement>("link[rel=icon]");
    if (favicon) {
      favicon.href = url || "/favicon.ico";
    }
  }, []);

  const applyPageTitle = useCallback((title: string) => {
    document.title = `${title} Investor Portal`;
  }, []);

  const applyThemeCustomization = useCallback(
    (outerTheme: DefaultTheme) => {
      if (portalSettings) {
        const local = createTheme(lightThemeOptions);
        if (portalSettings.mainColor) {
          local.palette.primary.main = portalSettings.mainColor;
          local.palette.primary.dark = darken(portalSettings.mainColor, 0.2);
        }

        applyFavicon(portalSettings.faviconUrl);
        applyPageTitle(clientTitle);

        return local;
      } else {
        return outerTheme;
      }
    },
    [portalSettings, applyFavicon, applyPageTitle, clientTitle]
  );

  return <ThemeProvider theme={(outerTheme) => applyThemeCustomization(outerTheme)}>{props.children}</ThemeProvider>;
};

export default CustomThemeProvider;
