import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { permissionsSelector } from "../../store/state/user/selectors";
import ClientSelectionCard from "./ClientSelectionCard";

const ClientSelectionList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const permissions = useSelector(permissionsSelector);

  if (permissions.length === 0) {
    return null;
  }

  return (
    <Stack spacing={2.5} px={1} alignItems="center">
      <Typography color="text.secondary">
        In the meantime, you can navigate to other portals you have access to within Entrilia.
      </Typography>
      <Stack spacing={1} width={isMobile ? "90vw" : "25rem"}>
        {permissions.map((clientPermissions) => (
          <ClientSelectionCard key={clientPermissions.clientCode} clientPermissions={clientPermissions} />
        ))}
      </Stack>
    </Stack>
  );
};

export default ClientSelectionList;
