import { ListItemText, Typography } from "@mui/material";
import { InboxCategoryTags } from "../../../../store/state/inboxStructure/types";
import DynamicIcon from "../DynamicIcon";
import Item from "./Item";

interface Props {
  selectedItem: string;
  item: InboxCategoryTags;
  onClick: () => void;
}

export const CategoryItem = (props: Props) => {
  const { item, onClick, selectedItem } = props;
  return (
    <Item item={item.name} selectedItem={selectedItem} onClick={onClick}>
      <DynamicIcon iconName={item.iconCode} />
      <ListItemText>
        <Typography variant="subtitle2" pl={1}>
          {item.name}
        </Typography>
      </ListItemText>
    </Item>
  );
};
export default CategoryItem;
