import { Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { getStatusCodeFromError } from "../../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../../shared/api/types";
import { logError } from "../../../../shared/logging";
import { isImpersonation } from "../../../../shared/variables";
import { api } from "../../../api/client";
import { setImpersonationHeaders } from "../../../impersonation/impersonationHandler";
import { UserActions } from "./actions";
import { UserPayload } from "./reducer";
import { Identity, IdentityLoadingState, UserPermissions } from "./types";

type UserDispatch = Dispatch<PayloadAction<UserPayload>>;

export function loadLargeAvatar() {
  return async (dispatch: UserDispatch) => {
    dispatch(UserActions.UpdateLoading({ identity: true }));
    try {
      const response = await api.user.loadIdentity(true);
      if (response.success) {
        dispatch(UserActions.UpdateIdentity({ ...response.data, largeImage: true }));
      } else if (response.error) {
        logError(response.error.message, "loadLargeAvatar");
      }
    } catch (error) {
      logError(error, "loadLargeAvatar");
    } finally {
      dispatch(UserActions.UpdateLoading({ identity: false }));
    }
  };
}

export function loadUserInfo(client: string | undefined) {
  return async (dispatch: UserDispatch) => {
    try {
      dispatch(UserActions.UpdateLoading({ identity: true }));

      let userPermissionsResp: ApiResponse<UserPermissions>;
      let userIdentityResp: ApiResponse<Identity>;

      if (isImpersonation()) {
        userPermissionsResp = await api.user.loadPermissions();
        setImpersonationHeaders(client, userPermissionsResp.data?.email);
        userIdentityResp = await api.user.loadIdentity();
      } else {
        [userPermissionsResp, userIdentityResp] = await Promise.all([
          api.user.loadPermissions(),
          api.user.loadIdentity(),
        ]);
      }

      if (userPermissionsResp.data && userIdentityResp.data) {
        dispatch(UserActions.UpdateState({ ...userPermissionsResp.data, identity: { ...userIdentityResp.data } }));
        dispatch(UserActions.UpdateLoading({ identity: false, state: IdentityLoadingState.Loaded }));
      } else {
        if (userPermissionsResp.error) {
          logError(userPermissionsResp.error.message, "loadPermissions");
        }
        if (userIdentityResp.error) {
          logError(userIdentityResp.error.message, "loadIdentity");
        }
        dispatch(UserActions.UpdateLoading({ identity: false, state: IdentityLoadingState.LoadingError }));
      }
    } catch (error) {
      logError(error, "loadUserInfo");
      if (getStatusCodeFromError(error) === 401) {
        if (isImpersonation()) {
          dispatch(
            UserActions.UpdateLoading({
              identity: false,
              state: IdentityLoadingState.LoadingErrorImpersonationPermissions,
            })
          );
        } else {
          dispatch(
            UserActions.UpdateLoading({ identity: false, state: IdentityLoadingState.AuthorizationOrAccessError })
          );
        }
      } else {
        dispatch(UserActions.UpdateLoading({ identity: false, state: IdentityLoadingState.LoadingError }));
      }
    }
  };
}
