import { AppBar, Dialog, DialogContent, SxProps, Theme, Toolbar, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PropsWithChildren, ReactNode } from "react";
import { useClientContext } from "../../contexts/ClientContext";

interface ModalPageProps {
  toolbarChildren?: ReactNode;
  open: boolean;
  sxContent?: SxProps<Theme>;
}

const ModalPage = ({ open, toolbarChildren, children, sxContent }: PropsWithChildren<ModalPageProps>) => {
  const theme = useTheme();
  const { portalSettings } = useClientContext();
  const appBarTextColor = portalSettings?.appBarTextColor || theme.palette.common.white;
  return (
    <Dialog
      fullScreen
      open={open}
      scroll="paper"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: grey[50],
        },
      }}
    >
      <AppBar
        sx={(theme) => ({
          backgroundColor: portalSettings?.appBarColor || theme.palette.primary.main,
          color: appBarTextColor,
          "&.MuiPaper-root": { boxShadow: "none" },
        })}
        position="relative"
      >
        <Toolbar sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}>{toolbarChildren}</Toolbar>
      </AppBar>

      <DialogContent sx={sxContent}>{children}</DialogContent>
    </Dialog>
  );
};

export default ModalPage;
