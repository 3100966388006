import { useSelector } from "react-redux";
import { MenuProps } from "@mui/material";
import { filterCategoriesSelector } from "../../../../../store/state/messages/selectors";
import FilterAutocomplete from "../../../../common/filters/FilterAutocomplete";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useInboxStateContext } from "../../../../../contexts/InboxStateContext";
import { useCallback } from "react";

interface Props {
  menuProps?: Partial<MenuProps> | undefined;
}

export const DocumentSelect = (props: Props) => {
  const { menuProps } = props;

  const { updateCriteria } = useInboxStateContext();

  const categories = useSelector(filterCategoriesSelector);
  const { criteria } = useInboxStateContext();

  const updateDocuments = useCallback(
    (value: string | string[]) => {
      const allCategories = categories || [];
      if (Array.isArray(value)) {
        updateCriteria({ categories: allCategories.filter((c) => value.includes(c.value)).map((k) => k.key), page: 0 });
      } else {
        updateCriteria({ categories: allCategories.filter((c) => c.value === value).map((k) => k.key), page: 0 });
      }
    },
    [updateCriteria, categories]
  );

  return (
    <FilterAutocomplete
      values={(categories || []).map((c) => c.value)}
      selectedValues={(categories || []).filter((c) => criteria.categories?.includes(c.key)).map((k) => k.value) || []}
      onSelected={updateDocuments}
      startIcon={<DescriptionOutlinedIcon color="secondary" />}
      placeholder={"Document"}
      menuProps={menuProps}
    />
  );
};

export default DocumentSelect;
