import { Message } from "./types";

export const getFundFromMessage = (message: Message) => {
  const childTags = message.tags?.flatMap((tag) => tag.childTags);
  if (childTags && childTags.length > 0) {
    return childTags[0]?.caption;
  }

  return undefined;
};

export const getRecipientsFromMessage = (message: Message) => {
  const tagCaptions = message.tags?.flatMap((tag) => tag.caption);
  if (tagCaptions && tagCaptions.length > 0) {
    return tagCaptions.length === 1 ? tagCaptions[0] : "-";
  }

  return undefined;
};

export const getEntityFromMessage = (message: Message) => {
  const secondLevelChildTags = message.tags?.flatMap((tag) => tag.childTags?.flatMap((tag) => tag.childTags));

  if (secondLevelChildTags && secondLevelChildTags.length > 0) {
    return secondLevelChildTags[0]?.caption;
  }

  return undefined;
};
