import { MenuProps } from "@mui/material";
import {
  anytime,
  DescriptiveDate,
  last30Days,
  lastMonth,
  lastQuarter,
  lastSevenDays,
  lastYear,
  thisMonth,
  thisQuarter,
  thisYear,
  today,
} from "../../../../../../shared/utilities/dateFilterHelper";
import { useInboxStateContext } from "../../../../../contexts/InboxStateContext";
import DateSelectFilter from "../../../../common/filters/DateSelectFilter";

interface Props {
  menuProps?: Partial<MenuProps>;
}

const filterDateItems = [
  anytime,
  today,
  lastSevenDays,
  last30Days,
  thisMonth,
  thisQuarter,
  thisYear,
  lastMonth,
  lastQuarter,
  lastYear,
];

export const FilterDate = (props: Props) => {
  const { menuProps } = props;

  const { updateCriteria, criteria } = useInboxStateContext();

  const onDateSelected = (descriptiveDate: DescriptiveDate) => {
    updateCriteria({
      from: descriptiveDate.from,
      to: descriptiveDate.to,
      descriptiveDate: descriptiveDate,
      page: 0,
    });
  };

  return (
    <DateSelectFilter
      menuProps={menuProps}
      descriptiveDate={criteria.descriptiveDate}
      options={filterDateItems}
      onSelected={onDateSelected}
    />
  );
};

export default FilterDate;
