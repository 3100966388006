import { ListItemIcon, MenuItem, Select, Typography } from "@mui/material";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useClientContext } from "../../contexts/ClientContext";
import { permissionsSelector } from "../../store/state/user/selectors";
import { RelativeLogoSize } from "../../store/state/user/types";

const getLogoHeight = (size: RelativeLogoSize | undefined) => {
  switch (size) {
    case "Small":
      return 40;
    case "Medium":
      return 48;
    case "Large":
      return 56;
    default:
      return 40;
  }
};

export const ClientsSelector = () => {
  const { clientCode } = useClientContext();
  const permissions = useSelector(permissionsSelector);
  const navigate = useNavigate();

  const getClientPortalSettings = useCallback(
    (clientCode: string) => permissions.find((ps) => ps.clientCode === clientCode)?.portalSettings,
    [permissions]
  );

  return (
    <Select
      readOnly={permissions.length === 1}
      contentEditable={false}
      value={clientCode}
      variant="outlined"
      sx={(theme) => ({
        ".MuiSelect-select": {
          py: 0,
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ".MuiSelect-icon": {
          display: permissions.length === 1 ? "none" : "inline-block",
        },
        [theme.breakpoints.down("sm")]: {
          ml: "0.5rem",
        },
      })}
      renderValue={(value) => {
        const clientSettings = getClientPortalSettings(value);
        const clientLogoUrl = clientSettings?.logoTypeUrl;
        const clientLogoSize = clientSettings?.logoTypeSize;
        return (
          <img
            alt="Client Logo"
            style={{ maxHeight: getLogoHeight(clientLogoSize), marginTop: 4 }}
            src={`${clientLogoUrl}`}
          />
        );
      }}
      onChange={({ target }) => navigate(`/${target.value}`)}
    >
      {permissions.map((permission) => (
        <MenuItem key={permission.clientCode} value={permission.clientCode}>
          <ListItemIcon>
            <img
              alt="Client Logo Small"
              style={{ width: 20, height: 20 }}
              src={`${getClientPortalSettings(permission.clientCode)?.logoMarkUrl}`}
            />
          </ListItemIcon>
          <Typography>{permission.clientTitle}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default ClientsSelector;
