import { useSelector } from "react-redux";
import NoAccessPage from "../../../shared/components/NoAccessPage";
import { userEmailSelector } from "../../store/state/user/selectors";
import ClientSelectionList from "./ClientSelectionList";

const ClientSelectionPage = () => {
  const userEmail = useSelector(userEmailSelector);

  return (
    <NoAccessPage userEmail={userEmail}>
      <ClientSelectionList />
    </NoAccessPage>
  );
};

export default ClientSelectionPage;
