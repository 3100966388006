import { SvgIcon, SvgIconProps } from "@mui/material";

export const TaxInformationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99998 0.399994C2.36346 0.399994 1.75301 0.65285 1.30292 1.10294C0.852832 1.55302 0.599976 2.16347 0.599976 2.79999V19.6L4.79998 17.2L8.99998 19.6L13.2 17.2L17.4 19.6V2.79999C17.4 2.16347 17.1471 1.55302 16.697 1.10294C16.2469 0.65285 15.6365 0.399994 15 0.399994H2.99998ZM5.99998 3.99999C5.52259 3.99999 5.06475 4.18964 4.72718 4.5272C4.38962 4.86477 4.19998 5.3226 4.19998 5.79999C4.19998 6.27738 4.38962 6.73522 4.72718 7.07279C5.06475 7.41035 5.52259 7.59999 5.99998 7.59999C6.47737 7.59999 6.9352 7.41035 7.27277 7.07279C7.61033 6.73522 7.79997 6.27738 7.79998 5.79999C7.79997 5.3226 7.61033 4.86477 7.27277 4.5272C6.9352 4.18964 6.47737 3.99999 5.99998 3.99999ZM13.4484 4.35159C13.2233 4.12663 12.9182 4.00025 12.6 4.00025C12.2818 4.00025 11.9766 4.12663 11.7516 4.35159L4.55158 11.5516C4.43696 11.6623 4.34554 11.7947 4.28265 11.9411C4.21976 12.0875 4.18666 12.245 4.18527 12.4043C4.18389 12.5636 4.21425 12.7217 4.27459 12.8691C4.33493 13.0166 4.42403 13.1506 4.5367 13.2633C4.64937 13.3759 4.78335 13.465 4.93083 13.5254C5.07831 13.5857 5.23632 13.6161 5.39566 13.6147C5.55499 13.6133 5.71246 13.5802 5.85886 13.5173C6.00527 13.4544 6.13768 13.363 6.24837 13.2484L13.4484 6.04839C13.6733 5.82336 13.7997 5.51819 13.7997 5.19999C13.7997 4.8818 13.6733 4.57663 13.4484 4.35159ZM12 9.99999C11.5226 9.99999 11.0647 10.1896 10.7272 10.5272C10.3896 10.8648 10.2 11.3226 10.2 11.8C10.2 12.2774 10.3896 12.7352 10.7272 13.0728C11.0647 13.4104 11.5226 13.6 12 13.6C12.4774 13.6 12.9352 13.4104 13.2728 13.0728C13.6103 12.7352 13.8 12.2774 13.8 11.8C13.8 11.3226 13.6103 10.8648 13.2728 10.5272C12.9352 10.1896 12.4774 9.99999 12 9.99999Z"
      />
    </SvgIcon>
  );
};

export default TaxInformationIcon;
