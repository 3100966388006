import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { MenuProps } from "@mui/material";
import FilterAutocomplete from "./FilterAutocomplete";

interface Props {
  disabled?: boolean;
  investors: string[];
  selectedValues: string | string[];
  onSelected: (values: string | string[]) => void;
  menuProps?: Partial<MenuProps> | undefined;
}

export const InvestorSelectFilter = ({ investors, onSelected, selectedValues, disabled, menuProps }: Props) => {
  return (
    <FilterAutocomplete
      disabled={disabled}
      values={investors}
      selectedValues={selectedValues}
      onSelected={onSelected}
      startIcon={<PersonOutlineIcon color="secondary" />}
      placeholder={"Investor"}
      menuProps={menuProps}
    />
  );
};

export default InvestorSelectFilter;
