import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useSelector } from "react-redux";
import { MenuProps } from "@mui/material";
import { filterInvestorsSelector } from "../../../../../store/state/messages/selectors";
import FilterAutocomplete from "../../../../common/filters/FilterAutocomplete";
import { useInboxStateContext } from "../../../../../contexts/InboxStateContext";
import { useCallback } from "react";

interface Props {
  menuProps?: Partial<MenuProps> | undefined;
}

export const InvestorSelect = (props: Props) => {
  const { menuProps } = props;

  const { updateCriteria } = useInboxStateContext();

  const investors = useSelector(filterInvestorsSelector);
  const { criteria } = useInboxStateContext();

  const updateInvestors = useCallback(
    (value: string | string[]) => {
      if (Array.isArray(value)) {
        updateCriteria({ investors: value, page: 0 });
      } else {
        updateCriteria({ investors: [value], page: 0 });
      }
    },
    [updateCriteria]
  );

  return (
    <FilterAutocomplete
      values={investors || []}
      selectedValues={criteria.investors || []}
      onSelected={updateInvestors}
      startIcon={<PersonOutlineIcon color="secondary" />}
      placeholder={"Investor"}
      menuProps={menuProps}
    />
  );
};

export default InvestorSelect;
