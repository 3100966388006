import { SvgIconComponent } from "@mui/icons-material";
import { ListItem, ListItemIcon, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  text: string;
  page: string;
  Icon: SvgIconComponent;
  color?: "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  disabled?: boolean;
  onClick: () => void;
  isActive?: boolean;
}

export const MainMenuItem = (props: Props) => {
  const { text, page, Icon, color, disabled, onClick, isActive } = props;

  return (
    <ListItem
      selected={isActive}
      disabled={disabled}
      component={Link}
      to={page}
      sx={(theme) => ({ py: theme.spacing(1.5), pl: theme.spacing(3) })}
      onClick={onClick}
    >
      <ListItemIcon sx={(theme) => ({ minWidth: theme.spacing(4) })}>
        <Icon color={color || "secondary"} />
      </ListItemIcon>
      <Typography variant="subtitle2" color={isActive ? "primary" : color || "secondary"}>
        {text}
      </Typography>
    </ListItem>
  );
};
