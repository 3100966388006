import AlertIcon from "@mui/icons-material/AccessTimeRounded";
import { Alert, Typography } from "@mui/material";
import { useClientContext } from "../../../../contexts/ClientContext";

const ChangesWarningAlert = () => {
  const { portalSettings } = useClientContext();
  const supportContact = portalSettings?.supportEmail ?? "the support";

  return (
    <Alert severity="warning" iconMapping={{ warning: <AlertIcon fontSize="inherit" /> }}>
      <Typography>
        Updating information requires approval. Upon submitting the request, it will be forwarded to{" "}
        <strong>{supportContact}</strong> for review and confirmation.
      </Typography>
    </Alert>
  );
};

export default ChangesWarningAlert;
