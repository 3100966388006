import { ChangeRequestFieldChange } from "../../../../../../shared/api/changeRequestTypes";
import { NewBankAccountState } from "./newBankAccountState";

export const getBankDescription = (state: NewBankAccountState): string =>
  `${state.bankAccountForm.values.bankName} - ${state.bankAccountForm.values.accountNumber.slice(-4)}`;

export const getSelectedFunds = (state: NewBankAccountState): string[] =>
  state.fundAssignments.filter((fund) => fund.selected).map((fund) => fund.fundName);

export const getFieldChanges = (state: NewBankAccountState): ChangeRequestFieldChange[] =>
  [
    { key: "Country", newValue: state.bankAccountForm.values.country, fieldType: "Text" },
    { key: "Bank Name", newValue: state.bankAccountForm.values.bankName, fieldType: "Text" },
    { key: "ABA Routing Number", newValue: state.bankAccountForm.values.abaNumber, fieldType: "Text" },
    { key: "SWIFT / BIC Code", newValue: state.bankAccountForm.values.swiftCode, fieldType: "Text" },
    { key: "Account Name", newValue: state.bankAccountForm.values.accountName, fieldType: "Text" },
    { key: "Account Number / IBAN", newValue: state.bankAccountForm.values.accountNumber, fieldType: "Text" },
    { key: "Currency", newValue: state.bankAccountForm.values.currencyCode, fieldType: "Text" },
    { key: "FFC Name", newValue: state.bankAccountForm.values.ffcName, fieldType: "Text" },
    { key: "FFC Number", newValue: state.bankAccountForm.values.ffcAccountNumber, fieldType: "Text" },
  ].filter((change) => change.newValue) as ChangeRequestFieldChange[];
