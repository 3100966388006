import { Container, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { financialInvestorsSelector } from "../../store/state/user/selectors";
import BalanceWidget from "./balance/BalanceWidget";
import CashFlowsWidgetMobile from "./cashFlows/mobile/CashFlowsWidgetMobile";

const DashboardPage = () => {
  const balanceInvestors = useSelector(financialInvestorsSelector("BALANCE"));
  const cashFlowInvestors = useSelector(financialInvestorsSelector("CASH FLOWS"));

  return (
    <Container sx={{ px: 0, py: 2 }}>
      <Stack spacing={2}>
        {balanceInvestors.length > 0 && <BalanceWidget isMobile />}
        {cashFlowInvestors.length > 0 && <CashFlowsWidgetMobile />}
      </Stack>
    </Container>
  );
};

export default DashboardPage;
