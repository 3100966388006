import { List, ListItem, Paper, SxProps, Theme, useTheme } from "@mui/material";

interface CardSwiperProps {
  items: JSX.Element[];
  width?: number;
  justifyContent?: "center" | "flex-start";
}

const HorizontalSwiper = ({ items, width, justifyContent }: CardSwiperProps) => {
  const theme = useTheme();
  const maxWidth = width ? width : window.innerWidth;
  const scrollerSettings: SxProps<Theme> =
    items.length > 1
      ? {
          ml: 2,
          mb: 0,
          pb: 1,
          display: "grid",
          gridGap: theme.spacing(2),
          gridTemplateColumns: "repeat(auto-fit)",
          gridTemplateRows: "auto",
          gridAutoFlow: "column",
          gridAutoColumns: "92%",
          overflowX: "scroll",
          scrollSnapType: "x mandatory",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "& > li": {
            display: "flex",
            padding: 0,
            scrollSnapAlign: "start",
            flexDirection: "column",
            justifyContent: justifyContent || "flex-start",
            alignItems: "start",
            borderRadius: "8px",
            minWidth: maxWidth * 0.9,
            "&:last-child": {
              pr: 2,
            },
          },
        }
      : {
          pt: 0,
        };

  return (
    <Paper elevation={0} sx={{ backgroundColor: "transparent", maxWidth: { maxWidth }, overflow: "hidden" }}>
      <List sx={scrollerSettings} className="horizontal-scroller">
        {items.map((item, i) => (
          <ListItem key={"h-scroll-item" + i}>{item}</ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default HorizontalSwiper;
