import { Grid, MenuProps } from "@mui/material";
import { ReactNode, useMemo } from "react";
import FilterDate from "./filterDate/FilterDate";
import DocumentSelect from "./selects/DocumentSelect";
import FundTreeSelect from "./selects/FundTreeSelect";
import InvestorSelect from "./selects/InvestorSelect";

const FilterItem = (props: { children?: ReactNode | undefined }) => {
  return (
    <Grid
      item
      sx={{
        display: "flex",
        flex: 1,
        maxWidth: "100%",
        height: "2.5rem",
        position: "relative",
      }}
    >
      <Grid item sx={{ position: "absolute", width: "100%", height: "100%" }}>
        {props.children}
      </Grid>
    </Grid>
  );
};

interface Props {
  direction: "row" | "column";
  menuMode: "inline" | "modal";
}

export const FilterComponent = (props: Props) => {
  const { direction, menuMode } = props;

  const menuProps = useMemo<Partial<MenuProps> | undefined>(() => {
    if (menuMode === "modal") {
      return {
        anchorEl: document.body,
        anchorOrigin: {
          vertical: "center",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "center",
          horizontal: "center",
        },
      } as Partial<MenuProps>;
    }
    return undefined;
  }, [menuMode]);

  return (
    <>
      {direction === "row" && (
        <Grid container gridAutoFlow="column" gap={2} maxWidth="58rem">
          <FilterItem>
            <InvestorSelect menuProps={menuProps} />
          </FilterItem>
          <FilterItem>
            <FundTreeSelect menuProps={menuProps} />
          </FilterItem>
          <FilterItem>
            <DocumentSelect menuProps={menuProps} />
          </FilterItem>
          <FilterItem>
            <FilterDate menuProps={menuProps} />
          </FilterItem>
        </Grid>
      )}
      {direction === "column" && (
        <Grid display={"flex"} flexDirection={"column"} gridAutoFlow={"row"} gap={2}>
          <InvestorSelect menuProps={menuProps} />
          <FundTreeSelect menuProps={menuProps} />
          <DocumentSelect menuProps={menuProps} />
          <FilterDate menuProps={menuProps} />
        </Grid>
      )}
    </>
  );
};

export default FilterComponent;
