import { TabularDataField } from "../../../../shared/reporting/api/biClient.types";
import { DescriptiveDate } from "../../../../shared/utilities/dateFilterHelper";

export interface DashboardState {
  balanceLoading: boolean;
  balanceError: string | undefined;
  balanceData: BalanceDataResponse | undefined;
  balanceInitialData: BalanceInitialData | undefined;
  balanceFilters: BalanceFiltersData;
  balanceClient: string | undefined;
  cashFlowData: CapCallOrDistribution[] | undefined;
  cashFlowRequestData: CashFlowRequest | undefined;
  cashFlowError: string | undefined;
  cashFlowLoading: boolean;
}

export interface GetDashboardFundsResponse {
  effectiveReportingDate: string;
  investorFunds: Record<string, DashboardFundInfo[]>;
  capCallsAndDistributions: CapCallOrDistribution[];
}

export interface DashboardFundInfo {
  id: string;
  name: string;
  nav: number;
  irr: number;
  rvpi: number;
  dpi: number;
  tvpi: number;
  moic: number;
  lastQuarterChange: FundPerformanceChange;
  commitment: number;
  contributions: number;
  distributions: number;
  funding: FundingInfo;
  periodicMovements: PeriodicMovements;
  capCallsAndDistributions: CapCallOrDistribution[];
  stageProgress: number;
}

interface FundPerformanceChange {
  nav: number;
  irr: number;
  rvpi: number;
  dpi: number;
  tvpi: number;
  moic: number;
}

export interface FundingInfo {
  paidInCapital: number;
  recycledCapital: number;
  unfunded: number;
  recallableDistribution: number;
  outsideOfCommitment: number;
}

interface PeriodicMovements {
  periods: PeriodMovements[];
  cumulativeThrough: PeriodMovements;
}

export interface PeriodMovements {
  periodName: string;
  periodEndDate: string;
  beginningNav: number;
  contributions: ContributionsDetails;
  distributions: DistributionsDetails;
  managementFees: number;
  partnershipExpenses: PartnershipExpensesDetails;
  realizedGainLoss: PeriodMovementEntry;
  unrealizedGainLoss: number;
  endingNav: number;
}

interface ContributionsDetails {
  investments: number;
  partnershipExpenses: number;
  managementFee: number;
  other: number;
  total: number;
}

interface DistributionsDetails {
  realizedGainCash: number;
  realizedGainStock: number;
  total: number;
}

interface PartnershipExpensesDetails {
  accounting: number;
  audit: number;
  bankFees: number;
  custodyFees: number;
  dueDiligence: number;
  legal: number;
  organizationCosts: number;
  other: number;
  total: number;
}

interface PeriodMovementEntry {
  details: Record<string, number>;
  total: number;
}

export interface CapCallOrDistribution {
  id: string;
  investorTitle: string;
  fundName: string;
  name: string;
  status: DistributionStatus;
  date: string;
  amount: number;
  unfunded: number;
  transactions: CapCallOrDistributionTransaction[];
}

export type DistributionStatus = "Unpaid" | "PartlyPaid" | "Paid";

export interface CapCallOrDistributionTransaction {
  id: string;
  transactionDescription: string;
  detailsDescription: string;
  impact: CapCallOrDistributionTransactionImpact;
  netAmount: number;
  periodEndingDate: string;
  investment: string;
  currency: string;
}

export type CapCallOrDistributionTransactionImpact = "None" | "Increases" | "Decreases";

export interface GetPortfolioResponse {
  assets: Asset[];
}

export interface Asset {
  dealName: string;
  industry: string;
  geography: string;
  acquisitionDate: string;
  capitalInvested: number;
  realizedProceeds: number;
  remainingValue: number;
  grossMoic: number;
  grossIrr: number;
  growthValues: number[];
}

export interface GetPerformanceResponse {
  periods: PeriodPerformance[];
}

export interface PeriodPerformance {
  periodName: string;
  nav: number;
  distributions: number;
  contributionsPercent: number;
  distributionsPercent: number;
  irr: number;
}

export interface GetBalanceRequest {
  reportingDate: Date | string;
  fields: TabularDataField[];
}

interface DrillDownDataResponse {
  data: { [key: string]: string }[];
}

export interface BalanceDataResponse {
  balanceData: DrillDownDataResponse;
  fundReportingDates: Record<string, Date>;
}

export interface BalanceInitialData {
  reportingPeriods: Date[];
}

export interface BalanceFiltersData {
  investors: string[];
  funds: string[];
  reportingDateIso?: string;
  initialDateIso?: string;
}

interface CashFlowFiltersData {
  investors?: string[];
  funds?: string[];
  paymentStatuses?: DistributionStatus[];
  from?: string;
  to?: string;
  descriptiveDate?: DescriptiveDate;
}

interface PagingRequest {
  page: number;
  size: number;
  totals?: boolean;
}

export interface CashFlowRequest extends CashFlowFiltersData {
  paging: PagingRequest;
}

export const cashFlowDefaults = {
  defaultCashFlowPageSize: 8,
  cashFlowPageSizeAll: 30,
};
