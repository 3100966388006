import HelpIcon from "@mui/icons-material/HelpOutlineRounded";
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PropsWithChildren } from "react";
import TableWithActions from "../../../../../shared/components/TableWithActions";
import { InvestorBankAccountAssignment } from "../../../../api/types/bankAccountTypes";
import UnavailableFundTooltip from "../common/UnavailableFundTooltip";
import { useBankAccountsContext } from "./BankAccountsContext";

interface RowModel extends InvestorBankAccountAssignment {
  disabled?: boolean;
}

interface BodyRowProps {
  row: RowModel;
  onChangeAssignment?: (fundInvestorId: string, fundName: string, bankAccountId: string) => void;
}

const Cell = ({ children, ...props }: PropsWithChildren<TableCellProps>) => (
  <TableCell {...props} sx={{ py: 1.25 }}>
    {children}
  </TableCell>
);

const BodyRow = ({ row, onChangeAssignment }: BodyRowProps) => {
  const { bankAccounts, canUserEdit } = useBankAccountsContext();

  if (!canUserEdit) {
    return (
      <TableRow>
        <Cell>{row.fundName}</Cell>
        <Cell>{row.bankDescription}</Cell>
        <Cell />
      </TableRow>
    );
  }

  if (row.disabled) {
    return (
      <TableRow sx={(t) => ({ ".MuiTableCell-root": { color: t.palette.text.disabled } })}>
        <Cell>{row.fundName}</Cell>
        <Cell>
          <Stack direction="row" spacing={1}>
            <Typography>{row.bankDescription}</Typography>
            <UnavailableFundTooltip active>
              <HelpIcon />
            </UnavailableFundTooltip>
          </Stack>
        </Cell>
        <Cell />
      </TableRow>
    );
  }

  const otherBankAccountsExist = bankAccounts.some((account) => account.id !== row.bankAccountId);

  return (
    <TableRow>
      <Cell>{row.fundName}</Cell>
      <Cell>{row.bankDescription}</Cell>
      <Cell className="table-row-actions">
        {otherBankAccountsExist && (
          <Button
            variant="text"
            onClick={() => onChangeAssignment?.(row.fundInvestorId, row.fundName, row.bankAccountId)}
          >
            Change
          </Button>
        )}
      </Cell>
    </TableRow>
  );
};

interface Props {
  rows: RowModel[];
  onChangeAssignment: (fundInvestorId: string, fundName: string, bankAccountId: string) => void;
}

const FundAssignmentsTable = ({ rows, onChangeAssignment }: Props) => {
  return (
    <TableContainer sx={{ maxHeight: "50vh" }}>
      <TableWithActions stickyHeader>
        <TableHead>
          <TableRow>
            <Cell width="45%">Fund</Cell>
            <Cell width="45%">Bank Account</Cell>
            <Cell width="10%"></Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <BodyRow key={row.fundInvestorId} row={row} onChangeAssignment={onChangeAssignment} />
          ))}
        </TableBody>
      </TableWithActions>
    </TableContainer>
  );
};

export default FundAssignmentsTable;
