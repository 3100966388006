import { Box, Stack } from "@mui/material";
import { useLocalization } from "../../../../hooks/useLocalization";
import { CapCallOrDistribution } from "../../../../store/state/dashboard/types";
import NoDataResult from "../../common/NoDataResult";
import CashFlowsView from "../CashFlowsView";
import CashFlowCard from "./CashFlowCard";
import CashFlowsMobilePageHeader from "./CashFlowsMobilePageHeader";

const CashFlowsMobilePage = () => {
  const locale = useLocalization().dashboard.cashFlows;

  const CapCallsDisplayData = (capCalls: CapCallOrDistribution[]) => {
    if (capCalls.length === 0) {
      return <NoDataResult text={locale.no_data} />;
    }

    return (
      <>
        {capCalls.map((capCall, i) => (
          <Box key={"cc-wrap" + i} py={1}>
            <CashFlowCard key={"cap-call" + i} capCall={capCall} />
          </Box>
        ))}
      </>
    );
  };

  return (
    <Stack spacing={2} sx={{ py: 2, px: 2 }}>
      <CashFlowsMobilePageHeader />
      <CashFlowsView renderItemsElement={CapCallsDisplayData} />
    </Stack>
  );
};

export default CashFlowsMobilePage;
