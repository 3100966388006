import { MessagesListComponent as MessagesListComponentDesktop } from "../messagesList/desktop/MessagesListComponent";
import Breakpoint from "../../../../shared/components/Breakpoint";
import { MessageListViewComponent } from "../messagesList/listView/MessageListViewComponent";
import SearchComponent from "../search/SearchComponent";
import { Grid } from "@mui/material";

export const Messages = () => {
  return (
    <Grid container flex={1}>
      <Breakpoint from="sm" to="lg">
        <Grid container direction={"column"}>
          <SearchComponent />
          <MessageListViewComponent />
        </Grid>
      </Breakpoint>
      <Breakpoint from="lg">
        <Grid container direction={"column"}>
          <SearchComponent />
          <MessagesListComponentDesktop />
        </Grid>
      </Breakpoint>
    </Grid>
  );
};

export default Messages;
