import { Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { logError } from "../../../../shared/logging";
import { localStorageService } from "../../../../shared/services/storage";
import { api } from "../../../api/client";
import { loadInboxStructure } from "../inboxStructure/thunks";
import { GridColDefConfig, InboxGridConfiguration } from "../inboxStructure/types";
import { MessageActions, MessageActionsEnum } from "./actions";
import { MessagesPayload } from "./reducer";
import { FilterConfigurationLoadingState, MessageLoadingState } from "./types";

export type MessagesDispatch = Dispatch<PayloadAction<MessagesPayload>>;
export type InboxConfigDispatch = Dispatch<PayloadAction<InboxGridConfiguration | unknown>>;

const loadInboxStructureAsync = loadInboxStructure();

export function markAsImportant(messageIds: string[], value: boolean) {
  return async (dispatch: MessagesDispatch) => {
    dispatch(MessageActions.UpdateLoading({ markAsImportantUpdating: true } as MessageLoadingState));
    try {
      const response = await api.messages.markManyAsImportant(messageIds, value);
      if (response?.success && response.data.updates) {
        response.data.updates.forEach((resp) => {
          dispatch(MessageActions.MarkAsImportant(resp.messageId, resp.markAsImportant));
        });
        await loadInboxStructureAsync(dispatch);
      } else if (response?.error) {
        logError(response.error.message, "markAsImportant");
      }
    } catch (error) {
      logError(error, "markAsImportant");
    } finally {
      dispatch(MessageActions.UpdateLoading({ markAsImportantUpdating: false } as MessageLoadingState));
    }
  };
}

export function loadConfiguration() {
  return async (dispatch: MessagesDispatch) => {
    dispatch(
      MessageActions.UpdateLoading({
        filterConfiguration: FilterConfigurationLoadingState.Loading,
      } as MessageLoadingState)
    );
    let loadingState: FilterConfigurationLoadingState = FilterConfigurationLoadingState.None;
    try {
      const [filterConfigResponse, inboxGridConfigColumnsDef] = await Promise.all([
        api.messages.loadFilterConfiguration(),
        loadInboxGridColumnDefinition(),
      ]);
      if (filterConfigResponse?.success) {
        dispatch(
          MessageActions.UpdateState({
            filterConfiguration: filterConfigResponse.data,
            inboxGridConfig: { columnDefinitions: inboxGridConfigColumnsDef },
          })
        );
        loadingState = FilterConfigurationLoadingState.Loaded;
      } else {
        if (filterConfigResponse?.error) {
          logError(filterConfigResponse.error?.message, "loadFilterConfiguration");
          loadingState = FilterConfigurationLoadingState.Error;
        }
      }
    } catch (error) {
      logError(error, "loadFilterConfiguration");
      loadingState = FilterConfigurationLoadingState.Error;
    } finally {
      dispatch(MessageActions.UpdateLoading({ filterConfiguration: loadingState } as MessageLoadingState));
    }
  };
}

const loadInboxGridColumnDefinition = async () =>
  new Promise<GridColDefConfig[]>((resolve) => {
    try {
      const inboxGridConfigFromLocalStorage = localStorageService.getItemWithFallback<GridColDefConfig[]>(
        "InboxGridColumnsConfig",
        []
      );
      resolve(inboxGridConfigFromLocalStorage);
    } catch (error) {
      logError(error, "inboxGridConfigurationFromLocalStorage");
    }
  });

export function updateInboxGridConfiguration(columnsDef: GridColDefConfig[]) {
  return async (dispatch: InboxConfigDispatch) => {
    dispatch({ type: MessageActionsEnum.SaveGridSettingsError, payload: false });
    dispatch({ type: MessageActionsEnum.SaveGridSettingsLoading, payload: true });
    try {
      await new Promise<void>((resolve) => {
        localStorageService.setItem("InboxGridColumnsConfig", columnsDef);
        resolve();
      });
    } catch (error) {
      logError(error, "updateInboxGridConfiguration");
      dispatch({ type: MessageActionsEnum.SaveGridSettingsError, payload: true });
    } finally {
      dispatch({ type: MessageActionsEnum.SaveGridSettingsLoading, payload: false });
    }
  };
}
