import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import DeleteFileIcon from "../../../../../shared/icons/DeleteFileIcon";
import { api } from "../../../../api/client";

interface Props {
  open: boolean;
  onClose: () => void;
  investorId: string;
  workflowId: string;
  referenceCode: string | undefined;
  onChangeRequestCanceled: () => void;
}

const cancelChangeRequest = withErrorHandling(api.changeRequests.cancelChangeRequest);

const ConfirmChangeRequestCancellationDialog = ({
  open,
  onClose,
  investorId,
  workflowId,
  referenceCode,
  onChangeRequestCanceled,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  const handleCancelChangeRequest = async () => {
    setLoading(true);
    const [, error] = await cancelChangeRequest(investorId, { workflowId });
    setLoading(false);

    if (error) {
      sendNotificationError("Failed to cancel change request");
      return;
    }

    sendNotification("The request was canceled");
    onChangeRequestCanceled?.();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Cancel Request</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DeleteFileIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={0.5}>
            <Typography variant="h6">Cancel update request #{referenceCode} ?</Typography>
            <Typography color="text.secondary" textAlign="center">
              Your requested changes will be discarded.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Close
        </Button>
        <LoadingButton variant="outlined" color="error" loading={loading} onClick={handleCancelChangeRequest}>
          Cancel Request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChangeRequestCancellationDialog;
