import { Card, CardContent, CardHeader, Grid, Stack, Typography, useTheme } from "@mui/material";
import TypographyMultilineEllipsis from "../../../../../shared/components/TypographyMultilineEllipsis";
import { CardSubHeader } from "../../common/CardSubHeader";
import { formatDate } from "../../../../../shared/utilities/dateUtils";

type BalanceCellProps = {
  title: string;
  value: string;
};

const BalanceCell = ({ title, value }: BalanceCellProps) => {
  return (
    <Stack>
      <Typography color="text.secondary" variant="caption">
        {title}
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        {value}
      </Typography>
    </Stack>
  );
};

type BalanceCardProps = {
  title: string;
  investorName: string;
  date?: Date;
  fields: [string, string][]; // [title, value]
};

const BalanceCard = ({ investorName, title: fundName, date, fields }: BalanceCardProps) => {
  const theme = useTheme();
  const subtitle = date ? `as of ${formatDate(date)}` : undefined;

  return (
    <Card className="balance-card" sx={{ width: "100%", height: "100%" }}>
      <CardHeader
        title={
          <TypographyMultilineEllipsis
            maxLines={2}
            typographyProps={{ variant: "h6", color: theme.palette.primary.main }}
          >
            {investorName}
          </TypographyMultilineEllipsis>
        }
        subheader={<CardSubHeader title={fundName} subtitle={subtitle} />}
      />
      <CardContent sx={{ pt: 0 }}>
        <Grid container spacing={2}>
          {fields.map(([title, value], i) => (
            <Grid key={"bf" + i} item xs={6}>
              <BalanceCell title={title} value={value} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BalanceCard;
