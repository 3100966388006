import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import DeleteBankIcon from "../../../../../../shared/icons/DeleteBankIcon";

interface Props {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const LeavePageConfirmationDialog = ({ open, onConfirm, onCancel }: Props) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Cancel</DialogTitle>

      <DialogContent>
        <Stack alignItems="center" spacing={3}>
          <DeleteBankIcon sx={(t) => ({ color: t.palette.dialogIcon, width: 80, height: 80 })} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Cancel changes?</Typography>
            <Typography color="text.secondary" textAlign="center">
              Your requested changes will be discarded.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onCancel}>
          Continue Editing
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Close & Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeavePageConfirmationDialog;
