import { Box, Divider, Paper } from "@mui/material";
import { Outlet } from "react-router-dom";
import SettingsMenu from "./menu/SettingsMenu";

export const Settings = () => {
  return (
    <Box display="flex" flex={1} sx={{ overflowY: "hidden" }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          width: "18rem",
          height: "100%",
        }}
      >
        <SettingsMenu />
      </Paper>
      <Divider orientation="vertical" flexItem />
      <Paper square elevation={0} sx={{ display: "flex", flex: 1, height: "100%" }}>
        <Outlet />
      </Paper>
    </Box>
  );
};

export default Settings;
