import { Grid } from "@mui/material";
import { useCallback } from "react";
import { Outlet } from "react-router-dom";
import { createApiResponse } from "../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../shared/api/types";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import { api } from "../../api/client";
import { FundInvestor } from "../../api/types/settingsTypes";
import { useClientContext } from "../../contexts/ClientContext";
import { SettingsContextProvider } from "./SettingsContext";

const SettingsPage = () => {
  const { isAnyFeatureEnabled } = useClientContext();

  const getFundInvestors = useCallback(() => {
    const areFundInvestorsAvailable = isAnyFeatureEnabled([
      "Inbox",
      "AccountSettingsViewer",
      "BankAccountsDetailsViewer",
      "Membership",
    ]);

    return areFundInvestorsAvailable
      ? api.settings.getFundInvestors()
      : Promise.resolve<ApiResponse<FundInvestor[]>>(createApiResponse([]));
  }, [isAnyFeatureEnabled]);

  const [fundInvestors, fundInvestorsError, { isFetching: isFetchingFundInvestors }] = useFetch(getFundInvestors);

  const [accessRolesAndCategories, accessRolesAndCategoriesError, { isFetching: isFetchingAccessRolesAndCategories }] =
    useFetch(api.settings.getAccessRolesAndCategories);

  const [dictionaries, dictionariesError, { isFetching: isFetchingDictionaries }] = useFetch(
    api.settings.getDictionaries
  );

  if (fundInvestorsError || accessRolesAndCategoriesError || dictionariesError) {
    logError(fundInvestorsError ?? accessRolesAndCategoriesError ?? dictionariesError, "[Settings]");
    return <DataLoadingFailed title="Failed to load settings" />;
  }

  return (
    <SettingsContextProvider
      fundInvestors={fundInvestors ?? []}
      accessCategories={accessRolesAndCategories?.categories ?? []}
      roles={accessRolesAndCategories?.roles ?? []}
      dictionaries={dictionaries ?? { countries: [], states: [], currencies: [] }}
      loading={isFetchingFundInvestors || isFetchingAccessRolesAndCategories || isFetchingDictionaries}
    >
      <Grid container direction="column" sx={{ flex: 1, overflowY: "auto" }}>
        <Outlet />
      </Grid>
    </SettingsContextProvider>
  );
};

export default SettingsPage;
