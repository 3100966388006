import { Box, Radio } from "@mui/material";
import { InvestorBankAccountDetails } from "../../../../../api/types/bankAccountTypes";
import BankAccountCard from "../BankAccountCard";

interface Props {
  bankAccount: InvestorBankAccountDetails;
  selected: boolean;
  onSelect: (bankAccountId: string) => void;
}

const BankAccountOption = ({ bankAccount, selected, onSelect }: Props) => {
  const onOptionClick = () => {
    if (!selected) {
      onSelect(bankAccount.id);
    }
  };

  return (
    <Box position="relative">
      <Box position="absolute" py={1.5} px={2} top={0} right={0} onClick={onOptionClick}>
        <Radio checked={selected} />
      </Box>
      <BankAccountCard bankAccount={bankAccount} sx={{ borderColor: selected ? "primary.main" : undefined }} />
    </Box>
  );
};

export default BankAccountOption;
