import { MenuList, Typography } from "@mui/material";
import { IItem, InboxStructureTree } from "../../../../store/state/inboxStructure/types";
import GeneralItem from "./GeneralItem";
import CustomCategoryTags from "./CustomCategoryTags";

interface Props {
  selectedItem: string;
  inboxStructure: InboxStructureTree;
  onItemSelected: (item: string) => void;
}

export const CategoryMenu = (props: Props) => {
  const { selectedItem, inboxStructure, onItemSelected } = props;

  return (
    <>
      <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary}>
        Menu
      </Typography>
      <MenuList sx={{ pb: 2 }}>
        <GeneralItem
          item={inboxStructure["inbox"] as IItem}
          selectedItem={selectedItem}
          onClick={() => onItemSelected("Inbox")}
        />
        <GeneralItem
          item={inboxStructure["important"] as IItem}
          selectedItem={selectedItem}
          onClick={() => onItemSelected("Important")}
        />
      </MenuList>
      <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary}>
        Category
      </Typography>
      <MenuList>
        <CustomCategoryTags inboxStructure={inboxStructure} selectedItem={selectedItem} onClick={onItemSelected} />
      </MenuList>
    </>
  );
};
export default CategoryMenu;
