import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Input, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import LoaderBox from "../../../../../shared/components/LoaderBox";
import UserAvatar from "../../../../../shared/components/UserAvatar";
import PhoneFieldExt from "../../../../../shared/components/inputs/PhoneFieldExt";
import TextFieldExt from "../../../../../shared/components/inputs/TextFieldExt";
import { logError } from "../../../../../shared/logging";
import {
  combineValidators,
  emailValidator,
  minCharactersValidator,
  requiredValidator,
} from "../../../../../shared/utilities/validators";
import { api } from "../../../../api/client";
import { useLocalization } from "../../../../hooks/useLocalization";
import { UserActions } from "../../../../store/state/user/actions";
import {
  identitySelector,
  impersonationSelector,
  loadingIdentitySelector,
} from "../../../../store/state/user/selectors";
import { loadLargeAvatar } from "../../../../store/state/user/thunks";
import { AppDispatch } from "../../../../store/store";

const maxAvatarSizeBytes = 5 * 1024 * 1024;

const updateIdentity = withErrorHandling(api.user.updateIdentity);

const MyProfile = () => {
  const fileUploadRef = useRef<HTMLElement>(null);
  const dispatch: AppDispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [avatarImage, setAvatarImage] = useState("");
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState("");
  const [errors, setErrors] = useState({} as { [key: string]: boolean });
  const [avatarProcessing, setAvatarProcessing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const identity = useSelector(identitySelector);
  const isImpersonation = useSelector(impersonationSelector);
  const locale = useLocalization();
  const disableInputs = true;

  useEffect(() => {
    if (identity && !identity.largeImage) {
      dispatch(loadLargeAvatar());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!identity]);

  useEffect(() => {
    setUserName(identity?.name || "");
    setEmail(identity?.email || "");
    setPhone(identity?.phone || "");
    setAvatarImage(identity?.avatarImage || "");
  }, [identity]);

  const isFormValid = () => {
    return userName && email && Object.values(errors).every((x) => x);
  };

  const handleSaveChanges = async () => {
    if (!isFormValid()) {
      return;
    }

    setSaving(true);

    const [identity, error] = await updateIdentity({
      name: userName,
      email,
      phone,
      avatarImage,
    });

    setSaving(false);

    if (error) {
      logError(error, "[MyProfile] handleSaveChanges");
      setAlert(locale.accountSettings.account.saving_account_error);
      return;
    }

    dispatch(UserActions.UpdateIdentity(identity));
    setIsDirty(false);
  };

  const clearAvatarImage = () => {
    const input = fileUploadRef?.current?.firstChild as HTMLInputElement;
    if (input) {
      input.value = "";
    }
    setIsDirty(true);
  };

  const handleCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    const { files } = e.target;
    if (files === null) {
      return;
    }

    const file = files[0];
    if (file === undefined) {
      return;
    }

    if (file.size > maxAvatarSizeBytes) {
      setAlert(locale.accountSettings.account.too_large_image);
      clearAvatarImage();
      return;
    }

    setAlert("");

    setAvatarProcessing(true);
    fileReader.readAsDataURL(file);
    fileReader.onload = async (e) => {
      const result = e.target?.result as string;
      let success = false;
      try {
        const response = await api.user.processAvatar(result);
        if (response?.success) {
          success = true;
          setAvatarImage(response.data);
          setIsDirty(true);
        }
      } catch (e) {
        logError(e, "[MyProfile] handleCapture");
      } finally {
        setAvatarProcessing(false);
        if (!success) {
          setAlert(locale.accountSettings.account.loading_image_error);
        }
      }
    };
  };

  return (
    <LoaderBox loading={useSelector(loadingIdentitySelector)}>
      <Stack sx={{ py: 3, px: 4 }} spacing={3}>
        <Typography variant="h5">My Profile</Typography>
        {!!alert && <Alert severity="error">{alert}</Alert>}
        <Stack direction={"row"} sx={{ paddingTop: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 700, textAlign: "left" }}>
              My Photo
            </Typography>
            <Box my={2}>
              <UserAvatar size={100} userName={userName} avatarImage={avatarImage} />
            </Box>
            <LinearProgress
              sx={{
                marginBottom: "3px",
                visibility: avatarProcessing ? "visible" : "hidden",
              }}
            />
            <label htmlFor="contained-button-file">
              {!isImpersonation && (
                <Input
                  ref={fileUploadRef}
                  inputProps={{ accept: ".png, .jpg, .jpeg" }}
                  id="contained-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={handleCapture}
                />
              )}
              <Button
                variant="outlined"
                component="span"
                sx={(theme) => {
                  return {
                    color: theme.palette.text.primary,
                    padding: "2px 9px",
                    whiteSpace: "nowrap",
                  };
                }}
                color="secondary"
                disabled={avatarProcessing || isImpersonation}
              >
                Upload Photo
              </Button>
            </label>
            <Button
              variant="text"
              sx={(theme) => {
                return {
                  color: theme.palette.text.primary,
                  padding: "2px 9px",
                  whiteSpace: "nowrap",
                  display: avatarImage ? "inherit" : "none",
                };
              }}
              color="secondary"
              onClick={() => {
                clearAvatarImage();
                setAvatarImage("");
              }}
              disabled={avatarProcessing || isImpersonation}
            >
              Remove
            </Button>
          </Box>
          <Box sx={{ marginLeft: "3rem", width: "25rem" }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              Personal Info
            </Typography>
            <Box>
              <Stack direction="column" gap="1.5rem" sx={{ padding: "1.5rem 0 2rem 0" }}>
                <TextFieldExt
                  name="full name"
                  InputProps={{
                    disabled: disableInputs,
                    label: "Full Name",
                    variant: "outlined",
                    value: userName,
                  }}
                  doValidate={minCharactersValidator(3)}
                  onValidated={(name, result) => setErrors({ ...errors, [name]: result.isValid })}
                  onValueChanged={setUserName}
                />
                <TextFieldExt
                  name="email address"
                  InputProps={{
                    disabled: disableInputs,
                    label: "Email Address",
                    variant: "outlined",
                    value: email,
                    type: "email",
                  }}
                  doValidate={combineValidators(requiredValidator, emailValidator)}
                  onValidated={(name, result) => setErrors({ ...errors, [name]: result.isValid })}
                  onValueChanged={setEmail}
                />
                <PhoneFieldExt
                  disabled={disableInputs}
                  name="phone"
                  containerClassName="my-profile-phone-number"
                  InputProps={{
                    placeholder: "",
                    preferredCountries: ["us"],
                    value: phone,
                  }}
                  onValidated={(name, value) => setErrors({ ...errors, [name]: value })}
                  onValueChanged={setPhone}
                ></PhoneFieldExt>
              </Stack>
            </Box>
          </Box>
        </Stack>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          sx={{ boxShadow: "unset", width: "7rem" }}
          disabled={!isFormValid() || isImpersonation || !isDirty}
          loading={saving}
          onClick={handleSaveChanges}
        >
          Save changes
        </LoadingButton>
      </Stack>
    </LoaderBox>
  );
};

export default MyProfile;
