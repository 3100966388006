import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import CheckableTreeLabel from "./CheckableTreeLabel";
import { TreeNode, updateNodes } from "./treeModel";

interface Props {
  nodes: TreeNode[];
  onChange: (newNodes: TreeNode[]) => void;
  expandedNodes?: string[];
  onExpandedNodesChange?: (nodeIds: string[]) => void;
}

/**
 * Supports only 2-level tree at the moment, but can be extended when needed.
 * It is a controlled component; onChange returns a clone of the updated tree structure back.
 */
const TreeSelect = ({ nodes, expandedNodes, onExpandedNodesChange, onChange }: Props) => {
  const handleChange = (id: string, checked: boolean) => {
    onChange(updateNodes(nodes, id, checked));
  };

  return (
    <SimpleTreeView
      expandedItems={expandedNodes}
      disableSelection
      onExpandedItemsChange={(_, nodeIds) => onExpandedNodesChange?.(nodeIds)}
      slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
    >
      {nodes.map((parentNode) => (
        <TreeItem
          sx={{
            ".MuiTreeItem-content.Mui-focused": {
              backgroundColor: "transparent",
            },
          }}
          key={parentNode.id}
          itemId={parentNode.id}
          label={<CheckableTreeLabel node={parentNode} onChange={handleChange} />}
        >
          {parentNode.children.map((childNode) => (
            <TreeItem
              key={childNode.id}
              itemId={childNode.id}
              label={<CheckableTreeLabel node={childNode} onChange={handleChange} />}
            ></TreeItem>
          ))}
        </TreeItem>
      ))}
    </SimpleTreeView>
  );
};

export default TreeSelect;
