import TimeIcon from "@mui/icons-material/AccessTimeRounded";
import { Alert, AlertTitle, Button, SxProps, Theme, Typography } from "@mui/material";
import { convertISODateShort } from "../../../../../shared/utilities/dateUtils";
import { ChangeRequestDetails } from "../../../../api/types/changeRequestsTypes";

interface Props {
  pendingChangeRequest: ChangeRequestDetails;
  onViewRequest: (pendingChangeRequest: ChangeRequestDetails) => void;
  hideIcon?: boolean;
  alertSx?: SxProps<Theme>;
}

const PendingChangesAlert = ({ pendingChangeRequest, onViewRequest, hideIcon, alertSx }: Props) => {
  return (
    <Alert
      severity="warning"
      icon={hideIcon ? false : undefined}
      iconMapping={{ warning: <TimeIcon fontSize="inherit" /> }}
      sx={{ position: "relative", ...alertSx }}
    >
      <AlertTitle>Pending Changes (#{pendingChangeRequest.referenceCode})</AlertTitle>
      <Button
        variant="text"
        color="inherit"
        sx={{ position: "absolute", right: 12, top: 12, py: 0 }}
        onClick={() => onViewRequest(pendingChangeRequest)}
      >
        View Request
      </Button>
      <Typography>
        Change request submitted by <strong>{pendingChangeRequest.requestedByUserName}</strong> on{" "}
        <strong>{convertISODateShort(pendingChangeRequest.requestedAt)}</strong> is waiting for approval. Editing will
        be restricted until the request is approved.
      </Typography>
    </Alert>
  );
};

export default PendingChangesAlert;
