import BankIcon from "@mui/icons-material/AccountBalanceOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, Typography } from "@mui/material";
import { useBankAccountsContext } from "./BankAccountsContext";

interface Props {
  canEdit: boolean;
  onAddBankAccount: () => void;
}

const NoBankAccounts = ({ canEdit, onAddBankAccount }: Props) => {
  const { availableFundInvestorIds } = useBankAccountsContext();

  return (
    <Stack spacing={2} width="25rem" alignItems="center">
      <BankIcon color="secondary" sx={(t) => ({ fill: t.palette.dialogIcon, width: 80, height: 80 })} />
      <Typography color="text.secondary">
        You don't have any bank accounts yet. These bank accounts will be used to receive distributions from your fund
        investments.
      </Typography>
      {canEdit && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onAddBankAccount}
          disabled={availableFundInvestorIds.length === 0}
        >
          Add Bank Account
        </Button>
      )}
    </Stack>
  );
};

export default NoBankAccounts;
