import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCashFlowFiltersApplied } from "../../../../store/state/dashboard/selectors";
import BackToDashboardButton from "../../BackToDashboardButton";
import { useState } from "react";
import SwipeableFiltersDrawer from "../../common/SwipeableFiltersDrawer";
import CashFlowFilters from "../CashFlowFilters";
import { useLocalization } from "../../../../hooks/useLocalization";
import FiltersIcon from "../../common/FiltersIcon";

interface Props {
  backButton?: React.ReactNode;
}

const CashFlowsMobilePageHeader = ({ backButton }: Props) => {
  const theme = useTheme();
  const locale = useLocalization().dashboard.cashFlows;
  const [showFilters, setShowFilters] = useState(false);
  const filtersApplied = useSelector(selectCashFlowFiltersApplied);
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        {backButton || <BackToDashboardButton />}
        <Typography variant="h6">{locale.title}</Typography>
        <IconButton onClick={() => setShowFilters(!showFilters)}>
          <FiltersIcon isApplied={filtersApplied} color={theme.palette.primary.main} />
        </IconButton>
      </Stack>
      {showFilters && (
        <SwipeableFiltersDrawer
          open={showFilters}
          onClose={() => setShowFilters(false)}
          children={<CashFlowFilters isMobile />}
        />
      )}
    </>
  );
};

export default CashFlowsMobilePageHeader;
