import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import { markAsImportant } from "../../../store/state/messages/thunks";
import { currentMessageSelector } from "../../../store/state/messages/selectors";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { Message } from "../../../store/state/messages/types";
import { useInboxStateContext } from "../../../contexts/InboxStateContext";
import { AppDispatch } from "../../../store/store";
import { impersonationSelector } from "../../../store/state/user/selectors";

interface Props {
  downloadAllAttachments: (message: Message) => void;
}

export const MessageMenu = (props: Props) => {
  const { downloadAllAttachments } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isImpersonation = useSelector(impersonationSelector);
  const dispatch: AppDispatch = useDispatch();
  const message = useSelector(currentMessageSelector);
  const { ui } = useInboxStateContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const changeImportance = useCallback(() => {
    if (message) {
      dispatch(markAsImportant([message.id], !message.isImportant));
    }
  }, [message, dispatch]);

  if (!message || ui.messageDetailLoading) {
    return <></>;
  }

  return (
    <>
      {message?.hasAttachments === true && message.attachments && (
        <IconButton onClick={() => downloadAllAttachments(message)}>
          <SaveAltOutlinedIcon />
        </IconButton>
      )}
      <IconButton className="menu" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            changeImportance();
          }}
          disabled={isImpersonation}
        >
          <ListItemIcon>
            <LabelImportantIcon style={{ color: message?.isImportant ? "#FFB400" : "inherit" }} />
          </ListItemIcon>
          <Typography variant="inherit">
            {message?.isImportant ? "Mark as not Important" : "Mark as Important"}{" "}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
export default MessageMenu;
