import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { inboxStructureSelector } from "../../../../store/state/inboxStructure/selectors";
import { IItem, InboxCategoryTags, InboxStructureTree } from "../../../../store/state/inboxStructure/types";
import InboxBadge from "../../../common/InboxBadge";
import InboxTreeComponent from "../../inboxTree/InboxTreeComponent";
import SearchComponent from "../../search/SearchComponent";
import { MessageListViewComponent } from "../listView/MessageListViewComponent";

const getItemByTag = (tree: InboxStructureTree, tag: string | undefined) => {
  if (!tree || !tag) {
    return undefined;
  }

  const values = tag.split("/");
  let caption = "";
  let parentCaption = "";
  if (values.length === 1) {
    caption = values[0] || "";
  } else {
    parentCaption = values[0] || "";
    caption = values[1] || "";
  }

  if (parentCaption) {
    const systemTags = tree["systemTags"] as IItem[];
    if (systemTags) {
      const parentItem = systemTags.find((item) => item.caption === parentCaption);
      if (parentItem && parentItem.childTags) {
        const childItem = parentItem.childTags.find((item) => item.caption === caption);
        return childItem;
      } else {
        return parentItem;
      }
    }
  } else {
    for (const key in tree) {
      if (key === "systemTags") {
        const systemTags = tree[key] as IItem[];
        if (systemTags) {
          const systemItem = systemTags.find((item) => item.caption === caption);
          if (systemItem) {
            return systemItem;
          }
        }
      }
      if (key === "categoryTags") {
        const categoryTags = tree[key] as InboxCategoryTags[];
        if (categoryTags && caption !== undefined) {
          const category = categoryTags.find((item) => item.id === caption);
          if (category) {
            return { caption: category.name } as IItem;
          }
        }
      }
      const item = tree[key] as IItem;
      if (item.caption === caption) {
        return item;
      }
    }
  }

  return undefined;
};

export const MessagesListComponent = () => {
  const { tag } = useParams();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const inboxStructureTree = useSelector(inboxStructureSelector);

  const currentTagItem = getItemByTag(inboxStructureTree, tag);

  return (
    <Grid container direction="column">
      <Grid container p={2} onClick={() => setMenuExpanded(!menuExpanded)} alignItems="center">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            maxWidth: "calc(100% - 1.4rem)",
          }}
        >
          <Typography
            component={"span"}
            variant="h5"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {currentTagItem?.caption}
          </Typography>
          <InboxBadge value={currentTagItem?.unread || 0} mr={-1.5} mt={1}>
            {menuExpanded ? (
              <ArrowDropUpRoundedIcon color="secondary" />
            ) : (
              <ArrowDropDownRoundedIcon color="secondary" />
            )}
          </InboxBadge>
        </Box>
      </Grid>
      <Grid
        sx={{
          position: "absolute",
          zIndex: 2,
          width: "100%",
          top: "3rem",
        }}
      >
        <Accordion expanded={menuExpanded} sx={(theme) => ({ backgroundColor: theme.palette.background.grey })}>
          <AccordionSummary sx={{ display: "none" }}></AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <InboxTreeComponent onItemSelected={() => setMenuExpanded(false)} />
          </AccordionDetails>
        </Accordion>
      </Grid>
      {menuExpanded && (
        <Grid
          item
          sx={{
            position: "absolute",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: 1,
            top: "4rem",
            width: "100%",
          }}
          onClick={() => setMenuExpanded(false)}
        ></Grid>
      )}
      <SearchComponent />
      <MessageListViewComponent />
    </Grid>
  );
};

export default MessagesListComponent;
