import { Stack, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import DateSelector from "../../../../shared/components/DateSelector";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { logError } from "../../../../shared/logging";
import { useLocalization } from "../../../hooks/useLocalization";
import { defaultBalanceFilters, updateBalanceFilters } from "../../../store/state/dashboard/dashboardSlice";
import {
  selectCurrentBalanceClient,
  selectDashboardBalance,
  selectDashboardBalanceFilters,
  selectDashboardBalanceInitialData,
  selectDashboardError,
  selectDashboardLoading,
} from "../../../store/state/dashboard/selectors";
import { fetchBalance } from "../../../store/state/dashboard/thunk";
import { clientCodeSelector } from "../../../store/state/user/selectors";
import { AppDispatch } from "../../../store/store";
import BalanceTable from "../balance/BalanceTable";
import NoDataResult from "../common/NoDataResult";
import TableContainerCard from "../fundDashboard/TableContainerCard";
import BalanceMobileWidget from "./mobile/BalanceMobileWidget";

interface BalanceWidgetProps {
  isMobile?: boolean;
}

const BalanceWidget = ({ isMobile }: BalanceWidgetProps) => {
  const dispatch: AppDispatch = useDispatch();
  const balanceLocale = useLocalization().dashboard.balance;
  const balanceInitialDataResponse = useSelector(selectDashboardBalanceInitialData);
  const isLoading = useSelector(selectDashboardLoading);
  const balanceDataResponse = useSelector(selectDashboardBalance);
  const errorMsg = useSelector(selectDashboardError);
  const filters = useSelector(selectDashboardBalanceFilters);
  const currentBalanceClient = useSelector(selectCurrentBalanceClient);
  const client = useSelector(clientCodeSelector);

  const setReportingDate = useCallback(
    (reportingDate: Date) => {
      const dateIso = reportingDate.toISOString();
      dispatch(
        updateBalanceFilters({
          ...filters,
          reportingDateIso: dateIso,
        })
      );
      dispatch(fetchBalance({ reportingDate, client }));
    },
    [client, dispatch, filters]
  );

  useEffect(() => {
    if (client !== currentBalanceClient) {
      dispatch(updateBalanceFilters(defaultBalanceFilters));
      dispatch(fetchBalance({ client }));
    }
  }, [client, currentBalanceClient, dispatch]);

  if (errorMsg) {
    logError(errorMsg, "BalanceWidget");
  }

  const noReportingPeriods = balanceInitialDataResponse?.reportingPeriods.length === 0;
  const showBalance = balanceDataResponse && !isLoading && !errorMsg;
  const showError = !isLoading && errorMsg;

  return isMobile ? (
    <BalanceMobileWidget reportingDate={filters.reportingDateIso ? parseISO(filters.reportingDateIso) : undefined} />
  ) : (
    <TableContainerCard
      title={balanceLocale.widget_name}
      tableWrapperProps={{ pt: 1 }}
      firstLineElements={
        balanceInitialDataResponse ? (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Typography color="text.secondary">{balanceLocale.reportingPeriod}:</Typography>
            <DateSelector
              initialDate={filters.reportingDateIso}
              optionDates={balanceInitialDataResponse.reportingPeriods.map((d) => new Date(d))}
              disabled={isLoading}
              onChange={setReportingDate}
            />
          </Stack>
        ) : null
      }
    >
      {showBalance && <BalanceTable balanceDataResponse={balanceDataResponse} />}
      {isLoading && <InlineLoader />}
      {showError && <DataLoadingFailed bgColor="none" />}
      {!showError && !isLoading && noReportingPeriods && <NoDataResult text={balanceLocale.no_reporting_periods} />}
    </TableContainerCard>
  );
};

export default BalanceWidget;
