import { Theme, useMediaQuery } from "@mui/material";
import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import InlineLoader from "../shared/components/inlineLoader/InlineLoader";
import DashboardPage from "./components/dashboard/DashboardPage";
import DashboardPageMobile from "./components/dashboard/DashboardPageMobile";
import CashFlowsPage from "./components/dashboard/cashFlows/CashFlowsPage";
import CashFlowsMobilePage from "./components/dashboard/cashFlows/mobile/CashFlowsMobilePage";
import FundraisingListPage from "./components/fundraising/FundraisingListPage";
import FundraisingPage from "./components/fundraising/FundraisingPage";
import AttachmentPreviewRedirect from "./components/inbox/AttachmentPreviewRedirect";
import InboxContainer from "./components/inbox/InboxContainer";
import InboxDesktopComponent from "./components/inbox/desktop/InboxComponent";
import Messages from "./components/inbox/desktop/Messages";
import { MessageComponent } from "./components/inbox/message/MessageComponent";
import MessagesListComponent from "./components/inbox/messagesList/mobile/MessagesListComponent";
import Settings from "./components/settings/Settings";
import SettingsPage from "./components/settings/SettingsPage";
import AccountSettings from "./components/settings/lp/account/AccountSettings";
import BankSettings from "./components/settings/lp/bank/BankSettings";
import ChangeFundAssignmentsPage from "./components/settings/lp/bank/changeFundAssignments/ChangeFundAssignmentsPage";
import NewBankAccountPage from "./components/settings/lp/bank/newBankAccount/NewBankAccountPage";
import MembersSettings from "./components/settings/lp/members/MembersSettings";
import PermissionsComponent from "./components/settings/lp/members/permissions/PermissionsComponent";
import MyProfile from "./components/settings/profile/myProfile/MyProfile";
import PasswordAuth from "./components/settings/profile/passwordAuth/PasswordAuth";
import { useClientContext } from "./contexts/ClientContext";

const FundDashboardPage = lazy(() => import("./components/dashboard/fundDashboard/FundDashboardPage"));
const PortfolioPage = lazy(() => import("./components/portfolio/PortfolioPage"));

const Pages = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between("sm", "lg"));

  const { clientCode, isFeatureEnabled } = useClientContext();

  const getHomeRoute = () => {
    if (isFeatureEnabled("Dashboards")) {
      return `/${clientCode}/dashboard`;
    }
    if (isFeatureEnabled("Inbox")) {
      return `/${clientCode}/inbox`;
    }
    if (isFeatureEnabled("Fundraising")) {
      return `/${clientCode}/fundraising`;
    }
    return `/${clientCode}/settings/lp/profile`;
  };

  const homeRoute = getHomeRoute();

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={homeRoute} />} />
      <Route path="settings" element={<SettingsPage />}>
        <Route path="lp" element={<Settings />}>
          <Route path="profile" element={<MyProfile />} />
          <Route path="password-auth" element={<PasswordAuth />} />
          {isFeatureEnabled("AccountSettingsViewer") && <Route path="account" element={<AccountSettings />} />}
          {isFeatureEnabled("BankAccountsDetailsViewer") && <Route path="bank" element={<BankSettings />} />}
          {isFeatureEnabled("Membership") && <Route path="members" element={<MembersSettings />} />}
        </Route>
        {isFeatureEnabled("BankAccountsDetailsEditor") && <Route path="lp/bank/new" element={<NewBankAccountPage />} />}
        {isFeatureEnabled("BankAccountsDetailsEditor") && (
          <Route path="lp/bank/assign" element={<ChangeFundAssignmentsPage />} />
        )}
        {isFeatureEnabled("Membership") && (
          <Route path="lp/members/:investorId/permissions" element={<PermissionsComponent />} />
        )}
      </Route>

      {isFeatureEnabled("Dashboards") && isMobile && <Route path="dashboard" element={<DashboardPageMobile />} />}
      {isFeatureEnabled("Dashboards") && !isMobile && <Route path="dashboard" element={<DashboardPage />} />}
      {isFeatureEnabled("Dashboards") && !isMobile && (
        <Route
          path="dashboard/fund"
          element={
            <Suspense fallback={<InlineLoader />}>
              <FundDashboardPage />
            </Suspense>
          }
        />
      )}
      {isFeatureEnabled("Dashboards") && isMobile && (
        <Route path="dashboard/cash-flows" element={<CashFlowsMobilePage />} />
      )}
      {isFeatureEnabled("Dashboards") && !isMobile && <Route path="dashboard/cash-flows" element={<CashFlowsPage />} />}

      {isFeatureEnabled("Fundraising") && (
        <Route path="fundraising" element={<Outlet />}>
          <Route index element={<FundraisingListPage />} />
          <Route path=":id" element={<FundraisingPage />} />
        </Route>
      )}

      {isFeatureEnabled("PortfolioCompanies") && (
        <Route
          path="portfolio"
          element={
            <Suspense fallback={<InlineLoader />}>
              <PortfolioPage />
            </Suspense>
          }
        />
      )}

      {isFeatureEnabled("Inbox") && isMobile && (
        <Route path="inbox" element={<InboxContainer />}>
          <Route path=":tag" element={<MessagesListComponent />} />
          <Route path=":tag/:page" element={<MessagesListComponent />} />
          <Route path=":tag/message/:id" element={<MessageComponent />} />
        </Route>
      )}
      {isFeatureEnabled("Inbox") && !isMobile && (
        <Route path="inbox" element={<InboxContainer keepPage={!isTablet} />}>
          <Route path=":tag" element={<InboxDesktopComponent />}>
            <Route index element={<Messages />} />
            <Route path=":page" element={<Messages />} />
            <Route path="message/:id" element={<MessageComponent />} />
          </Route>
        </Route>
      )}

      {isFeatureEnabled("Inbox") && (
        <Route path="preview/:externalMessageId/:externalAttachmentId" element={<AttachmentPreviewRedirect />} />
      )}

      <Route path="*" element={<Navigate replace to={homeRoute} />} />
    </Routes>
  );
};

export default Pages;
