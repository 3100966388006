import { SvgIcon, SvgIconProps } from "@mui/material";

const CircleIcon = (props: SvgIconProps) => {
  const radius = parseFloat(props.radius?.toString() || "4");
  return (
    <SvgIcon {...props} sx={{ ...props.sx }} width={radius * 2} height={radius * 2}>
      <circle r={radius} cx={radius} cy={radius} />
    </SvgIcon>
  );
};

export default CircleIcon;
