// https://www.iso.org/obp/ui/#search
const countries = {
  AD: ["Andorra"],
  AE: ["United Arab Emirates", "UAE"],
  AF: ["Afghanistan"],
  AG: ["Antigua and Barbuda"],
  AI: ["Anguilla"],
  AL: ["Albania"],
  AM: ["Armenia"],
  AO: ["Angola"],
  AQ: ["Antarctica"],
  AR: ["Argentina"],
  AS: ["American Samoa"],
  AT: ["Austria"],
  AU: ["Australia"],
  AW: ["Aruba"],
  AX: ["Åland Islands"],
  AZ: ["Azerbaijan"],
  BA: ["Bosnia and Herzegovina"],
  BB: ["Barbados"],
  BD: ["Bangladesh"],
  BE: ["Belgium"],
  BF: ["Burkina Faso"],
  BG: ["Bulgaria"],
  BH: ["Bahrain"],
  BI: ["Burundi"],
  BJ: ["Benin"],
  BL: ["Saint Barthélemy"],
  BM: ["Bermuda"],
  BN: ["Brunei", "Brunei Darussalam"],
  BO: ["Bolivia"],
  BQ: ["Bonaire"],
  BR: ["Brazil"],
  BS: ["Bahamas"],
  BT: ["Bhutan"],
  BV: ["Bouvet Island"],
  BW: ["Botswana"],
  BY: ["Belarus"],
  BZ: ["Belize"],
  CA: ["Canada"],
  CC: ["Cocos (Keeling) Islands", "Cocos Islands"],
  CD: ["Congo, Democratic Republic"],
  CF: ["Central African Republic", "CAR"],
  CG: ["Congo"],
  CH: ["Switzerland"],
  CI: ["Côte d'Ivoire"],
  CK: ["Cook Islands"],
  CL: ["Chile"],
  CM: ["Cameroon"],
  CN: ["China"],
  CO: ["Colombia"],
  CR: ["Costa Rica"],
  CU: ["Cuba"],
  CV: ["Cabo Verde"],
  CW: ["Curaçao"],
  CX: ["Christmas Island"],
  CY: ["Cyprus"],
  CZ: ["Czechia", "Czech Republic"],
  DE: ["Germany"],
  DJ: ["Djibouti"],
  DK: ["Denmark"],
  DM: ["Dominica"],
  DO: ["Dominican Republic"],
  DZ: ["Algeria"],
  EC: ["Ecuador"],
  EE: ["Estonia"],
  EG: ["Egypt"],
  EH: ["Western Sahara"],
  ER: ["Eritrea"],
  ES: ["Spain"],
  ET: ["Ethiopia"],
  FI: ["Finland"],
  FJ: ["Fiji", "Fiji Islands"],
  FK: ["Falkland Islands"],
  FM: ["Micronesia"],
  FO: ["Faroe Islands"],
  FR: ["France"],
  GA: ["Gabon"],
  GB: ["United Kingdom of Great Britain and Northern Ireland", "United Kingdom", "U.K.", "UK"],
  GD: ["Grenada"],
  GE: ["Georgia"],
  GF: ["French Guiana"],
  GG: ["Guernsey"],
  GH: ["Ghana"],
  GI: ["Gibraltar"],
  GL: ["Greenland"],
  GM: ["Gambia"],
  GN: ["Guinea"],
  GP: ["Guadeloupe"],
  GQ: ["Equatorial Guinea"],
  GR: ["Greece"],
  GS: ["South Georgia and the South Sandwich Islands", "South Georgia"],
  GT: ["Guatemala"],
  GU: ["Guam"],
  GW: ["Guinea-Bissau"],
  GY: ["Guyana"],
  HK: ["Hong Kong"],
  HM: ["Heard Island and McDonald Islands"],
  HN: ["Honduras"],
  HR: ["Croatia"],
  HT: ["Haiti"],
  HU: ["Hungary"],
  ID: ["Indonesia"],
  IE: ["Ireland"],
  IL: ["Israel"],
  IM: ["Isle of Man"],
  IN: ["India"],
  IO: ["British Indian Ocean Territory"],
  IQ: ["Iraq"],
  IR: ["Iran"],
  IS: ["Iceland"],
  IT: ["Italy"],
  JE: ["Jersey"],
  JM: ["Jamaica"],
  JO: ["Jordan"],
  JP: ["Japan"],
  KE: ["Kenya"],
  KG: ["Kyrgyzstan"],
  KH: ["Cambodia"],
  KI: ["Kiribati"],
  KM: ["Comoros"],
  KN: ["Saint Kitts and Nevis"],
  KP: ["Korea, Democratic People's Republic", "North Korea", "DPRK"],
  KR: ["Korea"],
  KW: ["Kuwait"],
  KY: ["Cayman Islands"],
  KZ: ["Kazakhstan"],
  LA: ["Lao People's Democratic Republic", "Laos"],
  LB: ["Lebanon"],
  LC: ["Saint Lucia"],
  LI: ["Liechtenstein"],
  LK: ["Sri Lanka"],
  LR: ["Liberia"],
  LS: ["Lesotho"],
  LT: ["Lithuania"],
  LU: ["Luxembourg"],
  LV: ["Latvia"],
  LY: ["Libya"],
  MA: ["Morocco"],
  MC: ["Monaco"],
  MD: ["Moldova"],
  ME: ["Montenegro"],
  MF: ["Saint Martin"],
  MG: ["Madagascar"],
  MH: ["Marshall Islands"],
  MK: ["North Macedonia"],
  ML: ["Mali"],
  MM: ["Myanmar"],
  MN: ["Mongolia"],
  MO: ["Macao"],
  MP: ["Northern Mariana Islands"],
  MQ: ["Martinique"],
  MR: ["Mauritania"],
  MS: ["Montserrat"],
  MT: ["Malta"],
  MU: ["Mauritius"],
  MV: ["Maldives"],
  MW: ["Malawi"],
  MX: ["Mexico"],
  MY: ["Malaysia"],
  MZ: ["Mozambique"],
  NA: ["Namibia"],
  NC: ["New Caledonia"],
  NE: ["Niger"],
  NF: ["Norfolk Island"],
  NG: ["Nigeria"],
  NI: ["Nicaragua"],
  NL: ["Netherlands"],
  NO: ["Norway"],
  NP: ["Nepal"],
  NR: ["Nauru"],
  NU: ["Niue"],
  NZ: ["New Zealand"],
  OM: ["Oman"],
  PA: ["Panama"],
  PE: ["Peru"],
  PF: ["French Polynesia"],
  PG: ["Papua New Guinea"],
  PH: ["Philippines"],
  PK: ["Pakistan"],
  PL: ["Poland"],
  PM: ["Saint Pierre and Miquelon"],
  PN: ["Pitcairn"],
  PR: ["Puerto Rico"],
  PS: ["Palestine"],
  PT: ["Portugal"],
  PW: ["Palau"],
  PY: ["Paraguay"],
  QA: ["Qatar"],
  RE: ["Réunion"],
  RO: ["Romania"],
  RS: ["Serbia"],
  RU: ["Russian Federation", "Russia"],
  RW: ["Rwanda"],
  SA: ["Saudi Arabia"],
  SB: ["Solomon Islands"],
  SC: ["Seychelles"],
  SD: ["Sudan"],
  SE: ["Sweden"],
  SG: ["Singapore"],
  SH: ["Saint Helena"],
  SI: ["Slovenia"],
  SJ: ["Svalbard and Jan Mayen"],
  SK: ["Slovakia"],
  SL: ["Sierra Leone"],
  SM: ["San Marino"],
  SN: ["Senegal"],
  SO: ["Somalia"],
  SR: ["Suriname"],
  SS: ["South Sudan"],
  ST: ["Sao Tome and Principe"],
  SV: ["El Salvador"],
  SX: ["Sint Maarten"],
  SY: ["Syrian Arab Republic", "Syria", "SAR"],
  SZ: ["Eswatini"],
  TC: ["Turks and Caicos Islands"],
  TD: ["Chad"],
  TF: ["French Southern Territories"],
  TG: ["Togo"],
  TH: ["Thailand"],
  TJ: ["Tajikistan"],
  TK: ["Tokelau"],
  TL: ["Timor-Leste"],
  TM: ["Turkmenistan"],
  TN: ["Tunisia"],
  TO: ["Tonga"],
  TR: ["Türkiye", "Turkey"],
  TT: ["Trinidad and Tobago"],
  TV: ["Tuvalu"],
  TW: ["Taiwan"],
  TZ: ["Tanzania"],
  UA: ["Ukraine"],
  UG: ["Uganda"],
  UM: ["United States Minor Outlying Islands"],
  US: ["United States of America", "United States", "USA", "U.S.", "US"],
  UY: ["Uruguay"],
  UZ: ["Uzbekistan"],
  VA: ["Holy See"],
  VC: ["Saint Vincent and the Grenadines"],
  VE: ["Venezuela"],
  VG: ["Virgin Islands, British"],
  VI: ["Virgin Islands, U.S."],
  VN: ["Viet Nam", "Vietnam"],
  VU: ["Vanuatu"],
  WF: ["Wallis and Futuna"],
  WS: ["Samoa"],
  YE: ["Yemen"],
  YT: ["Mayotte"],
  ZA: ["South Africa"],
  ZM: ["Zambia"],
  ZW: ["Zimbabwe"],
};

export const countryCodeAlpha2Lookup = Object.entries(countries).reduce<Map<string, string>>((result, [key, value]) => {
  value.forEach((countryName) => result.set(countryName, key));
  return result;
}, new Map<string, string>());

export const countryCodeAlpha2Set = new Set<string>(Object.keys(countries));
