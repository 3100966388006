import { Box, Checkbox, ListItemText, MenuItem, MenuProps, OutlinedInput, Select, Typography } from "@mui/material";
import clsx from "clsx";
import { FC, PropsWithChildren, useMemo, useState } from "react";
import { makeLighterBackgroundFromColor } from "../../../../shared/utilities/colorHelper";
import CloseFilterButton from "../../inbox/search/filter/CloseFilterButton";

interface Props {
  values?: string | string[];
  selectedValues: string | string[];
  onSelected: (values: string | string[]) => void;
  renderValue?: (values: string | string[]) => React.ReactNode;
  startIcon: JSX.Element;
  placeholder: string;
  multiple?: boolean;
  refEl?: (ref: Element | null) => void;
  menuProps?: Partial<MenuProps>;
  disabled?: boolean;
}

export const FilterAutocomplete: FC<PropsWithChildren<Props>> = (props) => {
  const {
    values,
    selectedValues,
    onSelected,
    renderValue,
    startIcon,
    placeholder,
    multiple,
    refEl: ref,
    menuProps,
    children,
    disabled,
  } = props;

  const [open, setOpen] = useState(false);

  const isMenuDialogMode = useMemo(() => menuProps && Object.keys(menuProps).length > 0, [menuProps]);

  return (
    <Select
      disabled={disabled}
      ref={(r) => ref?.call(this, r as Element | null)}
      open={open}
      className={clsx({
        selected: selectedValues.length > 0,
      })}
      sx={(theme) => ({
        display: "flex",
        flex: 1,
        pl: 1,
        "&.selected": {
          backgroundColor: makeLighterBackgroundFromColor(theme.palette.primary.main),
          fieldset: {
            color: theme.palette.primary.main,
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
          },
        },
        ".MuiSelect-select": {
          ml: "5px",
        },
      })}
      autoFocus={false}
      multiple={multiple !== false}
      value={selectedValues}
      MenuProps={{ ...menuProps }}
      onChange={(event) => onSelected(event.target.value as string[])}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      input={
        <OutlinedInput
          className={clsx({
            selected: selectedValues.length > 0,
          })}
        />
      }
      displayEmpty={true}
      startAdornment={startIcon}
      renderValue={(selected) => {
        if (renderValue) {
          return renderValue(selected);
        }
        if (typeof selected === "string") {
          const value = selected;
          if (value === "") {
            return placeholder;
          }
          return selected;
        } else {
          if (selected.length === 0) {
            return placeholder;
          }
          return selected.join(", ");
        }
      }}
    >
      {isMenuDialogMode && (
        <Typography
          variant="h5"
          sx={{
            paddingLeft: "1rem",
            paddingBottom: ".5rem",
          }}
        >
          {placeholder}
        </Typography>
      )}
      {children
        ? children
        : ((values as string[]) || []).map((name) => (
            <MenuItem key={name} value={name} sx={{ py: 0 }}>
              <Checkbox checked={selectedValues.indexOf(name) > -1} />
              <ListItemText sx={{ whiteSpace: "break-spaces" }} primary={name} />
            </MenuItem>
          ))}
      {isMenuDialogMode && (
        <Box display="flex" flexGrow={1} justifyContent="center">
          <CloseFilterButton onClick={() => setOpen(false)} />
        </Box>
      )}
    </Select>
  );
};

export default FilterAutocomplete;
