import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { api } from "../api/client";
import { generateAttachmentUrlData } from "../services/attachments";
import { loadInboxStructure } from "../store/state/inboxStructure/thunks";
import { MessageActions } from "../store/state/messages/actions";
import { Message, MessageLoadingState } from "../store/state/messages/types";
import { AppDispatch } from "../store/store";

export const usePdfPreview = () => {
  const dispatch: AppDispatch = useDispatch();

  const markMessageAsRead = useCallback(
    async (message: Message) => {
      if (!message.isRead) {
        const response = await api.messages.markAsRead(message.id);
        if (response.success && response.data.isRead === true) {
          dispatch(MessageActions.MarkAsRead(message.id));
          dispatch(loadInboxStructure());
        }
      }
    },
    [dispatch]
  );

  const prepareMessageForPreview = useCallback(
    async (message: Message, updateReadStatus = true) => {
      dispatch(MessageActions.UpdateLoading({ markAsImportantUpdating: true } as MessageLoadingState));
      const detailsResp = await api.messages.getDetails(message.id);
      if (detailsResp.success && detailsResp.data?.attachments) {
        if (updateReadStatus) {
          markMessageAsRead(message);
        }
        const attachment = detailsResp.data.attachments[0];
        if (attachment) {
          dispatch(MessageActions.UpdateLoading({ markAsImportantUpdating: true } as MessageLoadingState));
          const downloadData = await generateAttachmentUrlData(message.id, attachment.id);
          dispatch(MessageActions.UpdateLoading({ markAsImportantUpdating: false } as MessageLoadingState));
          return downloadData;
        }
      }

      return Promise.reject();
    },
    [dispatch, markMessageAsRead]
  );

  const prepareLinkForPreview = useCallback(async (messageId: string, attachmentId: string) => {
    const downloadData = await generateAttachmentUrlData(messageId, attachmentId);
    return downloadData;
  }, []);

  return { prepareMessageForPreview, prepareLinkForPreview };
};
