import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreviewFileDialog from "../../../../shared/components/previewFile/PreviewFileDialog";
import { convertISODateTimeWithSeconds } from "../../../../shared/utilities/dateUtils";
import { useInboxStateContext } from "../../../contexts/InboxStateContext";
import { getFundFromMessage, getRecipientsFromMessage } from "../../../store/state/messages/messageHelper";
import { currentMessageSelector, markAsImportantUpdatingSelector } from "../../../store/state/messages/selectors";
import { markAsImportant } from "../../../store/state/messages/thunks";
import { FileDownloadInfo } from "../../../store/state/messages/types";
import { impersonationSelector } from "../../../store/state/user/selectors";
import { AppDispatch } from "../../../store/store";
import { MessageLevelComponent } from "../messageLevel/MessageLevelComponent";
import AttachmentsContainer from "./AttachmentsContainer";

interface Props {
  downloadAttachment: (messageId: string, attachmentId: string) => void;
}

export const Content = (props: Props) => {
  const { downloadAttachment } = props;

  const [previewFileData, setPreviewFileData] = useState<FileDownloadInfo | undefined>(undefined);

  const dispatch: AppDispatch = useDispatch();
  const message = useSelector(currentMessageSelector);
  const applyingImportance = useSelector(markAsImportantUpdatingSelector);
  const isImpersonation = useSelector(impersonationSelector);

  const { ui } = useInboxStateContext();

  const changeImportance = useCallback(() => {
    if (message) {
      dispatch(markAsImportant([message.id], !message.isImportant));
    }
  }, [message, dispatch]);

  if (message === undefined) {
    return null;
  }

  return (
    <Grid container direction={"column"}>
      <Grid container direction={"column"} p={2.5} gap={1.5}>
        <Typography variant="subtitle2">{getFundFromMessage(message)}</Typography>
        <Grid container gap={1} alignItems="center">
          <MessageLevelComponent level={message.messageLevel} value={message.categoryId} />
          <Typography variant="caption" color={"secondary"}>
            {convertISODateTimeWithSeconds(message.sendDate)}
          </Typography>
        </Grid>
        <Typography variant="subtitle2">To: {getRecipientsFromMessage(message)}</Typography>
      </Grid>
      <Divider />
      <Grid container direction={"column"} px={2.5} py={2}>
        <Grid container alignItems={"center"}>
          <Typography variant="h5">{message.content.subject}</Typography>
          <IconButton
            disabled={applyingImportance}
            onClick={() => {
              if (!isImpersonation) {
                changeImportance();
              }
            }}
          >
            <LabelImportantIcon style={{ color: message?.isImportant ? "#FFB400" : "inherit" }} />
          </IconButton>
        </Grid>
        <Box dangerouslySetInnerHTML={{ __html: message.content.htmlBody || "" }}></Box>
        <AttachmentsContainer
          message={message}
          loading={ui.messageDetailLoading}
          onPreview={(downloadData) => {
            setPreviewFileData(downloadData);
            window.scrollTo(0, 0);
          }}
          onDownloadAttachment={downloadAttachment}
        />
      </Grid>
      {previewFileData && message && !ui.messageDetailLoading && (
        <PreviewFileDialog
          url={previewFileData.downloadUrl}
          fileName={previewFileData.fileName}
          documentViewSessionId={previewFileData.documentViewSessionId}
          onClose={() => setPreviewFileData(undefined)}
        />
      )}
    </Grid>
  );
};

export default Content;
