import { InboxStructureLoadingEnum, InboxStructureTree } from "./types";

export enum InboxStructureActionsEnum {
  Loaded = "inboxStructure/loaded",
  UpdateLoading = "inboxStructure/loading",
}

export type LoadedAction = { type: InboxStructureActionsEnum.Loaded; payload: InboxStructureTree };
export type UpdateLoadingAction = { type: InboxStructureActionsEnum.UpdateLoading; payload: InboxStructureLoadingEnum };
export type InboxActions = LoadedAction | UpdateLoadingAction;
