import { Button } from "@mui/material";
import { InboxCriteria, InboxUiState, useInboxStateContext } from "../../../contexts/InboxStateContext";
import { isFilterApplied } from "../../../hooks/inboxState/inboxState.utils";

interface Props {
  updateCriteria: (value: Partial<InboxCriteria>) => void;
  updateUi: (value: Partial<InboxUiState>) => void;
}

export const ClearAllButton = (props: Props) => {
  const { updateCriteria, updateUi } = props;

  const { criteria } = useInboxStateContext();
  const filterApplied = isFilterApplied(criteria);

  if (!filterApplied) {
    return <></>;
  }

  return (
    <Button
      variant="text"
      style={{ whiteSpace: "nowrap" }}
      onClick={() => {
        updateUi({ total: undefined });
        updateCriteria({
          investors: undefined,
          funds: undefined,
          entities: undefined,
          categories: undefined,
          from: undefined,
          to: undefined,
          descriptiveDate: undefined,
        });
      }}
    >
      Clear all
    </Button>
  );
};

export default ClearAllButton;
