import { Divider, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import InboxTreeComponent from "../inboxTree/InboxTreeComponent";

export const InboxDesktopComponent = () => {
  return (
    <Grid container direction="row" flex={1}>
      <Grid container sx={{ width: "19rem" }}>
        <InboxTreeComponent />
      </Grid>
      <Divider orientation="vertical" />
      <Grid container flex={1}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default InboxDesktopComponent;
