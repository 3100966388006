import { Stack, Typography } from "@mui/material";
import { formatDate } from "../../../../../shared/utilities/dateUtils";
import { useLocalization } from "../../../../hooks/useLocalization";

interface Props {
  reportingDate?: Date;
  dateColor?: "textPrimary" | "textSecondary";
}

const EffectiveReportingDate = ({ reportingDate, dateColor }: Props) => {
  const locale = useLocalization();

  return (
    <Stack spacing={0.5} direction="row">
      <Typography color="textSecondary">{locale.dashboard.balance.reportingPeriod}:</Typography>
      <Typography color={dateColor}>{reportingDate && formatDate(reportingDate)}</Typography>
    </Stack>
  );
};

export default EffectiveReportingDate;
