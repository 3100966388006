import { AccessPermission } from "../../../../shared/api/types";
import { IConsentAgreement as ConsentAgreement } from "../../../../shared/components/consentAgreement/types";
import { FundraisingInfo } from "../../../api/types/fundraisingTypes";
import { AccessCategory } from "../../../api/types/settingsTypes";

export interface UserState {
  authenticated: boolean;
  userName: string;
  email: string;
  permissions: ClientPermissionSet[];
  identity?: Identity;
  loading: IdentityLoading;
  selectedInvestorId?: string;
  isImpersonationMode: boolean;
  clientCode: string;
}

export interface UserPermissions {
  userName: string;
  email: string;
  permissions: ClientPermissionSet[];
}

export interface ClientPermissionSet {
  clientCode: string;
  clientTitle: string;
  investorsPermissions: InvestorPermissions[];
  portalSettings: PortalSettings;
  unsignedConsents?: ConsentAgreement[];
  financialPermissions: AccessCategoryPermissions[];
  fundraisings: FundraisingInfo[];
  messageCategoryIds: string[];
  featureToggles: Partial<Record<IrFeatureToggles, boolean>>;
}

export type IrFeatureToggles = "_"; // Add feature toggles here

export interface AccessCategoryPermissions {
  category: AccessCategory;
  investor: InvestorsWithFunds[];
}

export interface FundBase {
  id: string;
  name: string;
}

export interface InvestorsWithFunds {
  investorTitle: string;
  funds: FundBase[];
}

export interface InvestorPermissions {
  investorId: string;
  investorTitle: string;
  fundInvestors: FundInvestorPermissions[];
  investorLevelPermissions: AccessPermission[];
}

export type PortalSettings = {
  enabledFeatures?: string;
  enabledPreviewOnlyFeatures?: string;
  appBarColor?: string;
  appBarTextColor?: string;
  mainColor?: string;
  logoTypeUrl?: string;
  logoTypeSize?: RelativeLogoSize;
  logoMarkUrl?: string;
  faviconUrl?: string;
  bannerUrl?: string;
  bannerFontColor?: string;
  supportEmail?: string;
  supportPhone?: string;
  contactInvitationEmailCompanyLogoUrl?: string;
};

export type RelativeLogoSize = "Small" | "Medium" | "Large";

export type InvestorRelationPortalFeatures =
  | "Inbox"
  | "Dashboards"
  | "PortfolioCompanies"
  | "AccountSettingsViewer"
  | "AccountSettingsEditor"
  | "BankAccountsDetailsViewer"
  | "BankAccountsDetailsEditor"
  | "Membership"
  | "Fundraising";

export interface FundInvestorPermissions {
  fundInvestorId: string;
  fundId: string;
  fundName: string;
  permissions: AccessPermission[];
}

export interface Identity {
  id: string;
  name: string;
  phone: string;
  email: string;
  signInEmail: string;
  avatarImage?: string;
  largeImage?: boolean;
  consentAgreements?: ConsentAgreement[];
}

export interface UpdateIdentityRequest {
  name?: string;
  phone?: string;
  email?: string;
  avatarImage?: string;
  consentAgreements?: ConsentAgreement[];
  agreeToNews?: boolean;
}

export interface AcceptedConsentAgreement {
  type: string;
  version: number;
  name: string;
}

export interface IdentityLoading {
  identity: boolean;
  state?: IdentityLoadingState;
}

export enum IdentityLoadingState {
  None = 0,
  Loaded = 2,
  LoadingError = 3,
  LoadingErrorImpersonationPermissions = 4,
  AuthorizationOrAccessError = 5,
}

export type FinancialAccessCategory = "CASH FLOWS" | "BALANCE";
