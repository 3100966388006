import FilterListIcon from "@mui/icons-material/FilterList";
import { Box } from "@mui/material";
import CircleIcon from "../../../../shared/icons/CircleIcon";

interface Props {
  isApplied: boolean;
  color: string;
}

const FiltersIcon = ({ isApplied, color }: Props) => {
  return (
    <>
      <FilterListIcon />
      {isApplied && (
        <Box sx={{ position: "absolute", ml: 3, mb: 4 }}>
          <CircleIcon fill={color} radius={4} />
        </Box>
      )}
    </>
  );
};

export default FiltersIcon;
