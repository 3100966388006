import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { usePdfPreview } from "../../../hooks/pdfPreview";
import { useLocalization } from "../../../hooks/useLocalization";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { FileDownloadInfo, Message } from "../../../store/state/messages/types";
import { AttachmentComponent } from "./AttachmentComponent";

interface Props {
  message: Message;
  loading: boolean;
  onPreview: (data: FileDownloadInfo) => void;
  onDownloadAttachment: (messageId: string, attachmentId: string) => void;
}

export const AttachmentsContainer = (props: Props) => {
  const { message, loading, onPreview, onDownloadAttachment } = props;
  const { sendNotificationError } = useNotificationContext();
  const { prepareLinkForPreview } = usePdfPreview();
  const locale = useLocalization();
  const queryParams = useQueryParams();
  const attachmentId = queryParams.get("attachment");

  const previewFile = useCallback(
    (attachmentId: string) => {
      prepareLinkForPreview(message.id, attachmentId)
        .then(onPreview)
        .catch(() => {
          sendNotificationError(locale.inbox.preview_file_error);
        });
    },
    [message, locale.inbox.preview_file_error, prepareLinkForPreview, onPreview, sendNotificationError]
  );

  useEffect(() => {
    if (attachmentId) {
      previewFile(attachmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentId]);

  if (!message || loading || !message.attachments || message.attachments?.length <= 0) {
    return <></>;
  }

  return (
    <Grid container direction="column" gap={2}>
      <Typography variant="subtitle1">{message.attachments.length > 1 ? "Attachments" : "Attachment"}</Typography>
      {message.attachments.map((attachment) => {
        return (
          <AttachmentComponent
            key={attachment.id}
            attachment={attachment}
            onPreview={() => previewFile(attachment.id)}
            onDownload={() => onDownloadAttachment(message.id, attachment.id)}
          />
        );
      })}
    </Grid>
  );
};

export default AttachmentsContainer;
