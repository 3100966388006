import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { Box, IconButton, Stack } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ModalPage from "../../../common/ModalPage";
import { useLocalization } from "../../../../hooks/useLocalization";
import { selectCashFlowLoading } from "../../../../store/state/dashboard/selectors";
import { CapCallOrDistribution, cashFlowDefaults } from "../../../../store/state/dashboard/types";
import CashFlowCard from "./CashFlowCard";
import NoDataResult from "../../common/NoDataResult";
import CashFlowsView from "../CashFlowsView";
import CashFlowsMobilePageHeader from "./CashFlowsMobilePageHeader";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import { fetchCashFlowData } from "../../../../store/state/dashboard/thunk";

type BalanceMobilePageProps = {
  onClose: () => void;
};

const CashFlowsModalPage = ({ onClose }: BalanceMobilePageProps) => {
  const dispatch: AppDispatch = useDispatch();
  const locale = useLocalization().dashboard.cashFlows;
  const loading = useSelector(selectCashFlowLoading);

  const handleBack = useDebounce(() => {
    dispatch(fetchCashFlowData({ paging: { page: 0, size: cashFlowDefaults.defaultCashFlowPageSize } }));
    onClose();
  }, 10);

  const CapCallsDisplayData = (capCalls: CapCallOrDistribution[]) => {
    if (capCalls.length === 0) {
      return <NoDataResult text={locale.no_data} />;
    }

    return (
      <>
        {capCalls.map((capCall, i) => (
          <Box key={"cc-wrap" + i} py={1}>
            <CashFlowCard key={"cap-call" + i} capCall={capCall} />
          </Box>
        ))}
      </>
    );
  };

  return (
    <ModalPage
      sxContent={{ py: 1, px: 2 }}
      open
      toolbarChildren={
        <CashFlowsMobilePageHeader
          backButton={
            <IconButton edge="start" disabled={loading} onClick={handleBack}>
              <ArrowBackRoundedIcon />
            </IconButton>
          }
        />
      }
    >
      <Stack spacing={2}>
        <CashFlowsView renderItemsElement={CapCallsDisplayData} />
      </Stack>
    </ModalPage>
  );
};

export default CashFlowsModalPage;
