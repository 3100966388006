import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import { Ref, forwardRef } from "react";
import { Contact, FundInvestor } from "../../../../../api/types/settingsTypes";
import ViewPermissions from "./ViewPermissions";

interface Props {
  containerProps?: SxProps<Theme>;
  contact: Contact;
  investor: FundInvestor;
  onClose: () => void;
}

const ViewAccess = forwardRef((props: Props, ref: Ref<unknown>) => {
  const { containerProps, onClose, contact, investor } = props;

  return (
    <Box
      ref={ref}
      sx={{
        ...{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          minwidth: "30rem",
          gap: "1rem",
          padding: "2rem",
        },
        ...containerProps,
      }}
    >
      <Box>
        <Typography variant="h5" sx={(theme) => ({ color: theme.palette.text.primary })}>
          {contact.name}
        </Typography>
        <Typography sx={(theme) => ({ color: theme.palette.text.secondary })}>{investor.title}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="1rem">
        <ViewPermissions contact={contact} fundName={investor.fund.name} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "flex-end",
          gap: "2rem",
          mt: ".5rem",
        }}
      >
        <Button variant="contained" size="medium" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
});

export default ViewAccess;
