import { Box, Typography } from "@mui/material";
import { IItem } from "../../../../store/state/inboxStructure/types";
import InboxBadge from "../../../common/InboxBadge";
import DynamicIcon from "../DynamicIcon";
import Item from "./Item";

interface Props {
  selectedItem: string;
  item: IItem;
  onClick: () => void;
}

export const GeneralItem = (props: Props) => {
  const { item, selectedItem, onClick } = props;
  return (
    <Item item={item.caption} selectedItem={selectedItem} onClick={onClick}>
      <DynamicIcon iconName={item.caption} />
      <Box sx={{ display: "flex", flex: 1 }}>
        <Typography variant="subtitle2" pl={1}>
          {item.caption}
        </Typography>
      </Box>
      <InboxBadge value={item.unread || 0} />
    </Item>
  );
};
export default GeneralItem;
