import { Box } from "@mui/material";
import { useCallback } from "react";
import { createApiResponse } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { api } from "../../../../api/client";
import { FundInvestorPermissions } from "../../../../store/state/user/types";
import { BankAccountsContextProvider } from "./BankAccountsContext";
import BankAccountsView from "./BankAccountsView";
import ChangeRequestsStatus from "./ChangeRequestsStatus";
import NoBankAccounts from "./NoBankAccounts";
import { getAvailableFundInvestorIds } from "./fundAssignmentsHelper";
import { useBankSettingsNavigation } from "./navigation";

interface Props {
  canUserEdit: boolean;
  investorId: string;
  fundInvestors: FundInvestorPermissions[];
}

const BankAccounts = ({ canUserEdit, investorId, fundInvestors }: Props) => {
  const { navigateToNewBankAccountPage } = useBankSettingsNavigation();

  const getBankAccounts = useCallback(() => api.bankAccounts.getBankAccounts(investorId), [investorId]);

  const getPendingChangeRequests = useCallback(
    () =>
      canUserEdit
        ? api.changeRequests.getPendingChangeRequests(investorId, "InvestorBankAccountChangeRequest")
        : Promise.resolve(createApiResponse({ changeRequestDetails: [] })),
    [canUserEdit, investorId]
  );

  const [bankAccountsResp, fetchBankAccountsError, { isFetching: isFetchingBankAccounts }] = useFetch(getBankAccounts);

  const [
    pendingChangeRequestsResp,
    fetchPendingError,
    { isFetching: isFetchingPending, setData: setPendingChangeRequests },
  ] = useFetch(getPendingChangeRequests);

  if (fetchBankAccountsError || fetchPendingError) {
    logError(fetchBankAccountsError || fetchPendingError, "[BankAccounts]");
    return <DataLoadingFailed title="Failed to load bank accounts" />;
  }

  if (
    bankAccountsResp === undefined ||
    pendingChangeRequestsResp === undefined ||
    isFetchingBankAccounts ||
    isFetchingPending
  ) {
    return <InlineLoader />;
  }

  const availableFundInvestorIds = getAvailableFundInvestorIds(
    fundInvestors,
    pendingChangeRequestsResp.changeRequestDetails
  );

  const handleAddBankAccount = () => {
    navigateToNewBankAccountPage({ availableFundInvestorIds });
  };

  const handleChangeRequestCanceled = (workflowId: string) => {
    setPendingChangeRequests((previousData) =>
      previousData
        ? {
            ...previousData,
            changeRequestDetails: previousData?.changeRequestDetails.filter((cr) => cr.workflowId !== workflowId),
          }
        : previousData
    );
  };

  const pendingChangeRequests = pendingChangeRequestsResp.changeRequestDetails;

  return (
    <BankAccountsContextProvider
      canUserEdit={canUserEdit}
      investorId={investorId}
      fundInvestors={fundInvestors}
      availableFundInvestorIds={availableFundInvestorIds}
      bankAccounts={bankAccountsResp.bankAccounts}
      fundAssignments={bankAccountsResp.assignments}
      pendingChangeRequests={pendingChangeRequests}
    >
      {bankAccountsResp.bankAccounts.length === 0 ? (
        <>
          {canUserEdit && <ChangeRequestsStatus onChangeRequestCanceled={handleChangeRequestCanceled} />}
          <Box pt={1} display="flex" justifyContent="center" alignItems="center" width="100%" height="75vh">
            <NoBankAccounts canEdit={canUserEdit} onAddBankAccount={handleAddBankAccount} />
          </Box>
        </>
      ) : (
        <BankAccountsView
          onAddBankAccount={handleAddBankAccount}
          onChangeRequestCanceled={handleChangeRequestCanceled}
        />
      )}
    </BankAccountsContextProvider>
  );
};

export default BankAccounts;
