import { SvgIconComponent } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  text: string;
  page: string;
  disabled?: boolean;
  Icon: SvgIconComponent;
  color?: "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

export const UserMenuItem = (props: Props) => {
  const { text, page, Icon, disabled } = props;
  return (
    <MenuItem disabled={!!disabled} component={RouterLink} to={page} sx={{ pl: 3, py: 1 }}>
      <ListItemIcon>
        <Icon color="secondary" />
      </ListItemIcon>
      <ListItemText>
        <Typography>{text}</Typography>
      </ListItemText>
    </MenuItem>
  );
};

export default UserMenuItem;
