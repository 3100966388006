import { Autocomplete, Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { Dispatch } from "react";
import CountryFlag from "../../../../../../shared/components/CountryFlag";
import { useSettingsContext } from "../../../SettingsContext";
import { Action, NewBankAccountState } from "./newBankAccountState";

interface Props {
  state: NewBankAccountState;
  dispatch: Dispatch<Action>;
}

const NewBankAccountForm = ({ state, dispatch }: Props) => {
  const { dictionaries } = useSettingsContext();

  const countriesOptions = dictionaries.countries.map(({ code }) => code);
  const currenciesOptions = dictionaries.currencies.map(({ code }) => code);

  const showAbaNumber = state.bankAccountForm.countryCode === "US";

  return (
    <Stack py={2.5} spacing={4}>
      <Stack spacing={2.5}>
        <Typography variant="subtitle2">Bank Details</Typography>

        <Autocomplete
          fullWidth
          includeInputInList
          options={countriesOptions}
          value={state.bankAccountForm.values.country}
          onChange={(_e, newValue) => dispatch({ type: "update_field", field: "country", value: newValue || "" })}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Bank Country"
              error={!!state.bankAccountForm.errors.country}
              helperText={state.bankAccountForm.errors.country}
              InputProps={{
                ...params.InputProps,
                startAdornment: state.bankAccountForm.countryCode ? (
                  <CountryFlag countryCodeAlpha2={state.bankAccountForm.countryCode} typographyProps={{ ml: 1 }} />
                ) : undefined,
              }}
            />
          )}
        />

        <TextField
          required
          fullWidth
          label="Bank Name"
          variant="outlined"
          value={state.bankAccountForm.values.bankName}
          onChange={(e) => dispatch({ type: "update_field", field: "bankName", value: e.target.value })}
          error={!!state.bankAccountForm.errors.bankName}
          helperText={state.bankAccountForm.errors.bankName}
        />

        <Stack direction="row" spacing={2}>
          {showAbaNumber && (
            <TextField
              required
              fullWidth
              label="ABA Routing Number"
              variant="outlined"
              value={state.bankAccountForm.values.abaNumber}
              onChange={(e) => dispatch({ type: "update_field", field: "abaNumber", value: e.target.value })}
              error={!!state.bankAccountForm.errors.abaNumber}
              helperText={state.bankAccountForm.errors.abaNumber}
            />
          )}

          <TextField
            required={!showAbaNumber}
            fullWidth
            label="SWIFT / BIC Code"
            variant="outlined"
            value={state.bankAccountForm.values.swiftCode}
            onChange={(e) => dispatch({ type: "update_field", field: "swiftCode", value: e.target.value })}
            error={!!state.bankAccountForm.errors.swiftCode}
            helperText={state.bankAccountForm.errors.swiftCode}
          />
        </Stack>
      </Stack>

      <Stack spacing={2.5}>
        <Typography variant="subtitle2">Account Details</Typography>
        <TextField
          required
          fullWidth
          label="Account Name"
          variant="outlined"
          value={state.bankAccountForm.values.accountName}
          onChange={(e) => dispatch({ type: "update_field", field: "accountName", value: e.target.value })}
          error={!!state.bankAccountForm.errors.accountName}
          helperText={
            state.bankAccountForm.errors.accountName ||
            "The account is registered under this name, usually aligning with the investor's legal name."
          }
        />

        <Stack direction="row" spacing={2}>
          <TextField
            required
            fullWidth
            label="Account Number / IBAN"
            variant="outlined"
            value={state.bankAccountForm.values.accountNumber}
            onChange={(e) => dispatch({ type: "update_field", field: "accountNumber", value: e.target.value })}
            error={!!state.bankAccountForm.errors.accountNumber}
            helperText={state.bankAccountForm.errors.accountNumber}
          />

          <Autocomplete
            includeInputInList
            options={currenciesOptions}
            value={state.bankAccountForm.values.currencyCode}
            onChange={(_e, newValue) =>
              dispatch({ type: "update_field", field: "currencyCode", value: newValue || "" })
            }
            sx={{ width: "10rem" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Currency"
                error={!!state.bankAccountForm.errors.currencyCode}
                helperText={state.bankAccountForm.errors.currencyCode}
              />
            )}
          />
        </Stack>

        <FormControlLabel
          control={
            <Checkbox
              checked={state.bankAccountForm.addForFurtherCredit}
              onChange={(_, value) => dispatch({ type: "update_addForFurtherCredit", value })}
              tabIndex={-1}
              sx={{ pl: 0 }}
            />
          }
          label={<Typography>Add for further credit instructions?</Typography>}
        />

        {state.bankAccountForm.addForFurtherCredit && (
          <Stack direction="row" spacing={2}>
            <TextField
              required
              fullWidth
              label="FFC Name"
              variant="outlined"
              value={state.bankAccountForm.values.ffcName}
              onChange={(e) => dispatch({ type: "update_field", field: "ffcName", value: e.target.value })}
              error={!!state.bankAccountForm.errors.ffcName}
              helperText={state.bankAccountForm.errors.ffcName}
            />
            <TextField
              required
              fullWidth
              label="FFC Number"
              variant="outlined"
              value={state.bankAccountForm.values.ffcAccountNumber}
              onChange={(e) => dispatch({ type: "update_field", field: "ffcAccountNumber", value: e.target.value })}
              error={!!state.bankAccountForm.errors.ffcAccountNumber}
              helperText={state.bankAccountForm.errors.ffcAccountNumber}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default NewBankAccountForm;
