export interface TreeNode {
  id: string;
  label: string;
  selected: SelectedState;
  children: TreeNode[];
  parentId?: string;
}

export type SelectedState = boolean | null;

export const cloneNodes = (nodes: TreeNode[]): TreeNode[] => [
  ...nodes.map((node) => ({ ...node, children: [...node.children] })),
];

export const getAllNodesFlat = (nodes: TreeNode[]) => nodes.flatMap((node) => [...node.children, node]);

const findNode = (nodes: TreeNode[], id: string) => getAllNodesFlat(nodes).find((node) => node.id === id);

const updateParentNode = (parentNode: TreeNode, childId: string, childChecked: boolean) => {
  const childWithOtherStateExists = parentNode.children.some(
    (childNode) => childNode.id !== childId && childNode.selected !== childChecked
  );

  parentNode.selected = childWithOtherStateExists ? null : childChecked;
};

export const updateNodes = (nodes: TreeNode[], updatedId: string, checked: boolean): TreeNode[] => {
  const result = cloneNodes(nodes);

  const node = findNode(result, updatedId);
  if (node === undefined) {
    return result;
  }

  node.selected = checked;

  node.children.forEach((childNode) => {
    childNode.selected = checked;
  });

  if (node.parentId !== undefined) {
    const parentNode = findNode(result, node.parentId);
    if (parentNode !== undefined) {
      updateParentNode(parentNode, updatedId, checked);
    }
  }

  return result;
};
