import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../../shared/hooks/useDebounce";
import { groupByToMap } from "../../../shared/utilities/arrayHelper";
import { ImpersonationContact, ImpersonationData, ImpersonationInvestor } from "../../api/types/impersonationTypes";
import { useClientContext } from "../../contexts/ClientContext";
import { viewAs } from "../../impersonation/impersonationHandler";
import ImpersonationInvestorList from "./ImpersonationInvestorList";

const getFirstInvestorMatch = (investors: ImpersonationInvestor[], email: string) => {
  return investors.find((investor) => investor.contacts.find((contact) => contact.email === email));
};

const contactsIncludeValue = (contacts: ImpersonationContact[], value: string): boolean => {
  return (
    contacts.filter((contact) => {
      return contact.email.toLowerCase().includes(value) || contact.name.toLowerCase().includes(value);
    }).length > 0
  );
};

const filterEmptyContacts = (investors: ImpersonationInvestor[] | undefined) => {
  return !investors ? investors : investors.filter((inv) => inv.contacts.length > 0);
};

const ImpersonationSearchPanel = (props: { userName: string; email: string; impersonationData: ImpersonationData }) => {
  const { impersonationData, email } = props;

  const fundGrouped = useMemo(() => {
    return groupByToMap(impersonationData.investors, (investor) => investor.fundName);
  }, [impersonationData.investors]);

  const fundNames = useMemo(
    () =>
      Array.from(fundGrouped.entries())
        .filter(([, investors]) => (filterEmptyContacts(investors) ?? []).length > 0)
        .map(([name]) => name),
    [fundGrouped]
  );

  const { fund } = useClientContext();

  const existingFundName = fundNames.includes(fund || "") ? fund : undefined;

  const [currentFundName, setCurrentFund] = useState<string>(
    existingFundName || getFirstInvestorMatch(impersonationData.investors, email)?.fundName || ""
  );
  const [investorFilterText, setInvestorFilter] = useState<string>("");
  const [currentInvestors, setCurrentInvestors] = useState<ImpersonationInvestor[] | undefined>(
    filterEmptyContacts(fundGrouped.get(currentFundName))
  );
  const debounceFilterText = useDebounce((value: string) => {
    setInvestorFilter(value);
  }, 400);

  useEffect(() => {
    if (currentFundName) {
      const fundInvestors = fundGrouped.get(currentFundName) || [];

      if (investorFilterText === "") {
        setCurrentInvestors(fundInvestors);
      } else {
        const value = investorFilterText.toLowerCase();
        const filteredInvertors = fundInvestors?.filter((inv) => {
          return (
            inv.title.toLowerCase().includes(value) ||
            inv.fundName.toLowerCase().includes(value) ||
            contactsIncludeValue(inv.contacts, value)
          );
        });
        setCurrentInvestors(filterEmptyContacts(filteredInvertors));
      }
    }
  }, [investorFilterText, currentFundName, fundGrouped]);

  useEffect(() => {
    const newInvestors = fundGrouped.get(currentFundName);
    setCurrentInvestors(filterEmptyContacts(newInvestors));
  }, [currentFundName, fundGrouped]);

  const handleImpersonationView = (email: string) => {
    viewAs(email, currentFundName || "");
  };

  const onFundChange = (newFund: string) => {
    setCurrentFund(newFund);
  };

  const searchOptionsList = currentInvestors
    ?.flatMap((investor) => {
      return investor.contacts.map((ct) => ct.name);
    })
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    })
    .sort()
    .map((contact) => {
      return { label: contact };
    });

  const ColumnLabel = (text: string) => {
    return (
      <Box
        sx={(theme) => ({
          textAlign: "center",
          height: "2rem",
          width: "100%",
          lineHeight: "140%",
          padding: theme.spacing(2),
        })}
      >
        <Typography variant="subtitle1">{text}</Typography>
      </Box>
    );
  };

  const columnStyles = {
    py: 2,
    px: 3,
  };

  return (
    <>
      <Grid container>
        <Grid sx={{ px: 2 }} item xs={7}>
          <Autocomplete
            disablePortal
            freeSolo
            value={investorFilterText}
            sx={{ backgroundColor: grey[200] }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <SearchIcon />,
                }}
              />
            )}
            onInputChange={(_, value) => debounceFilterText(value.trim())}
            options={searchOptionsList || []}
          />
        </Grid>
        <Grid sx={{ px: 2 }} item xs={5}>
          <Select
            sx={(theme) => ({
              width: "100%",
              "&.MuiMenu-list": {
                py: theme.spacing(0.5),
              },
            })}
            onChange={(e: SelectChangeEvent<string>) => onFundChange(e.target.value)}
            value={currentFundName}
          >
            {fundNames.map((fund) => (
              <MenuItem key={fund} value={fund}>
                {fund}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item container sx={columnStyles} xs={5}>
          {ColumnLabel("Name")}
        </Grid>
        <Grid item container sx={columnStyles} xs={5}>
          {ColumnLabel("Email")}
        </Grid>
      </Grid>
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <ImpersonationInvestorList
          handleImpersonationView={handleImpersonationView}
          contactEmail={email}
          filterText={investorFilterText}
          investors={currentInvestors || []}
        />
      </Box>
    </>
  );
};

export default ImpersonationSearchPanel;
