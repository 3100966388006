import { MenuProps, Popover, PopoverProps } from "@mui/material";
import { endOfDay, parseISO, startOfDay } from "date-fns";
import DateRangeSelector from "../../../../shared/components/dateRangeSelector/DateRangeSelector";
import { DescriptiveDate } from "../../../../shared/utilities/dateFilterHelper";
import { formatDateShort } from "../../../../shared/utilities/dateUtils";

interface Props {
  anchorEl: Element | null;
  open: boolean;
  menuProps?: Partial<MenuProps>;
  onFilterDateSelected: (value: DescriptiveDate) => void;
  onClose: () => void;
  descriptiveDate?: DescriptiveDate;
  options: DescriptiveDate[];
}

export const customDescriptiveDate: DescriptiveDate = { title: "Custom range" };

export const CustomDatePopup = ({
  anchorEl,
  open,
  onFilterDateSelected,
  onClose,
  menuProps,
  descriptiveDate,
  options,
}: Props) => {
  const isCustomRangeSelected = descriptiveDate?.title === customDescriptiveDate.title;

  const handleApply = ([customFrom, customTo]: [Date, Date]) => {
    const customDate = isCustomRangeSelected
      ? { ...customDescriptiveDate, ...descriptiveDate }
      : { ...customDescriptiveDate };

    const dates = [...options, customDate];
    const date = dates.find((d) => d.title === customDescriptiveDate.title);
    if (!date) {
      onClose();
      return;
    }

    date.from = startOfDay(customFrom).toISOString();
    date.to = endOfDay(customTo).toISOString();

    const fromStr = formatDateShort(customFrom);
    const toStr = formatDateShort(customTo);
    if (fromStr !== toStr) {
      date.description = `${fromStr} - ${toStr}`;
    } else {
      date.description = fromStr;
    }

    onFilterDateSelected(date);
    onClose();
  };

  const from = isCustomRangeSelected && descriptiveDate?.from ? parseISO(descriptiveDate.from) : new Date();
  const to = isCustomRangeSelected && descriptiveDate?.to ? parseISO(descriptiveDate.to) : new Date();

  const customMenuProps: Partial<PopoverProps> =
    menuProps && Object.keys(menuProps).length > 0
      ? {
          anchorEl: anchorEl,
          anchorOrigin: menuProps.anchorOrigin,
          transformOrigin: menuProps.transformOrigin,
        }
      : {
          anchorEl: anchorEl,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        };

  return (
    <Popover open={open} {...customMenuProps}>
      <DateRangeSelector from={from} to={to} onApply={handleApply} onCancel={onClose} />
    </Popover>
  );
};

export default CustomDatePopup;
