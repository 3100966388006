import { SvgIcon, SvgIconProps } from "@mui/material";

export const CapitalAccountStatementIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 20C1.1 20 0.75 19.85 0.45 19.55C0.15 19.25 0 18.9 0 18.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H9.9C10.1 0 10.2958 0.0416667 10.4875 0.125C10.6792 0.208333 10.8417 0.316667 10.975 0.45L15.55 5.025C15.6833 5.15833 15.7917 5.32083 15.875 5.5125C15.9583 5.70417 16 5.9 16 6.1V18.5C16 18.9 15.85 19.25 15.55 19.55C15.25 19.85 14.9 20 14.5 20H1.5ZM8 16.975C8.21667 16.975 8.39583 16.9042 8.5375 16.7625C8.67917 16.6208 8.75 16.45 8.75 16.25V15.975H10.25C10.4667 15.975 10.6458 15.9042 10.7875 15.7625C10.9292 15.6208 11 15.4417 11 15.225V11.975C11 11.7583 10.9292 11.5792 10.7875 11.4375C10.6458 11.2958 10.4667 11.225 10.25 11.225H6.5V9.475H10.25C10.4667 9.475 10.6458 9.40417 10.7875 9.2625C10.9292 9.12083 11 8.94167 11 8.725C11 8.50833 10.9292 8.32917 10.7875 8.1875C10.6458 8.04583 10.4667 7.975 10.25 7.975H8.75V7.725C8.75 7.50833 8.67917 7.32917 8.5375 7.1875C8.39583 7.04583 8.21667 6.975 8 6.975C7.78333 6.975 7.60417 7.04583 7.4625 7.1875C7.32083 7.32917 7.25 7.50833 7.25 7.725V7.975H5.75C5.53333 7.975 5.35417 8.04583 5.2125 8.1875C5.07083 8.32917 5 8.50833 5 8.725V11.975C5 12.1917 5.07083 12.3708 5.2125 12.5125C5.35417 12.6542 5.53333 12.725 5.75 12.725H9.5V14.475H5.75C5.53333 14.475 5.35417 14.5458 5.2125 14.6875C5.07083 14.8292 5 15.0083 5 15.225C5 15.4417 5.075 15.6208 5.225 15.7625C5.375 15.9042 5.55 15.975 5.75 15.975H7.25V16.225C7.25 16.4417 7.32083 16.6208 7.4625 16.7625C7.60417 16.9042 7.78333 16.975 8 16.975ZM10.65 5.475H13.875L9.9 1.5V4.725C9.9 4.94167 9.97083 5.12083 10.1125 5.2625C10.2542 5.40417 10.4333 5.475 10.65 5.475Z"
      />
    </SvgIcon>
  );
};

export default CapitalAccountStatementIcon;
