import { Stack, Typography } from "@mui/material";
import { useLocalization } from "../../../hooks/useLocalization";
import BackToDashboardButton from "../BackToDashboardButton";
import CashFlowFilters from "./CashFlowFilters";

const CashFlowsPageHeader = () => {
  const locale = useLocalization().dashboard.cashFlows;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={3}>
        <BackToDashboardButton />
        <Typography variant="h5">{locale.title}</Typography>
      </Stack>
      <CashFlowFilters />
    </>
  );
};

export default CashFlowsPageHeader;
