import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useClientContext } from "../../contexts/ClientContext";

const BackToDashboardButton = () => {
  const { clientCode } = useClientContext();

  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={<ArrowBackRoundedIcon />}
      component={Link}
      to={`/${clientCode}/dashboard`}
    >
      Back
    </Button>
  );
};

export default BackToDashboardButton;
