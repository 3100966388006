import { Box, Button, Popover, Typography } from "@mui/material";
import { useLocalization } from "../../hooks/useLocalization";

const ImpersonationHelpPopup = (props: { anchorEl: HTMLElement | null; onClose: () => void }) => {
  const locale = useLocalization();
  const { anchorEl, onClose } = props;

  return (
    <Popover
      open
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Box sx={{ p: 2, width: anchorEl?.offsetWidth }}>
        <Box>
          <Typography variant="h4">{locale.impersonation.inspect_help_title}</Typography>
        </Box>
        <Box sx={{ my: 1 }}>
          <Typography>{locale.impersonation.inspect_help_description}</Typography>
        </Box>
        <Box>
          <Typography sx={{ whiteSpace: "pre-line" }}>{locale.impersonation.inspect_help_text}</Typography>
        </Box>
        <Box pt="1rem" display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button variant="contained" color="primary" onClick={onClose}>
            {locale.impersonation.inspect_help_closeButtonText}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default ImpersonationHelpPopup;
