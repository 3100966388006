import { Box, Container, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { financialInvestorsSelector } from "../../store/state/user/selectors";
import Banner from "./Banner";
import BalanceWidget from "./balance/BalanceWidget";
import CashFlowsWidget from "./cashFlows/CashFlowsWidget";

const DashboardPage = () => {
  const balanceInvestors = useSelector(financialInvestorsSelector("BALANCE"));
  const cashFlowInvestors = useSelector(financialInvestorsSelector("CASH FLOWS"));

  return (
    <>
      <Banner />
      <Container maxWidth="xl">
        <Box pt={2} mb={2}>
          <Stack spacing={2}>
            {balanceInvestors.length > 0 && <BalanceWidget />}
            {cashFlowInvestors.length > 0 && <CashFlowsWidget />}
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default DashboardPage;
