import { Dispatch } from "@reduxjs/toolkit";
import { logError } from "../../../../shared/logging";
import { api } from "../../../api/client";
import { InboxActions, InboxStructureActionsEnum } from "./actions";
import { InboxStructureLoadingEnum } from "./types";

type InboxStructureDispatch = Dispatch<InboxActions>;

export function loadInboxStructure(handleErrors = false) {
  return async (dispatch: InboxStructureDispatch) => {
    let state = InboxStructureLoadingEnum.None;
    try {
      const resp = await api.inboxStructure.load();
      if (resp.data) {
        dispatch({ type: InboxStructureActionsEnum.Loaded, payload: resp.data });
        state = InboxStructureLoadingEnum.Loaded;
      } else {
        logError(resp.error?.message, "loadInboxStructure");
        state = InboxStructureLoadingEnum.Error;
      }
    } catch (error) {
      logError(error, "loadInboxStructure");
      state = InboxStructureLoadingEnum.Error;
    } finally {
      if (handleErrors) {
        dispatch({ type: InboxStructureActionsEnum.UpdateLoading, payload: state });
      }
    }
  };
}
