import { HubConnection, HubConnectionState, MessageHeaders } from "@microsoft/signalr";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "../services/signalRConnector";
import { Action } from "../types";

interface Params {
  url: string;
  subscriptions: Subscription[];
  customHeaders: MessageHeaders;
  onConnected?: (connection: HubConnection) => Promise<void>;
  filter?: () => boolean;
}

interface Subscription {
  eventName: string;
  callback: Action;
}

const subscribe = (connection: HubConnection, subscriptions: Subscription[]) => {
  subscriptions.forEach(({ eventName, callback }) => {
    connection.off(eventName);
    connection.on(eventName, callback);
  });
};

const unsubscribe = (connection: HubConnection | undefined, subscriptions: Subscription[]) => {
  if (connection !== undefined) {
    subscriptions.forEach(({ eventName }) => {
      connection.off(eventName);
    });
  }
};

const useSignalR = ({ url, subscriptions, customHeaders, onConnected, filter }: Params) => {
  const [connection, setConnection] = useState<HubConnection>();

  const shouldConnect = useMemo(() => filter === undefined || filter(), [filter]);

  const handleConnected = useCallback(async () => {
    if (connection && connection.state === HubConnectionState.Connected && onConnected) {
      await onConnected(connection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    const connectToHub = async () => {
      const conn = await connect(url, customHeaders);
      if (conn) {
        setConnection(conn);
      }
    };

    if (shouldConnect) {
      connectToHub();
    }

    return () => {
      unsubscribe(connection, subscriptions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, shouldConnect, url]);

  useEffect(() => {
    handleConnected();
  }, [handleConnected]);

  useEffect(() => {
    if (connection) {
      subscribe(connection, subscriptions);
      connection.onclose(() => unsubscribe(connection, subscriptions));
    }
  }, [connection, subscriptions]);
};

export default useSignalR;
