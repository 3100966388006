import { createReducer, createAction } from "@reduxjs/toolkit";
import { GridColDefConfig, InboxGridConfiguration } from "../inboxStructure/types";
import { MessageActionsEnum } from "./actions";
import { MessageLoadingState, Message, IMessagesState, FilterConfigurationLoadingState } from "./types";

const initialState: IMessagesState = {
  inboxGridConfig: { columnDefinitions: [] },
  inboxGridConfigError: false,
  inboxGridConfigLoading: false,
  messages: [],
  loading: { markAsImportantUpdating: false, filterConfiguration: FilterConfigurationLoadingState.None },
};

interface IMessageMark {
  id: string;
  isImportant: boolean;
}

export type MessagesPayload =
  | Partial<IMessagesState>
  | Message
  | IMessageMark
  | MessageLoadingState
  | string
  | undefined;

const updateStateAction = createAction<Partial<IMessagesState>>(MessageActionsEnum.UpdateState);
const markAsImportantAction = createAction<IMessageMark>(MessageActionsEnum.MarkAsImportant);
const messagesLoadedAction = createAction<IMessagesState | undefined>(MessageActionsEnum.MessagesLoaded);
const messagesPreLoadedAction = createAction<IMessagesState | undefined>(MessageActionsEnum.MessagesPreLoaded);
const messageDetailsLoadedAction = createAction<Message>(MessageActionsEnum.MessageDetailsLoaded);
const markAsReadAction = createAction<string>(MessageActionsEnum.MarkAsRead);
const updateLoadingAction = createAction<MessageLoadingState>(MessageActionsEnum.UpdateLoading);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateStateAction, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addCase(markAsImportantAction, (state, action) => {
      const { id, isImportant } = action.payload;
      const message = state.messages.find((msg) => msg.id === id);
      if (message !== undefined) {
        message.isImportant = isImportant;
      }
      if (state.currentMessage && state.currentMessage.id === id) {
        state.currentMessage.isImportant = isImportant;
      }
      return state;
    })
    .addCase(messagesLoadedAction, (state, action) => {
      if (action.payload) {
        state.messages = action.payload.messages;
      }
      return state;
    })
    .addCase(messagesPreLoadedAction, (state, action) => {
      if (action.payload) {
        state.messages = state.messages.concat(action.payload.messages);
      }
      return state;
    })
    .addCase(messageDetailsLoadedAction, (state, action) => {
      state.currentMessage = action.payload;
      return state;
    })
    .addCase(markAsReadAction, (state, action) => {
      const messageId = action.payload;
      const message = state.messages.find((msg) => msg.id === messageId);
      if (message !== undefined) {
        message.isRead = true;
      }
      if (state.currentMessage?.id === messageId) {
        state.currentMessage.isRead = true;
      }
      return state;
    })
    .addCase(updateLoadingAction, (state, action) => {
      state.loading = action.payload;
      return state;
    })
    .addCase(createAction<InboxGridConfiguration>(MessageActionsEnum.SaveGridSettings), (state, action) => {
      state.inboxGridConfig = action.payload;
      return state;
    })
    .addCase(createAction(MessageActionsEnum.CancelGridSettings), (state) => {
      state.inboxGridConfigError = false;
      return state;
    })
    .addCase(createAction<boolean>(MessageActionsEnum.SaveGridSettingsError), (state, action) => {
      state.inboxGridConfigError = action.payload;
      return state;
    })
    .addCase(createAction<boolean>(MessageActionsEnum.SaveGridSettingsLoading), (state, action) => {
      state.inboxGridConfigLoading = action.payload;
      return state;
    })
    .addCase(createAction<GridColDefConfig[]>(MessageActionsEnum.UpdateInboxColumnDef), (state, action) => {
      state.inboxGridConfig.columnDefinitions = action.payload;
      return state;
    })
    ;
});
