import TimeIcon from "@mui/icons-material/AccessTimeRounded";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { useSelector } from "react-redux";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { convertISODateShort } from "../../../../../shared/utilities/dateUtils";
import { ChangeRequestDetails } from "../../../../api/types/changeRequestsTypes";
import { identityUserIdSelector } from "../../../../store/state/user/selectors";
import ConfirmChangeRequestCancellationDialog from "./ConfirmChangeRequestCancellationDialog";

interface Props {
  open: boolean;
  onClose: () => void;
  investorId: string;
  changeRequestDetails: ChangeRequestDetails;
  onChangeRequestCanceled: () => void;
}

const PendingChangeRequestDialog = ({
  open,
  onClose,
  investorId,
  changeRequestDetails,
  onChangeRequestCanceled,
  children,
}: PropsWithChildren<Props>) => {
  const userId = useSelector(identityUserIdSelector);
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);

  const handleChangeRequestCanceled = () => {
    setShowCancelConfirmationDialog(false);
    onChangeRequestCanceled();
  };

  const isRequestedByCurrentUser = changeRequestDetails.requestedByUserId === userId;

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>Request (#{changeRequestDetails.referenceCode})</DialogTitle>
        <DialogCloseButton onClick={onClose} />
        <Divider />
        <DialogContent>
          <Stack spacing={2} width="100%" minHeight="35vh">
            <Alert
              severity="warning"
              iconMapping={{ warning: <TimeIcon fontSize="inherit" /> }}
              sx={{ position: "relative" }}
            >
              <AlertTitle>Pending Changes (#{changeRequestDetails.referenceCode})</AlertTitle>
              {isRequestedByCurrentUser && (
                <Button
                  variant="text"
                  color="inherit"
                  sx={{ position: "absolute", right: 12, top: 12, py: 0 }}
                  onClick={() => setShowCancelConfirmationDialog(true)}
                >
                  Cancel Request
                </Button>
              )}
              <Typography>
                Change request submitted by <strong>{changeRequestDetails.requestedByUserName}</strong> on{" "}
                <strong>{convertISODateShort(changeRequestDetails.requestedAt)}</strong> is waiting for approval.
              </Typography>
            </Alert>
            {children}
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ py: 2, px: 3 }}>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmChangeRequestCancellationDialog
        investorId={investorId}
        workflowId={changeRequestDetails.workflowId}
        referenceCode={changeRequestDetails.referenceCode}
        open={showCancelConfirmationDialog}
        onClose={() => setShowCancelConfirmationDialog(false)}
        onChangeRequestCanceled={handleChangeRequestCanceled}
      />
    </>
  );
};

export default PendingChangeRequestDialog;
