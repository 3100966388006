import {
  DefaultHttpClient,
  HttpRequest,
  HttpResponse,
  HubConnection,
  HubConnectionBuilder,
  ILogger,
  LogLevel,
  MessageHeaders,
} from "@microsoft/signalr";
import memoize from "p-memoize";
import { getErrorMessage } from "../api/axiosHelper";
import { checkAuthentication, userAuthenticated } from "../auth/identity";
import { logError } from "../logging";

const unauthorizedConnectionError = () => new Error("UnauthorizedConnection");

const doConnect = async (url: string, customHeaders: MessageHeaders): Promise<HubConnection | undefined> => {
  const connection = new HubConnectionBuilder()
    .withUrl(url, {
      httpClient: new SingalRHttpClient(customHeaders),
      accessTokenFactory: async () => {
        if (!(await userAuthenticated())) {
          throw unauthorizedConnectionError();
        }
        return "";
      },
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect([10_000, 30_000, 60_000, 120_000, 300_000])
    .build();

  connection.onreconnecting(async () => {
    await checkAuthentication();
  });

  try {
    if (!(await userAuthenticated())) {
      return undefined;
    }

    await connection.start();
    return connection;
  } catch (error) {
    if (getErrorMessage(error) !== "UnauthorizedConnection") {
      logError(error, "[SignalR]");
    }
    return undefined;
  }
};

const logger: ILogger = {
  log: (logLevel: LogLevel, message: string) => {
    if (logLevel >= LogLevel.Error) {
      logError(message, "[SingalR]");
    }
  },
};

class SingalRHttpClient extends DefaultHttpClient {
  private customHeaders: MessageHeaders;

  constructor(customHeaders: MessageHeaders) {
    super(logger);
    this.customHeaders = customHeaders;
  }

  public override send(req: HttpRequest): Promise<HttpResponse> {
    req.headers = { ...req.headers, ...this.customHeaders };
    return super.send(req);
  }
}

export const connect = memoize(doConnect);
