import { Checkbox, FormControlLabel } from "@mui/material";
import { TreeNode } from "./treeModel";

interface Props {
  node: TreeNode;
  onChange: (id: string, checked: boolean) => void;
}

const CheckableTreeLabel = ({ node, onChange }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(node.id, e.target.checked);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <FormControlLabel
      label={node.label}
      control={
        <Checkbox
          checked={node.selected === true}
          indeterminate={node.selected === null}
          onChange={handleChange}
          onClick={handleClick}
        />
      }
    />
  );
};

export default CheckableTreeLabel;
