export interface GridColDefConfig {
  field: string;
  width: number;
}

export interface InboxStructureState {
  tree: InboxStructureTree;
  loading: InboxStructureLoadingEnum;
}

export type InboxStructureTree = Record<string, InboxStructureTreeNode>;

export interface InboxGridConfiguration {
  columnDefinitions: GridColDefConfig[];
}

export interface InboxCategoryTags {
  id: string;
  iconCode: string;
  name: string;
}

export type InboxStructureTreeNode = IItem | IItem[] | string[] | InboxCategoryTags[];

export interface IItem {
  caption: string;
  unread: number;
  total: number;
  childTags: IItem[] | undefined;
}

export enum InboxStructureLoadingEnum {
  None = 0,
  Loaded = 1,
  Error = 2,
}
