import { Stack } from "@mui/material";
import CashFlowsTable from "./CashFlowsTable";
import CashFlowsPageHeader from "./CashFlowsPageHeader";
import CashFlowsView from "./CashFlowsView";
import { CapCallOrDistribution } from "../../../store/state/dashboard/types";

const CashFlowsPage = () => {
  const CapCallsDisplayData = (capCalls: CapCallOrDistribution[]) => (
    <CashFlowsTable cashFlowCapCalls={capCalls} showInvestorAndFund showAll />
  );

  return (
    <Stack spacing={2} sx={{ py: 2, px: 5 }}>
      <CashFlowsPageHeader />
      <CashFlowsView renderItemsElement={CapCallsDisplayData} />
    </Stack>
  );
};

export default CashFlowsPage;
