import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { Divider, Menu } from "@mui/material";
import { useSelector } from "react-redux";
import { useClientContext } from "../../contexts/ClientContext";
import { impersonationSelector } from "../../store/state/user/selectors";
import UserMenuItem from "./UserMenuItem";
import { UserComponent } from "./user/UserComponent";

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export const UserMenu = (props: Props) => {
  const { anchorEl, onClose } = props;
  const { isFeatureEnabled } = useClientContext();
  const isImpersonation = useSelector(impersonationSelector);

  const getInvestorSettingsRoute = () => {
    if (isFeatureEnabled("AccountSettingsViewer")) {
      return "settings/lp/account";
    }
    if (isFeatureEnabled("BankAccountsDetailsViewer")) {
      return "settings/lp/bank";
    }
    if (isFeatureEnabled("Membership")) {
      return "settings/lp/members";
    }
    return undefined;
  };

  const investorSettingsRoute = getInvestorSettingsRoute();

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      onClick={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: 40, vertical: 50 }}
      slotProps={{ paper: { sx: { width: "18rem" } } }}
    >
      <UserComponent sx={{ mt: 1, mb: 2, mx: 2 }} />
      <Divider sx={{ m: 1 }} />
      <UserMenuItem text="My Profile" page="settings/lp/profile" Icon={PersonIcon} />
      {investorSettingsRoute && (
        <UserMenuItem text="Investor Settings" page={investorSettingsRoute} Icon={SettingsIcon} />
      )}
      <Divider sx={{ m: 1 }} />
      <UserMenuItem text="Log out" page="logout" Icon={LogoutIcon} color="primary" disabled={isImpersonation} />
    </Menu>
  );
};

export default UserMenu;
