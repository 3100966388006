import { createReducer, createAction } from "@reduxjs/toolkit";
import { InboxStructureActionsEnum } from "./actions";
import { InboxStructureLoadingEnum, InboxStructureState, InboxStructureTree } from "./types";

const initialState: InboxStructureState = {
  tree: {},
  loading: InboxStructureLoadingEnum.None,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createAction<InboxStructureTree>(InboxStructureActionsEnum.Loaded), (state, action) => {
      state.tree = action.payload;
      return state;
    })
    .addCase(createAction<InboxStructureLoadingEnum>(InboxStructureActionsEnum.UpdateLoading), (state, action) => {
      state.loading = action.payload;
      return state;
    });
});
