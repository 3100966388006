import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Message, MessageType } from "../../../store/state/messages/types";

interface Props {
  message: Message;
}

export const MessageTypeComponent = (props: Props) => {
  const { message } = props;

  if (message.type === MessageType.Document) {
    return <DescriptionOutlinedIcon color="secondary" />;
  } else {
    return <MailOutlineIcon color="secondary" />;
  }
};

export default MessageTypeComponent;
