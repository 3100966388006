import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./state/dashboard/dashboardSlice";
import { reducer as inboxStructureReducer } from "./state/inboxStructure/reducer";
import { reducer as messagesReducer } from "./state/messages/reducer";
import { reducer as userReducer } from "./state/user/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  messages: messagesReducer,
  inboxStructure: inboxStructureReducer,
  dashboard: dashboardReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
