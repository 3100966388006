import { RootState } from "../../store";

export const selectDashboardLoading = (state: RootState) => state.dashboard.balanceLoading;

export const selectDashboardError = (state: RootState) => state.dashboard.balanceError;

export const selectDashboardBalance = (state: RootState) => state.dashboard.balanceData;

export const selectDashboardBalanceInitialData = (state: RootState) => state.dashboard.balanceInitialData;

export const selectDashboardBalanceFilters = (state: RootState) => state.dashboard.balanceFilters;

export const selectDashboardBalanceFiltersApplied = (state: RootState) => {
  const filters = state.dashboard.balanceFilters;
  return filters.investors.length || filters.funds.length || filters.reportingDateIso !== filters.initialDateIso;
};

export const selectCurrentBalanceClient = (state: RootState) => state.dashboard.balanceClient;

//cash flow
export const selectCashFlowData = (state: RootState) => state.dashboard.cashFlowData;

export const selectCashFlowRequestData = (state: RootState) => state.dashboard.cashFlowRequestData;

export const selectCashFlowError = (state: RootState) => state.dashboard.cashFlowError;

export const selectCashFlowLoading = (state: RootState) => state.dashboard.cashFlowLoading;

export const selectCashFlowFiltersApplied = (state: RootState) => {
  const filters = state.dashboard.cashFlowRequestData;
  return !!(
    filters &&
    (filters.investors?.length ||
      filters.funds?.length ||
      filters.from ||
      filters.to ||
      filters.paymentStatuses?.length)
  );
};
