import { Button, Popover, Stack, Typography } from "@mui/material";
import { useLocalization } from "../../../../hooks/useLocalization";

const CashFlowDetailsInfoPopOver = ({ anchorEl, onClose }: { anchorEl: HTMLElement | null; onClose: () => void }) => {
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  return (
    <Popover
      PaperProps={{
        sx: { p: 2 },
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={anchorEl !== null}
    >
      <Stack spacing={2}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          {cashFlowsLocale.detailsInfoPopOver.title}
        </Typography>
        <Typography color="textSecondary">{cashFlowsLocale.detailsInfoPopOver.text}</Typography>
        <Button sx={{ alignSelf: "flex-end" }} variant="contained" color="primary" onClick={onClose}>
          {cashFlowsLocale.detailsInfoPopOver.ok}
        </Button>
      </Stack>
    </Popover>
  );
};

export default CashFlowDetailsInfoPopOver;
