import { SvgIconComponent } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  title: string;
  Icon: SvgIconComponent;
  pageName: string;
  currentPage?: string;
}

const MenuPageItem = ({ title, Icon, pageName, currentPage }: Props) => (
  <MenuItem
    component={RouterLink}
    to={pageName}
    selected={currentPage === pageName}
    sx={(theme) => ({
      borderRadius: theme.spacing(0.5),
      py: theme.spacing(1),
      color: theme.palette.text.secondary,
    })}
  >
    <ListItemIcon>
      <Icon color={currentPage === pageName ? "primary" : "secondary"} />
    </ListItemIcon>
    <ListItemText>
      <Typography>{title}</Typography>
    </ListItemText>
  </MenuItem>
);

export default MenuPageItem;
