import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MonitorIcon from "@mui/icons-material/Monitor";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ImpersonationContact, ImpersonationInvestor } from "../../api/types/impersonationTypes";

const ImpersonationInvestorList = (props: {
  contactEmail: string;
  investors: ImpersonationInvestor[];
  filterText: string;
  handleImpersonationView: (email: string) => void;
}) => {
  const { contactEmail, investors, handleImpersonationView, filterText } = props;
  const theme = useTheme();
  const filterTextVal = filterText.trim().toLowerCase();

  const investorTitle = investors.find((investor) =>
    investor.contacts.find((contact) => contact.email === contactEmail)
  )?.title;

  const [expandedInvestor, setExpandedInvestor] = useState<string | false>(investorTitle || false);

  useEffect(() => {
    if (investorTitle) {
      setExpandedInvestor(investorTitle);
    }
  }, [investorTitle]);

  const onContactViewClick = (email: string) => {
    handleImpersonationView(email);
  };

  const investorContact = (
    contact: ImpersonationContact,
    selectedInvestor: boolean,
    investor: ImpersonationInvestor
  ) => {
    const colorSettings = {
      color: `${contact.email === contactEmail ? "#00A866" : theme.palette.text.secondary}`,
    };

    if (
      filterTextVal !== "" &&
      !investor.title.toLowerCase().includes(filterTextVal) &&
      !contact.name.toLowerCase().includes(filterTextVal) &&
      !contact.email.toLowerCase().includes(filterTextVal) &&
      !investor.fundName.toLowerCase().includes(filterTextVal)
    ) {
      return null;
    }

    const isCurrentUser = contact.email === contactEmail && selectedInvestor;

    return (
      <Box
        sx={(theme) => ({
          height: "2.2rem",
          display: "flex",
          alignItems: "center",
          ...colorSettings,
          "&:hover": {
            backgroundColor: "#EDEDED",
            cursor: isCurrentUser ? "arrow" : "pointer",
          },
          "& > *": {
            ...colorSettings,
          },
          paddingLeft: theme.spacing(2),
          marginLeft: theme.spacing(3),
        })}
        key={contact.email}
        onClick={() => {
          if (!isCurrentUser) {
            onContactViewClick(contact.email);
          }
        }}
      >
        <Grid
          container
          sx={{
            "& > .MuiGrid-item": {
              margin: "auto",
            },
          }}
        >
          <Grid item xs={5}>
            <Typography variant="subtitle2">{contact.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color: colorSettings.color || theme.palette.text.secondary,
                lineHeight: "1.5rem",
              })}
            >
              {contact.email}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {contact.email === contactEmail && selectedInvestor && <MonitorIcon sx={{ ...colorSettings }} />}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const investorAccordion = (investor: ImpersonationInvestor) => {
    const selectedInvestor = investor.title === expandedInvestor;

    return (
      <Accordion
        onChange={(_, newEpanded) => setExpandedInvestor(newEpanded ? investor.title : false)}
        expanded={selectedInvestor || filterTextVal !== ""}
        disableGutters
        elevation={0}
        square={true}
        sx={{
          backgroundColor: "#FAFAFA",
          border: "1px solid #E5E6E9",
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
        }}
        key={investor.title}
      >
        <AccordionSummary
          expandIcon={<ChevronRightIcon />}
          sx={(theme) => ({
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: theme.spacing(2),
            },
          })}
        >
          <Typography variant="subtitle2">{investor.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {investor.contacts.map((ct) => investorContact(ct, selectedInvestor, investor))}
        </AccordionDetails>
      </Accordion>
    );
  };

  return <> {investors.map((inv) => investorAccordion(inv))} </>;
};

export default ImpersonationInvestorList;
