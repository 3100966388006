import { Box, Typography } from "@mui/material";
import { TreeItem, TreeItemContentProps, TreeItemProps, useTreeItemState } from "@mui/x-tree-view";
import clsx from "clsx";
import React, { useCallback } from "react";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { IItem } from "../../../../store/state/inboxStructure/types";
import InboxBadge from "../../../common/InboxBadge";
import DynamicIcon from "../DynamicIcon";

interface Props {
  expandedTags: string[];
  inboxStructure: IItem[];
  onItemSelected: (item: string) => void;
  onExpandedTags: (items: string[]) => void;
}

const InvestorTree = ({ expandedTags, inboxStructure, onItemSelected, onExpandedTags }: Props) => {
  const generateNodeId = (caption: string, parentCaption: string) => {
    let param = caption;
    if (parentCaption) {
      param = parentCaption + "/" + caption;
    }
    return param;
  };

  const onExpandChanged = useCallback(
    (expanded: boolean, nodeId: string) => {
      let tags: string[];
      if (expanded) {
        tags = [...expandedTags];
        tags.splice(tags.indexOf(nodeId), 1);
      } else {
        tags = [...expandedTags, nodeId];
      }
      onExpandedTags(tags);
    },
    [expandedTags, onExpandedTags]
  );

  const CustomContent = React.forwardRef((props: TreeItemContentProps, ref) => {
    const { classes, className, label, itemId, icon: iconProp, expansionIcon, displayIcon } = props;

    const { disabled, expanded, selected, focused, handleSelection, preventSelection, handleExpansion } =
      useTreeItemState(itemId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleExpansion(event);
      onExpandChanged(expanded, itemId);
    };

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleSelection(event);
      onItemSelected(itemId);
    };

    return (
      <Box
        ref={ref as React.Ref<HTMLDivElement>}
        sx={(theme) => ({
          borderRadius: "4px",
          p: "0 8px 0 0px !important",
          "&.Mui-selected": {
            ">.MuiTypography-root": {
              ">div": {
                color: theme.palette.primary.main,
              },
            },
          },
        })}
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
      >
        <Box
          sx={{ display: "flex" }}
          color={"text.secondary"}
          onClick={handleExpansionClick}
          className={classes.iconContainer}
        >
          {icon}
        </Box>
        <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
          {label}
        </Typography>
      </Box>
    );
  });

  const CustomTreeItem = (props: TreeItemProps) => <TreeItem ContentComponent={CustomContent} {...props} />;

  const buildInvestors = (parentCaption: string, structure: IItem[] | undefined): JSX.Element[] => {
    let items: JSX.Element[] = [];
    if (structure && structure.length > 0) {
      items = structure.map((item) => {
        const id = generateNodeId(item.caption, parentCaption);
        return (
          <CustomTreeItem
            key={item.caption}
            itemId={id}
            label={
              <Box
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.text.secondary,
                  height: "38px",
                })}
              >
                <Box sx={(theme) => ({ display: "flex", color: theme.palette.success.main })}>
                  <DynamicIcon iconName={item.caption} />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", flex: 1, position: "relative" }}>
                  <Box
                    sx={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center" }}
                  >
                    <TypographyTooltipEllipsis
                      text={item.caption}
                      typographyProps={{ variant: "subtitle2", pl: 1, mr: 4 }}
                    />
                  </Box>
                </Box>
                <InboxBadge value={item.unread || 0} mr={1.2} />
              </Box>
            }
          >
            {buildInvestors(item.caption, item.childTags)}
          </CustomTreeItem>
        );
      });
    }
    return items;
  };

  return <>{buildInvestors("", inboxStructure)}</>;
};

export default InvestorTree;
