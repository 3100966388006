import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { api } from "../../../../../api/client";
import { useClientContext } from "../../../../../contexts/ClientContext";
import { workspaceInvestorSelector } from "../../../../../store/state/user/selectors";
import LpSettingsWrapper from "../../LpSettingsWrapper";
import ChangesWarningAlert from "../../common/ChangesWarningAlert";
import { useBankSettingsNavigation } from "../navigation";
import BankAccountSelector from "./BankAccountSelector";

const updateFundAssignments = withErrorHandling(api.bankAccounts.assignBankAccountsToFundInvestors);

const ChangeFundAssignmentsPage = () => {
  const { clientCode } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const workspaceInvestor = useSelector(workspaceInvestorSelector);

  const { changeFundAssignmentsPageNavigationState: navigationState, navigateToBankAccountsPage } =
    useBankSettingsNavigation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(navigationState?.bankAccountId || "");

  const handleBackButtonClick = () => {
    navigateToBankAccountsPage();
  };

  const handleSubmit = async () => {
    if (!navigationState) {
      return;
    }

    setIsSubmitting(true);
    const [, error] = await updateFundAssignments({
      assignments: [{ fundInvestorId: navigationState.fundInvestorId, bankAccountId: selectedBankAccountId }],
    });
    setIsSubmitting(false);

    if (error) {
      logError(error, "[ChangeFundAssignmentsPage] handleSubmit");
      sendNotificationError("Failed to update fund assignments");
      return;
    }

    sendNotification("The request has been submitted");
    setTimeout(() => navigateToBankAccountsPage(), 100);
  };

  if (!navigationState) {
    return <Navigate to={`/${clientCode}/settings/lp/bank`} />;
  }

  const handleSelectedBankAccountChange = (bankAccountId: string) => {
    setSelectedBankAccountId(bankAccountId);
  };

  return (
    <LpSettingsWrapper
      title="Change Assignments"
      subtitle={workspaceInvestor?.investorTitle}
      onBackButtonClick={handleBackButtonClick}
      Footer={
        <Box py={2} px={3} display="flex" justifyContent="space-between">
          <HorizontalFill />
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit}
            disabled={navigationState?.bankAccountId === selectedBankAccountId}
          >
            Send Request
          </LoadingButton>
        </Box>
      }
    >
      <Box>
        <ChangesWarningAlert />
        <Box pt={3} pb={2}>
          <Typography variant="subtitle1">{navigationState.fundName}</Typography>
        </Box>
        <BankAccountSelector
          bankAccounts={navigationState.bankAccounts}
          selectedBankAccountId={selectedBankAccountId}
          onSelectedBankAccountChange={handleSelectedBankAccountChange}
        />
      </Box>
    </LpSettingsWrapper>
  );
};

export default ChangeFundAssignmentsPage;
