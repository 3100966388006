import { useState } from "react";
import { Avatar, Button, CardHeader, Skeleton, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/PersonOutline";
import EffectiveReportingDate from "./EffectiveReportingDate";
import { useLocalization } from "../../../../hooks/useLocalization";
import BalanceMobilePage from "./BalanceMobilePage";
import { BalanceCardData } from "./types";
import {
  communicationGroupNameField,
  dashboardBaseBalanceFields,
  investorNameField,
} from "../../../../../shared/components/balances/balanceFieldset";
import HorizontalSwiper from "../../../../../shared/components/HorizontalSwiper";
import {
  selectDashboardBalance,
  selectDashboardError,
  selectDashboardLoading,
} from "../../../../store/state/dashboard/selectors";
import { useSelector } from "react-redux";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import BalanceCard from "./BalanceCard";
import { logError } from "../../../../../shared/logging";
import NoDataResult from "../../common/NoDataResult";
import { cardHeaderPropsDefault } from "../../common/helpers";

const BalanceWidgetSkeleton = () => {
  return (
    <Stack>
      <CardHeader
        sx={{ ...cardHeaderPropsDefault, pb: 2 }}
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        title={<Skeleton animation="wave" height={20} width="40%" />}
        subheader={<Skeleton animation="wave" height={14} width="80%" />}
      />
      <Skeleton sx={{ height: 260, mx: 2, borderRadius: 1 }} animation="wave" variant="rectangular" />
    </Stack>
  );
};

interface BalanceMobileWidgetProps {
  reportingDate?: Date;
}

const BalanceMobileWidget = ({ reportingDate }: BalanceMobileWidgetProps) => {
  const isBalanceLoading = useSelector(selectDashboardLoading);
  const balanceDataResponse = useSelector(selectDashboardBalance);
  const balanceErrorMsg = useSelector(selectDashboardError);
  const balanceLocale = useLocalization().dashboard.balance;
  const [openBalancePage, setOpenBalancePage] = useState(false);

  if (balanceErrorMsg) {
    logError(balanceErrorMsg, "BalanceWidget");
  }

  const onBalancePageClose = () => {
    setOpenBalancePage(false);
  };

  const replaceIfEmpty = (value: string | undefined) => {
    return value || "-";
  };

  let balanceCardsData: BalanceCardData[] | undefined = undefined;
  if (balanceDataResponse) {
    balanceCardsData = (balanceDataResponse.balanceData ? balanceDataResponse.balanceData.data : []).map((data) => {
      const fundName = replaceIfEmpty(data[communicationGroupNameField.guid]);
      const fundReportingDate = balanceDataResponse.fundReportingDates[fundName];
      return {
        investorName: replaceIfEmpty(data[investorNameField.guid]),
        fundName: fundName,
        reportingDate: fundReportingDate && new Date(fundReportingDate),
        fields: dashboardBaseBalanceFields.map((field) => {
          const config = field.config;
          const value = data[field.guid];
          const valueFormatted = replaceIfEmpty(config?.format ? config.format(value) : value);
          return [config?.title || field.guid, valueFormatted];
        }),
      };
    });
  }

  const showData = !balanceErrorMsg && balanceCardsData !== undefined;
  const isLoading =
    (isBalanceLoading || (balanceCardsData === undefined && reportingDate !== undefined)) && !balanceErrorMsg;

  const BalanceDisplayData = () => {
    if ((showData && !isBalanceLoading && balanceCardsData?.length === 0) || reportingDate === undefined) {
      return <NoDataResult text={balanceLocale.no_data} />;
    }

    if (showData && balanceCardsData && !isBalanceLoading) {
      return (
        <HorizontalSwiper
          items={balanceCardsData.map((bc, i) => (
            <BalanceCard
              key={"bc" + i}
              investorName={bc.investorName}
              title={bc.fundName}
              date={bc.reportingDate}
              fields={bc.fields}
            />
          ))}
        />
      );
    }

    return null;
  };

  return (
    <Stack>
      {isLoading ? (
        <BalanceWidgetSkeleton />
      ) : (
        <>
          <CardHeader
            sx={cardHeaderPropsDefault}
            avatar={
              <Avatar sx={(theme) => ({ backgroundColor: theme.palette.primary.main })} aria-label="recipe">
                <PersonIcon sx={(theme) => ({ color: theme.palette.primary.contrastText })} />
              </Avatar>
            }
            action={
              <Button variant="text" disabled={!showData} onClick={() => setOpenBalancePage(true)}>
                {balanceLocale.actions.view}
              </Button>
            }
            title={
              <Typography variant="h6" color="textPrimary">
                {balanceLocale.widget_name}
              </Typography>
            }
            subheader={<EffectiveReportingDate reportingDate={reportingDate} dateColor="textSecondary" />}
          />
          <BalanceDisplayData />
        </>
      )}

      {!isBalanceLoading && balanceErrorMsg && <DataLoadingFailed bgColor="none" sx={{ px: 2 }} />}
      {showData && openBalancePage && balanceCardsData && (
        <BalanceMobilePage reportingDate={reportingDate} cardsData={balanceCardsData} onClose={onBalancePageClose} />
      )}
    </Stack>
  );
};

export default BalanceMobileWidget;
