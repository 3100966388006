import { Stack, Typography, TypographyProps } from "@mui/material";
import BadgeDetached from "./BadgeDetached";

interface Props {
  title: string;
  items: string[];
  maxVisibleItems: number;
  expanded?: boolean;
  collapsedText?: string;
  explicitTotalCount?: number;
  titleTypographyProps?: TypographyProps;
  itemTypographyProps?: TypographyProps;
}

const LimitedListWithBadge = ({
  title,
  items,
  maxVisibleItems,
  expanded,
  collapsedText,
  explicitTotalCount,
  titleTypographyProps,
  itemTypographyProps,
}: Props) => {
  return (
    <Stack spacing={0.5} alignItems="flex-start">
      <Stack direction="row" spacing={0.5}>
        <Typography variant="subtitle2" {...titleTypographyProps}>
          {title}
        </Typography>
        <BadgeDetached badgeContent={explicitTotalCount ?? items.length} color="secondary" />
      </Stack>
      {items.slice(0, maxVisibleItems).map((item) => (
        <Typography key={item} {...itemTypographyProps}>
          {item}
        </Typography>
      ))}
      {items.length > maxVisibleItems && (
        <>
          {expanded &&
            items.slice(maxVisibleItems).map((item) => (
              <Typography key={item} {...itemTypographyProps}>
                {item}
              </Typography>
            ))}
          {!expanded && collapsedText && (
            <Typography key="_more" {...itemTypographyProps}>
              {collapsedText}
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
};

export default LimitedListWithBadge;
