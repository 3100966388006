import { IconButton, Stack, Typography } from "@mui/material";
import { useLocalization } from "../../../../hooks/useLocalization";
import { CapCallOrDistribution } from "../../../../store/state/dashboard/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CashFlowTransactionDetails from "./CashFlowTransactionDetails";
import CashFlowDetailsInfoPopOver from "./CashFlowDetailsInfoPopOver";
import { useState } from "react";

interface CashFlowDetailsProps {
  capCall: CapCallOrDistribution;
}

const CashFlowDetails = ({ capCall }: CashFlowDetailsProps) => {
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  const [infoPopupEl, setInfoPopupEl] = useState<HTMLButtonElement | null>(null);
  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="subtitle1" color="textPrimary">
          {cashFlowsLocale.details_title}
        </Typography>
        <IconButton onClick={(e) => setInfoPopupEl(e.currentTarget)}>
          <InfoOutlinedIcon fontSize="medium" />
        </IconButton>
        <CashFlowDetailsInfoPopOver anchorEl={infoPopupEl} onClose={() => setInfoPopupEl(null)} />
      </Stack>
      {capCall.transactions.map((transaction) => (
        <CashFlowTransactionDetails key={transaction.id} transaction={transaction} />
      ))}
    </Stack>
  );
};

export default CashFlowDetails;
