import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { IItem, InboxStructureTree } from "../../../../store/state/inboxStructure/types";
import InvestorTree from "./InvestorTree";

interface Props {
  selectedItem: string;
  inboxStructure: InboxStructureTree;
  onItemSelected: (item: string) => void;
}

const InvestorMenu = ({ selectedItem, inboxStructure, onItemSelected }: Props) => {
  const dispatch = useDispatch();
  const [expandedTags, setExpandedTags] = useState<string[]>([]);

  useEffect(() => {
    const getDefaultExpandedNode = () => {
      const tags = selectedItem.split("/");
      if (tags.length > 1) {
        return [defined(tags[0])];
      }
      return [];
    };

    setExpandedTags(getDefaultExpandedNode());
  }, [dispatch, selectedItem]);

  return (
    <>
      <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary} py={1}>
        Investors
      </Typography>
      <SimpleTreeView
        aria-label="icon expansion"
        selectedItems={selectedItem}
        expandedItems={expandedTags}
        slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
      >
        <InvestorTree
          expandedTags={expandedTags}
          inboxStructure={inboxStructure["systemTags"] as IItem[]}
          onItemSelected={onItemSelected}
          onExpandedTags={setExpandedTags}
        />
      </SimpleTreeView>
    </>
  );
};

export default InvestorMenu;
