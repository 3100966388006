import { useSelector } from "react-redux";
import { workspaceInvestorSelector } from "../../../../store/state/user/selectors";
import LpSettingsWrapper from "../LpSettingsWrapper";
import MembersComponent from "./list/MembersComponent";

export const MembersSettings = () => {
  const workspaceInvestor = useSelector(workspaceInvestorSelector);

  return (
    <LpSettingsWrapper
      title="Members"
      subtitle={workspaceInvestor?.investorTitle}
      containerSx={{ overflowY: "hidden" }}
    >
      <MembersComponent />
    </LpSettingsWrapper>
  );
};

export default MembersSettings;
