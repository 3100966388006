import deepEqual from "fast-deep-equal";
import { requiredValidator } from "../../../../../shared/utilities/validators";
import { InvestorDetails, UpdateInvestorDetailsRequest } from "../../../../api/types/investorDetailsTypes";

export interface AccountSettingsFormValues {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

interface AccountSettingsFormErrors {
  name?: string;
  addressLine1?: string;
  city?: string;
  country?: string;
}

export interface AccountSettingsFormState {
  initialValues: AccountSettingsFormValues;
  values: AccountSettingsFormValues;
  errors: AccountSettingsFormErrors;
  isDirty: boolean;
}

type StateAction = (state: AccountSettingsFormState) => AccountSettingsFormState;

export const getInitialState = (): AccountSettingsFormState => ({
  initialValues: {
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  },
  values: {
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  },
  errors: {},
  isDirty: false,
});

// Selectors

const sanitizeFormValues = (values: AccountSettingsFormValues): AccountSettingsFormValues => ({
  name: values.name.trim(),
  addressLine1: values.addressLine1.trim(),
  addressLine2: values.addressLine2.trim(),
  city: values.city.trim(),
  state: values.state.trim(),
  zipCode: values.zipCode.trim(),
  country: values.country.trim(),
});

export const mapFormStateToUpdateRequest = (values: AccountSettingsFormValues): UpdateInvestorDetailsRequest =>
  sanitizeFormValues(values);

export const validateForm = (state: AccountSettingsFormState): AccountSettingsFormErrors => {
  const nameResult = requiredValidator(state.values.name);
  const addressLine1Result = requiredValidator(state.values.addressLine1);
  const cityResult = requiredValidator(state.values.city);
  const countryResult = requiredValidator(state.values.country);

  return {
    name: nameResult.error,
    addressLine1: addressLine1Result.error,
    city: cityResult.error,
    country: countryResult.error,
  };
};

// Actions

export const loadDataAction =
  (investor: InvestorDetails): StateAction =>
  (state) => ({
    ...state,
    initialValues: sanitizeFormValues({
      name: investor.name || "",
      addressLine1: investor.addressLine1 || "",
      addressLine2: investor.addressLine2 || "",
      city: investor.city || "",
      state: investor.state || "",
      zipCode: investor.zipCode || "",
      country: investor.country || "",
    }),
    values: sanitizeFormValues({
      name: investor.name || "",
      addressLine1: investor.addressLine1 || "",
      addressLine2: investor.addressLine2 || "",
      city: investor.city || "",
      state: investor.state || "",
      zipCode: investor.zipCode || "",
      country: investor.country || "",
    }),
  });

export const updateFieldAction =
  (field: keyof AccountSettingsFormValues, value: string): StateAction =>
  (state) => ({
    ...state,
    values: { ...state.values, [field]: value },
    errors: { ...state.errors, [field]: undefined },
    isDirty: !deepEqual(state.initialValues, sanitizeFormValues({ ...state.values, [field]: value })),
  });

export const setErrorsAction =
  (errors: AccountSettingsFormErrors): StateAction =>
  (state) => ({
    ...state,
    errors,
  });

export const resetAction = (): StateAction => (state) => ({
  initialValues: { ...state.initialValues },
  values: { ...state.initialValues },
  errors: {},
  isDirty: false,
});
