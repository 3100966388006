import CircleIcon from "@mui/icons-material/Circle";

enum MessagePublishStatus {
  Live = "Live",
  Preview = "Preview",
}

const MessagePublishStatusIcon = (props: { publishingStatus: string }) => {
  const { publishingStatus } = props;

  return (
    <CircleIcon
      sx={(theme) => ({
        color: `${
          publishingStatus === MessagePublishStatus.Live ? theme.palette.success.main : theme.palette.info.main
        }`,
        mx: theme.spacing(),
        [theme.breakpoints.down("sm")]: {
          mx: 0,
        },
        fontSize: "0.5rem",
      })}
    />
  );
};

export default MessagePublishStatusIcon;
