import { GridColDefConfig, InboxGridConfiguration } from "../inboxStructure/types";
import { MessageLoadingState, Message, IMessagesState } from "./types";

export enum MessageActionsEnum {
  UpdateState = "messages/update-state",
  MessagesLoaded = "messages/messages-loaded",
  MessagesPreLoaded = "messages/messages-preloaded",
  MessageDetailsLoaded = "messages/message-details-loaded",
  MarkAsRead = "messages/mark-as-read",
  MarkAsImportant = "messages/mark-as-important",
  UpdateLoading = "messages/update-loading",
  SaveGridSettings = "messages/save-grid-settings",
  CancelGridSettings = "messages/cancel-grid-settings",
  SaveGridSettingsError = "messages/save-grid-settings-error",
  SaveGridSettingsLoading = "messages/save-grid-settings-loading",
  UpdateInboxColumnDef = "messages/update-inbox-column-def",
  UpdsteInboxGridConfigEditing = "messages/update-inbox-grid-config-editing",
}

export const MessageActions = {
  UpdateState: (state: Partial<IMessagesState>) => ({ type: MessageActionsEnum.UpdateState, payload: state }),
  MessagesLoaded: (state: Partial<IMessagesState>) => ({ type: MessageActionsEnum.MessagesLoaded, payload: state }),
  MessagesPreLoaded: (state: Partial<IMessagesState>) => ({
    type: MessageActionsEnum.MessagesPreLoaded,
    payload: state,
  }),
  MessageDetailsLoaded: (message: Message) => ({ type: MessageActionsEnum.MessageDetailsLoaded, payload: message }),
  MarkAsRead: (id: string) => ({ type: MessageActionsEnum.MarkAsRead, payload: id }),
  MarkAsImportant: (id: string, isImportant: boolean) => ({
    type: MessageActionsEnum.MarkAsImportant,
    payload: { id, isImportant },
  }),
  UpdateLoading: (loading: MessageLoadingState) => ({ type: MessageActionsEnum.UpdateLoading, payload: loading }),
  SaveGridConfiguration: (configuration: InboxGridConfiguration) => ({
    type: MessageActionsEnum.SaveGridSettings,
    payload: configuration,
  }),
  CancelGridConfiguration: () => ({ type: MessageActionsEnum.CancelGridSettings }),
  UpdateInboxColumnDef: (columnDefinitions: GridColDefConfig[]) => ({
    type: MessageActionsEnum.UpdateInboxColumnDef,
    payload: columnDefinitions,
  }),
  UpdateInboxEditing: (isEditing: boolean) => ({
    type: MessageActionsEnum.UpdsteInboxGridConfigEditing,
    payload: isEditing,
  }),
};
