import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { LoadingButton } from "@mui/lab";
import { GridRowId, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { markMessageAsRead } from "../../../../../hooks/inboxState/inboxState.api";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { downloadAll, downloadAllAttachments } from "../../../../../services/attachments";
import { messagesSelector } from "../../../../../store/state/messages/selectors";
import { AppDispatch } from "../../../../../store/store";

interface Props {
  compact?: boolean;
  selection: GridRowSelectionModel;
}

export const DownloadAllAction = (props: Props) => {
  const { compact, selection } = props;
  const dispatch: AppDispatch = useDispatch();
  const messages = useSelector(messagesSelector);
  const { sendNotificationError } = useNotificationContext();
  const locale = useLocalization();

  const messageCountWithAttachments = useMemo(() => {
    return messages.filter((msg) => selection.some((id: GridRowId) => id === msg.id && msg.hasAttachments)).length;
  }, [messages, selection]);

  const allowDownloadAllAction = useMemo(() => {
    return selection && selection.length > 0 && messageCountWithAttachments > 0;
  }, [selection, messageCountWithAttachments]);

  const downloadAttachments = useCallback(async () => {
    try {
      if (selection.length === 1) {
        const message = messages.find((msg) => msg.id === selection[0]);
        if (message) {
          await downloadAllAttachments(message);
          if (!message.isRead) {
            await markMessageAsRead(message.id)(dispatch);
          }
          return;
        }
      }

      const messageIds = selection.map((s) => s as string);
      await downloadAll(messageIds);
      await markMessageAsRead(messageIds)(dispatch);
    } catch {
      sendNotificationError(locale.inbox.download_files_error);
    }
  }, [selection, messages, dispatch, sendNotificationError, locale.inbox.download_files_error]);

  return (
    <>
      {allowDownloadAllAction && (
        <LoadingButton
          startIcon={<FileDownloadOutlinedIcon />}
          loadingPosition="start"
          onClick={() => downloadAttachments()}
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            flexDirection: compact === true ? "column" : "inherit",
          })}
        >
          Download
        </LoadingButton>
      )}
    </>
  );
};

export default DownloadAllAction;
