import { Identity, IdentityLoading, UserState } from "./types";

export enum UserActionsEnum {
  RequestLoadInfo = "user/request-load-info",
  LoadInfo = "user/load-info",
  Authenticated = "user/authenticated",
  UpdateLoading = "user/update-loading",
  UpdateIdentity = "user/update-identity",
  UpdateWorkspace = "user/update-workspace",
  Impersonation = "user/impersonation",
  UpdateClientCode = "user/update-client-code",
}

export const UserActions = {
  UpdateState: (info: Partial<UserState>) => ({ type: UserActionsEnum.LoadInfo, payload: info }),
  Authenticated: () => ({ type: UserActionsEnum.Authenticated }),
  UpdateLoading: (payload: IdentityLoading) => ({ type: UserActionsEnum.UpdateLoading, payload }),
  UpdateIdentity: (payload: Identity) => ({ type: UserActionsEnum.UpdateIdentity, payload }),
  UpdateWorkspace: (investorId: string) => ({ type: UserActionsEnum.UpdateWorkspace, payload: investorId }),
  UpdateImpersonation: (payload: boolean) => ({ type: UserActionsEnum.Impersonation, payload }),
  UpdateClientCode: (payload: string) => ({ type: UserActionsEnum.UpdateClientCode, payload }),
};
