import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Container, Divider, IconButton, Paper, Stack, SxProps, Theme, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import LoaderBox from "../../../../shared/components/LoaderBox";
import { useSettingsContext } from "../SettingsContext";

interface Props {
  title: string;
  subtitle?: string;
  onBackButtonClick?: () => void;
  containerSx?: SxProps<Theme>;
  Subheader?: React.ReactNode;
  Footer?: React.ReactNode;
}

const LpSettingsWrapper = ({
  title,
  subtitle,
  onBackButtonClick,
  containerSx,
  Subheader,
  Footer,
  children,
}: PropsWithChildren<Props>) => {
  const { loading } = useSettingsContext();

  return (
    <LoaderBox loading={loading}>
      <Box width="100%" sx={{ overflowY: "auto", ...containerSx }}>
        <Paper elevation={0} sx={{ position: "sticky", top: 0, left: "auto", right: 0, zIndex: 1050 }}>
          <Stack direction="row" alignItems="center" spacing={1} py={2} px={3}>
            {onBackButtonClick && (
              <IconButton onClick={onBackButtonClick} color="secondary">
                <ArrowBackIcon />
              </IconButton>
            )}
            <Stack spacing={0.5}>
              <Typography variant="h6">{title}</Typography>
              {subtitle && <Typography color="text.secondary">{subtitle}</Typography>}
            </Stack>
          </Stack>
          <Divider />
        </Paper>
        {Subheader && (
          <>
            {Subheader}
            <Divider />
          </>
        )}
        <Box py={2.5} height="100%">
          <Container maxWidth="md" disableGutters sx={{ height: "100%" }}>
            {children}
          </Container>
        </Box>
        {Footer && (
          <Paper elevation={0} sx={{ position: "fixed", bottom: 0, left: "auto", right: 0, zIndex: 1050 }}>
            <Divider />
            <Box width="100vw">{Footer}</Box>
          </Paper>
        )}
      </Box>
    </LoaderBox>
  );
};

export default LpSettingsWrapper;
