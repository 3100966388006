import { createContext, PropsWithChildren, useContext } from "react";
import { defined } from "../../../shared/utilities/typeHelper";

interface Props {
  showFilePreview: (fileId: string) => void;
}

const FundraisingDocumentActionsContext = createContext<Props | undefined>(undefined);

export const FundraisingDocumentActionsContextProvider = ({ showFilePreview, children }: PropsWithChildren<Props>) => {
  return (
    <FundraisingDocumentActionsContext.Provider value={{ showFilePreview }}>
      {children}
    </FundraisingDocumentActionsContext.Provider>
  );
};

export const useFundraisingDocumentActionsContext = () =>
  defined(
    useContext(FundraisingDocumentActionsContext),
    "Attempt to use FundraisingDocumentActionsContext without provider"
  );
