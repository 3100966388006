import { Stack, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import CountryFlag from "../../../../../shared/components/CountryFlag";
import RoundedTable from "../../../../../shared/components/RoundedTable";
import { countryCodeAlpha2Lookup } from "../../../../../shared/utilities/countriesProvider";
import { InvestorBankAccountDetails } from "../../../../api/types/bankAccountTypes";

interface Props {
  bankAccount: InvestorBankAccountDetails;
}

const Row = ({ field, children }: PropsWithChildren<{ field: string }>) => (
  <TableRow>
    <TableCell sx={(t) => ({ width: 200, color: t.palette.text.secondary })}>{field}</TableCell>
    <TableCell sx={{ maxWidth: 250 }}>{children}</TableCell>
  </TableRow>
);

const BankAccountDetailsTable = ({ bankAccount }: Props) => {
  const countryCode = countryCodeAlpha2Lookup.get(bankAccount.country);

  return (
    <RoundedTable>
      <TableBody>
        <Row field="Country">
          <Stack direction="row" spacing={1}>
            {countryCode && <CountryFlag countryCodeAlpha2={countryCode ?? ""} />}
            <Typography>{bankAccount.country}</Typography>
          </Stack>
        </Row>
        <Row field="Bank Name">{bankAccount.bankName}</Row>
        {bankAccount.abaNumber && <Row field="ABA Routing Number">{bankAccount.abaNumber}</Row>}
        {bankAccount.swiftCode && <Row field="SWIFT / BIC Code">{bankAccount.swiftCode}</Row>}
        <Row field="Account Name">{bankAccount.accountName}</Row>
        <Row field="Account Number / IBAN">{bankAccount.accountNumber}</Row>
        <Row field="Currency">{bankAccount.currencyCode}</Row>
        {bankAccount.ffcName && <Row field="FFC Name">{bankAccount.ffcName}</Row>}
        {bankAccount.ffcAccountNumber && <Row field="FFC Number">{bankAccount.ffcAccountNumber}</Row>}
      </TableBody>
    </RoundedTable>
  );
};

export default BankAccountDetailsTable;
