import { SvgIcon, SvgIconProps } from "@mui/material";

export const CapCallNoticeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0H2C0.89 0 0 0.89 0 2V18C0 19.11 0.89 20 2 20H9.81C9.28 19.09 9 18.05 9 17C9 16.67 9.03 16.33 9.08 16H2V14H9.81C10.27 13.2 10.91 12.5 11.68 12H2V10H14V11.08C14.33 11.03 14.67 11 15 11C15.33 11 15.67 11.03 16 11.08V6L10 0ZM9 7V1.5L14.5 7H9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8004 17.2L15.8004 19L19.4004 16L15.8004 13L15.8004 14.8L11.0004 14.8L11.0004 17.2L15.8004 17.2Z"
      />
    </SvgIcon>
  );
};

export default CapCallNoticeIcon;
