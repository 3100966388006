import { Box, Tooltip } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  active: boolean;
}

const UnavailableFundTooltip = ({ active, children }: PropsWithChildren<Props>) =>
  active ? (
    <Tooltip title="The fund selection is currently unavailable. You either do not have access to submit changes for this fund or there are pending changes.">
      <Box component="span">{children}</Box>
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default UnavailableFundTooltip;
