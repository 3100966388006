import { Badge, Box, Drawer, Grid, IconButton, Stack, SwipeableDrawer, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import FilterComponent from "./filter/FilterComponent";
import Breakpoint from "../../../../shared/components/Breakpoint";
import AdjustmentsIcon from "../../../../shared/icons/AdjustmentsIcon";
import ClearAllButton from "./ClearAllButton";
import { InboxCriteria, InboxUiState, useInboxStateContext } from "../../../contexts/InboxStateContext";
import { isFilterApplied } from "../../../hooks/inboxState/inboxState.utils";
import InboxConfigControl from "../config/InboxConfigControl";
import SwipePuller from "../../../../shared/components/SwipePuller";
import SearchField from "../../../../shared/components/inputs/SearchField";

const FilterComponentCompact = (props: {
  menuMode: "inline" | "modal";
  onUpdate: (chages: Partial<InboxCriteria>) => void;
  updateUi: (value: Partial<InboxUiState>) => void;
}) => {
  return (
    <>
      <Box pb={1.5} display="flex" alignItems={"center"} justifyContent="space-between" height={"2rem"}>
        <Typography variant="h5">Filters</Typography>
        <ClearAllButton updateCriteria={props.onUpdate} updateUi={props.updateUi} />
      </Box>
      <FilterComponent menuMode={props.menuMode} direction="column" />
    </>
  );
};

export const SearchComponent = () => {
  const [showFilter, setShowFilter] = useState(false);

  const { updateCriteria, updateUi, criteria } = useInboxStateContext();
  const filterApplied = isFilterApplied(criteria);

  const doUpdateCriteria = useCallback(
    (value: Partial<InboxCriteria>) => {
      updateCriteria({ ...value, page: 0 });
    },
    [updateCriteria]
  );

  return (
    <Box p={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Grid container sx={{ maxWidth: "50rem" }}>
        <Grid item flex={1}>
          <SearchField
            initialValue={criteria.query}
            placeholder="Search documents"
            debounceTimeMs={500}
            onSearch={(text) => {
              doUpdateCriteria({ query: text });
              updateUi({ total: undefined });
            }}
            fullWidth
          />
        </Grid>
        <Box
          sx={(theme) => ({
            display: "none",
            [theme.breakpoints.down("lg")]: {
              display: "inherit",
            },
          })}
        >
          <IconButton onClick={() => setShowFilter(true)} color="primary">
            {filterApplied ? (
              <Badge color="primary" variant="dot">
                <AdjustmentsIcon />
              </Badge>
            ) : (
              <AdjustmentsIcon />
            )}
          </IconButton>
          <Breakpoint from="sm" to="lg">
            <Drawer
              anchor="right"
              open={showFilter}
              onClose={() => setShowFilter(false)}
              PaperProps={{
                sx: {
                  p: "1rem",
                  width: "20rem",
                  minWidth: "25vw",
                },
              }}
            >
              <FilterComponentCompact menuMode="inline" onUpdate={doUpdateCriteria} updateUi={updateUi} />
            </Drawer>
          </Breakpoint>
          <Breakpoint to="sm">
            <SwipeableDrawer
              container={undefined}
              anchor="bottom"
              open={showFilter}
              onClose={() => setShowFilter(false)}
              onOpen={() => undefined}
              swipeAreaWidth={20}
              disableSwipeToOpen={true}
              PaperProps={{ sx: { p: 2 } }}
            >
              <SwipePuller />
              <FilterComponentCompact menuMode="modal" onUpdate={doUpdateCriteria} updateUi={updateUi} />
            </SwipeableDrawer>
          </Breakpoint>
        </Box>
      </Grid>

      <Breakpoint from="lg">
        <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={3}>
          <FilterComponent menuMode="inline" direction="row" />
          <ClearAllButton updateCriteria={updateCriteria} updateUi={updateUi} />
          <InboxConfigControl />
        </Stack>
      </Breakpoint>
    </Box>
  );
};

export default SearchComponent;
