import { useSelector } from "react-redux";
import { messagesSelector } from "../../../../store/state/messages/selectors";
import { Grid } from "@mui/material";
import { NoEmailsComponent } from "../noEmails/NoEmailsComponent";
import Actions from "../Actions";
import MessageGrid from "./MessageGrid";
import { useInboxStateContext } from "../../../../contexts/InboxStateContext";

export const MessagesListComponent = () => {
  const messages = useSelector(messagesSelector);
  const { ui, setMessageId, selection, setSelection } = useInboxStateContext();

  if (messages.length === 0 && !ui.messagesLoading) {
    return <NoEmailsComponent />;
  }

  return (
    <Grid container sx={{ flexDirection: "column", flex: 1 }}>
      {selection.length > 0 && (
        <Grid
          container
          alignItems="center"
          px={0.5}
          sx={(theme) => ({ borderTop: 1, borderBottom: 1, borderColor: theme.palette.divider })}
        >
          <Grid item>
            <Actions showSelectAll selection={selection} setSelection={setSelection} />
          </Grid>
        </Grid>
      )}
      <Grid item flex={1}>
        <MessageGrid selection={selection} setSelection={setSelection} navigateToMessage={setMessageId} />
      </Grid>
      <Grid
        sx={(theme) => ({
          bgcolor: theme.palette.grey[50],
          height: "2rem",
          borderTop: 1,
          borderColor: theme.palette.divider,
        })}
      ></Grid>
    </Grid>
  );
};
