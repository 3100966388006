import BankIcon from "@mui/icons-material/AccountBalanceOutlined";
import { Avatar, Box, Divider, Paper, Stack, SxProps, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import LimitedListWithBadge from "../../../../../shared/components/LimitedListWithBadge";
import ShowMoreLessButton from "../../../../../shared/components/ShowMoreLessButton";
import useToggleState from "../../../../../shared/hooks/useToggleState";
import { InvestorBankAccountDetails } from "../../../../api/types/bankAccountTypes";
import BankAccountDetailsTable from "./BankAccountDetailsTable";

interface Props {
  bankAccount: InvestorBankAccountDetails;
  sx?: SxProps<Theme>;
}

const BankAccountCard = ({ bankAccount, sx }: Props) => {
  const [expanded, toggleExpanded] = useToggleState(false);

  return (
    <Paper variant="outlined" sx={{ px: 2, ...sx }}>
      <Stack direction="row" spacing={1.5} py={1.5}>
        <Avatar variant="rounded" sx={{ width: 40, height: 40, bgcolor: grey[100] }}>
          <BankIcon />
        </Avatar>
        <Stack spacing={0.5}>
          <Typography variant="subtitle1">{bankAccount.description}</Typography>
          <Typography variant="caption" color="text.secondary">
            {bankAccount.accountName}
          </Typography>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Stack spacing={1.5} pt={1.5} pb={2}>
        <Typography variant="subtitle1">Bank Details</Typography>
        {expanded && <BankAccountDetailsTable bankAccount={bankAccount} />}
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Box py={1.5}>
        <LimitedListWithBadge
          title="Fund Assignments"
          items={bankAccount.fundNames}
          maxVisibleItems={5}
          itemTypographyProps={{ variant: "caption", color: "text.secondary" }}
        />
      </Box>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Box py={1}>
        <ShowMoreLessButton expanded={expanded} onClick={() => toggleExpanded()} />
      </Box>
    </Paper>
  );
};

export default BankAccountCard;
