import { Box } from "@mui/material";

const SwipePuller = () => {
  return (
    <Box
      sx={(theme) => ({
        width: 40,
        height: 3,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 20px)",
      })}
    />
  );
};

export default SwipePuller;
