import { Box, Button, Card, CardContent, Container, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import FundraisingStatusTag from "../../../shared/components/FundraisingStatusTag";
import { useClientContext } from "../../contexts/ClientContext";
import { availableFundraisingsSelector } from "../../store/state/fundraising/selectors";
import { impersonationSelector } from "../../store/state/user/selectors";

const FundraisingListPage = () => {
  const { clientCode } = useClientContext();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const availableFundraisings = useSelector(availableFundraisingsSelector);
  const isImpersonation = useSelector(impersonationSelector);

  if (availableFundraisings.length === 0) {
    return (
      <Container maxWidth="sm" sx={{ pt: 4 }}>
        <Typography variant="h5" color="text.secondary" py={2}>
          There are no active fundraisings at the moment
        </Typography>
      </Container>
    );
  }

  if (availableFundraisings.length === 1) {
    const activeFundraising = availableFundraisings[0];
    if (activeFundraising) {
      return <Navigate to={`/${clientCode}/fundraising/${activeFundraising.id}`} />;
    }
  }

  return (
    <Container maxWidth="md" sx={{ pt: 4 }}>
      <Stack spacing={1}>
        <Typography variant="h5" py={2}>
          Active Fundraisings
        </Typography>
        {availableFundraisings.map((fundraising) => (
          <Card key={fundraising.id} variant="outlined">
            <CardContent>
              <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                alignItems={isMobile ? "flex-start" : "center"}
                justifyContent="space-between"
                columnGap={1}
                rowGap={2}
              >
                <Stack spacing={0.5} width={isMobile ? "100%" : "75%"}>
                  <Stack direction="row" spacing={1} justifyContent={isMobile ? "space-between" : "flex-start"}>
                    <Typography variant="h6">{fundraising.name}</Typography>
                    {isImpersonation && <FundraisingStatusTag status={fundraising.status} />}
                  </Stack>
                  {fundraising.documentCount > 0 && (
                    <Typography variant="body2" color="text.secondary">
                      {`${fundraising.documentCount.toLocaleString()} ${fundraising.documentCount === 1 ? "Document" : "Documents"}`}
                    </Typography>
                  )}
                </Stack>
                <Button component={Link} variant="contained" to={`/${clientCode}/fundraising/${fundraising.id}`}>
                  View Details
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Container>
  );
};

export default FundraisingListPage;
