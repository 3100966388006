import { useSelector } from "react-redux";
import { useClientContext } from "../../../../contexts/ClientContext";
import { hasPermissionSelector, workspaceInvestorSelector } from "../../../../store/state/user/selectors";
import { RootState } from "../../../../store/store";
import LpSettingsWrapper from "../LpSettingsWrapper";
import BankAccounts from "./BankAccounts";

const BankSettings = () => {
  const { clientCode, isFeatureEnabled } = useClientContext();
  const workspaceInvestor = useSelector(workspaceInvestorSelector);

  const hasEditPermissions = useSelector(
    (state: RootState) =>
      !!workspaceInvestor &&
      hasPermissionSelector(state, clientCode, workspaceInvestor.investorId, "ManageInvestorSettings")
  );

  const canUserEdit = hasEditPermissions && isFeatureEnabled("BankAccountsDetailsEditor");

  return (
    <LpSettingsWrapper title="Bank Accounts" subtitle={workspaceInvestor?.investorTitle}>
      {workspaceInvestor && (
        <BankAccounts
          canUserEdit={canUserEdit}
          investorId={workspaceInvestor.investorId}
          fundInvestors={workspaceInvestor.fundInvestors}
        />
      )}
    </LpSettingsWrapper>
  );
};

export default BankSettings;
