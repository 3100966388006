import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "../../../../shared/utilities/cloneDeep";
import { fetchBalance, fetchCashFlowData } from "./thunk";
import { BalanceFiltersData, cashFlowDefaults, CashFlowRequest, DashboardState } from "./types";

export const defaultCashFlowRequest: CashFlowRequest = {
  paging: {
    page: 0,
    size: cashFlowDefaults.cashFlowPageSizeAll,
  },
  investors: undefined,
  funds: undefined,
  paymentStatuses: undefined,
  from: undefined,
  to: undefined,
};

export const defaultBalanceFilters: BalanceFiltersData = {
  investors: [],
  funds: [],
  reportingDateIso: undefined,
  initialDateIso: undefined,
};

const initialState: DashboardState = {
  balanceLoading: false,
  balanceError: "",
  balanceData: undefined,
  balanceInitialData: undefined,
  balanceFilters: defaultBalanceFilters,
  balanceClient: undefined,
  cashFlowData: undefined,
  cashFlowRequestData: cloneDeep(defaultCashFlowRequest),
  cashFlowError: "",
  cashFlowLoading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateCashFlowRequest: (state, action: PayloadAction<Partial<CashFlowRequest>>) => {
      if (state.cashFlowRequestData) {
        Object.assign(state.cashFlowRequestData, action.payload);
      }
    },
    resetCashFlowRequest: (state) => {
      state.cashFlowRequestData = cloneDeep(defaultCashFlowRequest);
    },
    updateBalanceFilters: (state, action: PayloadAction<Partial<BalanceFiltersData>>) => {
      Object.assign(state.balanceFilters, action.payload);
    },
    resetBalanceFilters: (state) => {
      state.balanceFilters = {
        investors: [],
        funds: [],
        reportingDateIso: state.balanceFilters?.initialDateIso,
        initialDateIso: state.balanceFilters?.initialDateIso,
      };
    },
    resetBalanceInitialData: (state) => {
      state.balanceInitialData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.balanceData = action.payload.data;
        state.balanceLoading = false;
        state.balanceError = "";
        state.balanceClient = action.payload.client;
        state.balanceFilters.reportingDateIso = action.payload.reportingDateIso;
        if (action.payload.updateReportingPeriods) {
          state.balanceInitialData = { reportingPeriods: action.payload.reportingPeriods };
        }
        if (action.payload.reportingPeriods.length > 0) {
          state.balanceFilters.initialDateIso = action.payload.reportingDateIso;
        }
      })
      .addCase(fetchBalance.rejected, (state, action) => {
        state.balanceError = action.error.message;
        state.balanceLoading = false;
      })
      .addCase(fetchBalance.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(fetchCashFlowData.fulfilled, (state, action) => {
        if (action.payload.request.paging.page > 0 && action.payload.data) {
          state.cashFlowData = state.cashFlowData?.concat(action.payload.data);
        } else {
          state.cashFlowData = action.payload.data;
        }
        state.cashFlowRequestData = action.payload.request;
        state.cashFlowLoading = false;
        state.cashFlowError = "";
      })
      .addCase(fetchCashFlowData.rejected, (state, action) => {
        state.cashFlowError = action.error.message;
        state.cashFlowLoading = false;
      })
      .addCase(fetchCashFlowData.pending, (state) => {
        state.cashFlowLoading = true;
      });
  },
});

export const {
  updateCashFlowRequest,
  resetCashFlowRequest,
  updateBalanceFilters,
  resetBalanceFilters,
  resetBalanceInitialData,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
