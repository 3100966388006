import { Typography } from "@mui/material";
import { useState } from "react";
import FieldsDiffTable from "../../../../../shared/components/FieldsDiffTable";
import { ChangeRequestDetails } from "../../../../api/types/changeRequestsTypes";
import PendingChangeRequestDialog from "../common/PendingChangeRequestDialog";
import PendingChangesSection from "../common/PendingChangesSection";
import { useBankAccountsContext } from "./BankAccountsContext";

interface Props {
  onChangeRequestCanceled: (workflowId: string) => void;
}

const ChangeRequestsStatus = ({ onChangeRequestCanceled }: Props) => {
  const { investorId, pendingChangeRequests } = useBankAccountsContext();

  const [showPendingChangeRequestDialog, setShowPendingChangeRequestDialog] = useState(false);
  const [visiblePendingChangeRequest, setVisiblePendingChangeRequest] = useState<ChangeRequestDetails>();

  if (pendingChangeRequests.length === 0) {
    return null;
  }

  const handleViewRequest = (changeRequest: ChangeRequestDetails) => {
    setVisiblePendingChangeRequest(changeRequest);
    setShowPendingChangeRequestDialog(true);
  };

  const handleChangeRequestCanceled = () => {
    if (visiblePendingChangeRequest) {
      onChangeRequestCanceled(visiblePendingChangeRequest.workflowId);
    }
    setShowPendingChangeRequestDialog(false);
  };

  return (
    <>
      <PendingChangesSection pendingChangeRequests={pendingChangeRequests} onViewRequest={handleViewRequest} />
      {visiblePendingChangeRequest && (
        <PendingChangeRequestDialog
          open={showPendingChangeRequestDialog}
          onClose={() => setShowPendingChangeRequestDialog(false)}
          investorId={investorId}
          changeRequestDetails={visiblePendingChangeRequest}
          onChangeRequestCanceled={handleChangeRequestCanceled}
        >
          {visiblePendingChangeRequest.fieldChanges?.length && (
            <>
              <Typography variant="subtitle1">Bank Details</Typography>
              <FieldsDiffTable
                fieldChanges={visiblePendingChangeRequest.fieldChanges}
                newValueColumnLabel="New"
                sortKeys
              />
            </>
          )}
          {visiblePendingChangeRequest.allowedAssignedEntityChanges?.length && (
            <>
              <Typography variant="subtitle1">Fund Assignments</Typography>
              <FieldsDiffTable
                fieldChanges={visiblePendingChangeRequest.allowedAssignedEntityChanges.map((change) => ({
                  ...change,
                  key: change.displayName,
                  fieldType: "Text",
                }))}
                oldValueColumnLabel="Current"
                newValueColumnLabel="Change"
                sortKeys
              />
            </>
          )}
        </PendingChangeRequestDialog>
      )}
    </>
  );
};

export default ChangeRequestsStatus;
