import { createSvgIcon } from "@mui/material";

const DeleteBankIcon = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8296 24.8718L40 15.7887L53.1704 24.8718H26.8296ZM11 30L40 10L69 30H11ZM27 35L27 56H22L22 35H27ZM14 61H67V66H14V61ZM43 56L43 35H38L38 56H43ZM59 35V56H54V35H59Z"
      fill="currentColor"
    />
    <path d="M71.5361 53.0001L54 70.5361L57.5355 74.0716L75.0716 56.5356L71.5361 53.0001Z" fill="#F44336" />
    <path d="M57.5355 53.0001L54 56.5356L71.5361 74.0717L75.0716 70.5362L57.5355 53.0001Z" fill="#F44336" />
  </svg>,
  "DeleteBankIcon"
);

export default DeleteBankIcon;
