import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ApiError } from "../../../../../shared/api/types";
import FieldsDiffTable from "../../../../../shared/components/FieldsDiffTable";
import { logError } from "../../../../../shared/logging";
import { ChangeRequestDetails } from "../../../../api/types/changeRequestsTypes";
import ChangesWarningAlert from "../common/ChangesWarningAlert";
import PendingChangeRequestDialog from "../common/PendingChangeRequestDialog";
import PendingChangesAlert from "../common/PendingChangesAlert";

interface Props {
  investorId: string;
  investorName: string;
  pendingChangeRequest?: ChangeRequestDetails;
  fetchError?: ApiError;
  onChangeRequestCanceled: () => void;
}

const ChangeRequestStatus = ({
  investorId,
  investorName,
  pendingChangeRequest,
  fetchError,
  onChangeRequestCanceled,
}: Props) => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  if (fetchError) {
    logError(fetchError, "[ChangeRequestStatus] fetchError");
    return (
      <Alert severity="error">
        <AlertTitle>Failed to load change request status for this investor</AlertTitle>
      </Alert>
    );
  }

  if (!pendingChangeRequest) {
    return <ChangesWarningAlert />;
  }

  const handleChangeRequestCanceled = () => {
    setDetailsOpen(false);
    onChangeRequestCanceled();
  };

  return (
    <>
      <PendingChangesAlert pendingChangeRequest={pendingChangeRequest} onViewRequest={() => setDetailsOpen(true)} />
      <PendingChangeRequestDialog
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        investorId={investorId}
        changeRequestDetails={pendingChangeRequest}
        onChangeRequestCanceled={handleChangeRequestCanceled}
      >
        <Stack spacing={0.5}>
          <Typography variant="subtitle1">Investor Details</Typography>
          {investorName && <Typography color="text.secondary">{investorName}</Typography>}
        </Stack>
        <Box height="100%" pb={2}>
          <FieldsDiffTable
            fieldChanges={pendingChangeRequest.fieldChanges ?? []}
            oldValueColumnLabel="Current"
            newValueColumnLabel="Change"
            sortKeys
          />
        </Box>
      </PendingChangeRequestDialog>
    </>
  );
};

export default ChangeRequestStatus;
