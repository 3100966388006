import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../shared/utilities/typeHelper";
import { AccessCategory, AccessRole, ClientDictionaries, FundInvestor } from "../../api/types/settingsTypes";

interface ContextValue {
  fundInvestors: FundInvestor[];
  roles: AccessRole[];
  accessCategories: AccessCategory[];
  dictionaries: ClientDictionaries;
  loading: boolean;
}

const SettingsContext = createContext<ContextValue | undefined>(undefined);

export const SettingsContextProvider = ({
  fundInvestors,
  roles,
  accessCategories,
  dictionaries,
  loading,
  children,
}: PropsWithChildren<ContextValue>) => {
  return (
    <SettingsContext.Provider value={{ fundInvestors, roles, accessCategories, dictionaries, loading }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () =>
  defined(useContext(SettingsContext), "Attempt to use SettingsContext without provider");
