import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Checkbox, Divider, IconButton, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderBox from "../../../../../../shared/components/LoaderBox";
import { Contact } from "../../../../../api/types/settingsTypes";
import { UserActions } from "../../../../../store/state/user/actions";
import { investorsSelector } from "../../../../../store/state/user/selectors";
import { AppDispatch } from "../../../../../store/store";
import { systemRoleToDisplayText } from "../../../../../utilities/rolesHelper";
import { useSettingsContext } from "../../../SettingsContext";
import ContactsComponent from "../contacts/ContactsComponent";

interface NavigationState {
  fund?: string;
}

const PermissionsComponent = () => {
  const { client, investorId } = useParams();
  const { loading, fundInvestors, accessCategories, roles } = useSettingsContext();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [fundInvestorId, setFundInvestorId] = useState("");

  const investors = useSelector(investorsSelector);

  useEffect(() => {
    if (investorId) {
      dispatch(UserActions.UpdateWorkspace(investorId));
    }
  }, [dispatch, investorId]);

  const getGridColumns = (): GridColDef<Contact>[] => {
    const staticColumns: GridColDef<Contact>[] = [
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        disableColumnMenu: true,
        disableReorder: true,
        flex: 1,
        minWidth: 150,
      },
      {
        field: "email",
        headerName: "Email",
        sortable: true,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 150,
      },
      {
        field: "access",
        headerName: "Access",
        disableColumnMenu: true,
        width: 200,
        renderCell: ({ row }) => (
          <Typography>{row.permissions.roles.map((r) => systemRoleToDisplayText(roles, r)).join(", ")}</Typography>
        ),
      },
    ];

    const accessColumns: GridColDef<Contact>[] = accessCategories.map((ac) => ({
      field: ac.externalId,
      headerName: ac.name,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      width: 100,
      renderCell: ({ row }) => (
        <Checkbox color="primary" checked={row.permissions.categories.includes(ac.externalId)} disabled />
      ),
    }));

    return [...staticColumns, ...accessColumns];
  };

  const navigationState = location.state as NavigationState;

  const investor = investors.find((i) => i.investorId === investorId) ?? investors[0];
  if (!investor) {
    return null;
  }

  return (
    <LoaderBox loading={loading || !investorId}>
      <Box width="100%" display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
        <Stack direction="row" spacing={1} alignItems="center" py={2} px={3}>
          <IconButton
            onClick={() =>
              navigate(`/${client}/settings/lp/members`, {
                state: { fund: fundInvestors.find(({ id }) => id === fundInvestorId)?.fund.name },
              })
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <Stack spacing={0.5}>
            <Typography variant="h6">Permissions</Typography>
            <Typography color="text.secondary" sx={{ mb: "2rem" }}>
              {investor.investorTitle}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Box pt={2} px={3} height="100%">
          <ContactsComponent
            investorId={investor.investorId}
            defaultFund={navigationState?.fund}
            columns={getGridColumns()}
            onFundInvestorSelected={setFundInvestorId}
          />
        </Box>
      </Box>
    </LoaderBox>
  );
};

export default PermissionsComponent;
