import { SvgIcon, SvgIconProps } from "@mui/material";

const GeneralIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <path d="M10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6L10 0ZM9 16H2V14H9V16ZM14 12H2V10H14V12ZM9 7V1.5L14.5 7H9Z" />
    </SvgIcon>
  );
};

export default GeneralIcon;
