import { Stack } from "@mui/material";
import { InvestorBankAccountDetails } from "../../../../../api/types/bankAccountTypes";
import BankAccountOption from "./BankAccountOption";

interface Props {
  bankAccounts: InvestorBankAccountDetails[];
  selectedBankAccountId: string;
  onSelectedBankAccountChange: (bankAccountId: string) => void;
}

const BankAccountSelector = ({ bankAccounts, selectedBankAccountId, onSelectedBankAccountChange }: Props) => {
  return (
    <Stack width="100%" spacing={1.5}>
      {bankAccounts.map((bankAccount) => (
        <BankAccountOption
          key={bankAccount.id}
          bankAccount={bankAccount}
          selected={selectedBankAccountId === bankAccount.id}
          onSelect={onSelectedBankAccountChange}
        />
      ))}
    </Stack>
  );
};

export default BankAccountSelector;
