import { Stack, Typography } from "@mui/material";
import ExpandableListWithBadge from "../../../../../../shared/components/ExpandableListWithBadge";
import FieldsDiffTable from "../../../../../../shared/components/FieldsDiffTable";
import ChangesWarningAlert from "../../common/ChangesWarningAlert";
import { getBankDescription, getFieldChanges, getSelectedFunds } from "./newBankAccountReviewHelper";
import { NewBankAccountState } from "./newBankAccountState";

interface Props {
  state: NewBankAccountState;
}

const NewBankAccountReview = ({ state }: Props) => {
  return (
    <Stack py={2.5} spacing={2.5}>
      <ChangesWarningAlert />
      <Stack spacing={2}>
        <Stack spacing={0.5}>
          <Typography variant="h6">Bank Details</Typography>
          <Typography color="text.secondary">{getBankDescription(state)}</Typography>
        </Stack>
        <FieldsDiffTable fieldChanges={getFieldChanges(state)} newValueColumnLabel="New" />
      </Stack>
      <ExpandableListWithBadge title="Fund Assignments" items={getSelectedFunds(state)} maxVisibleItems={5} />
    </Stack>
  );
};

export default NewBankAccountReview;
