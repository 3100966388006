import { Stack, Typography } from "@mui/material";
import { formatDate } from "../../../../shared/utilities/dateUtils";

const FundCellComponent = ({ value, reportingDate }: { value: string; reportingDate?: Date }) => {
  return (
    <Stack>
      <Typography variant="subtitle2">{value}</Typography>
      {reportingDate && (
        <Typography color="text.secondary" variant="caption">{`as of ${formatDate(reportingDate)}`}</Typography>
      )}
    </Stack>
  );
};

export default FundCellComponent;
