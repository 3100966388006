import { MenuProps } from "@mui/material";

export type BalanceCardData = {
  reportingDate?: Date;
  investorName: string;
  fundName: string;
  fields: [string, string][]; // [title, value]
};

export type CardSubheaderProps = {
  title: string;
  titleVariant?: "h6" | "subtitle1";
  subtitle?: string;
  bgColor?: string;
};

export type CashFlowCardHeaderProps = CardSubheaderProps & {
  statusText: string;
  statusColor: string;
};

export const mobileOptionsMenuProps = {
  anchorEl: document.body,
  anchorOrigin: {
    vertical: "center",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "center",
  },
} as Partial<MenuProps>;
