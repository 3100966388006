import { Stack, TypographyProps } from "@mui/material";
import useToggleState from "../hooks/useToggleState";
import LimitedListWithBadge from "./LimitedListWithBadge";
import ShowMoreLessButton from "./ShowMoreLessButton";

interface Props {
  title: string;
  items: string[];
  maxVisibleItems: number;
  explicitTotalCount?: number;
  titleTypographyProps?: TypographyProps;
  itemTypographyProps?: TypographyProps;
}

const ExpandableListWithBadge = ({
  title,
  items,
  maxVisibleItems,
  explicitTotalCount,
  titleTypographyProps,
  itemTypographyProps,
}: Props) => {
  const [expanded, toggleExpanded] = useToggleState(false);

  return (
    <Stack spacing={0.5} alignItems="flex-start">
      <LimitedListWithBadge
        title={title}
        items={items}
        maxVisibleItems={maxVisibleItems}
        expanded={expanded}
        explicitTotalCount={explicitTotalCount}
        titleTypographyProps={titleTypographyProps}
        itemTypographyProps={itemTypographyProps}
      />
      {items.length > maxVisibleItems && <ShowMoreLessButton expanded={expanded} onClick={() => toggleExpanded()} />}
    </Stack>
  );
};

export default ExpandableListWithBadge;
