import { Badge, lighten } from "@mui/material";
import { PropsWithChildren } from "react";
import { makeLighterBackgroundFromColor } from "../../../shared/utilities/colorHelper";

interface Props {
  value: number;
  ml?: number;
  mr?: number;
  mt?: number;
}

const InboxBadge = (props: PropsWithChildren<Props>) => {
  const { value, ml, mr, mt, children } = props;

  return (
    <Badge
      badgeContent={value}
      sx={(theme) => ({
        ">span": {
          mr: mr || 1.5,
          ml: ml || 0,
          mt: mt || 0,
          color: theme.palette.text.primary,
          border: "1px solid " + lighten(theme.palette.primary.main, 0.6),
          backgroundColor: makeLighterBackgroundFromColor(theme.palette.primary.main),
        },
      })}
    >
      {children}
    </Badge>
  );
};

export default InboxBadge;
