import { Message } from "../../../../../store/state/messages/types";
import { MessageLevelComponent } from "../../../messageLevel/MessageLevelComponent";
import { Grid, Stack, Typography } from "@mui/material";
import MessageTypeComponent from "../../MessageType";
import { impersonationSelector } from "../../../../../store/state/user/selectors";
import { useSelector } from "react-redux";
import MessagePublishStatusIcon from "../../MessagePublishStatusIcon";
import { getEntityFromMessage, getFundFromMessage } from "../../../../../store/state/messages/messageHelper";
import { convertISODateShort } from "../../../../../../shared/utilities/dateUtils";

interface ItemTextProps {
  text: string | undefined;
  emphasize: boolean;
  subText?: string;
}

const ItemText = ({ text, emphasize, subText }: ItemTextProps) => {
  const fontWeight = emphasize ? "bold" : "normal";

  return (
    <Stack sx={{ whiteSpace: "normal" }}>
      <Typography variant="subtitle2" fontWeight={fontWeight}>
        {text}
      </Typography>
      {subText && (
        <Typography variant="caption" color="text.secondary" fontWeight={fontWeight}>
          {subText}
        </Typography>
      )}
    </Stack>
  );
};

interface Props {
  message: Message;
}

export const MessageItemComponent = (props: Props) => {
  const { message } = props;
  const isImpersonation = useSelector(impersonationSelector);

  return (
    <Grid
      container
      direction={"column"}
      flex={1}
      sx={{
        height: "100%",
      }}
    >
      <Grid container flex={1} py={1} pr={1.5} alignItems="center">
        <Grid container flex={1} justifyContent="space-between">
          <ItemText
            emphasize={!message.isRead}
            text={getFundFromMessage(message)}
            subText={getEntityFromMessage(message)}
          />
          <ItemText emphasize={!message.isRead} text={convertISODateShort(message.sendDate)} />
        </Grid>
        <Grid container alignItems={"center"} gap={1}>
          <Grid zeroMinWidth item xs={9}>
            <ItemText emphasize={!message.isRead} text={message.content.subject} />
          </Grid>
          <Grid item xs={2} alignItems="flex-end">
            <MessageTypeComponent message={message} />
          </Grid>
        </Grid>
        <Grid container gap={1} alignItems="center">
          <MessageLevelComponent level={message.messageLevel} value={message.categoryId} />
          {isImpersonation && (
            <>
              <MessagePublishStatusIcon publishingStatus={message.publishingStatus} />
              <ItemText emphasize={!message.isRead} text={message.publishingStatus} />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
