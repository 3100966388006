import { useSelector } from "react-redux";
import { messagesSelector } from "../../../store/state/messages/selectors";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { Checkbox, Grid, Divider } from "@mui/material";
import DownloadAllAction from "./desktop/messageActions/DownloadAllAction";
import MarkAsImportantAction from "./desktop/messageActions/MarkAsImportantAction";
import MarkAsNotImportantAction from "./desktop/messageActions/MarkAsNotImportantAction";

interface Props {
  selection: GridRowSelectionModel;
  showSelectAll?: boolean;
  compact?: boolean;
  setSelection: (selection: GridRowSelectionModel) => void;
}

export const Actions = (props: Props) => {
  const { selection, showSelectAll, compact, setSelection } = props;

  const messages = useSelector(messagesSelector);

  const changeSelection = useCallback(() => {
    if (selection.length === 0) {
      setSelection(messages.map((m) => m.id));
    } else {
      setSelection([]);
    }
  }, [messages, selection.length, setSelection]);

  return (
    <Grid container sx={{ alignItems: "center", gap: 1, justifyContent: compact ? "center" : "inherit" }}>
      {showSelectAll && messages && messages.length > 0 && (
        <Checkbox checked={selection.length > 0} onChange={() => changeSelection()} />
      )}
      {!compact && selection.length > 0 && <Divider orientation="vertical" flexItem sx={{ my: 1 }} />}
      <Grid item>
        <DownloadAllAction compact={compact} selection={selection} />
      </Grid>
      <Grid item>
        <MarkAsImportantAction compact={compact} selection={selection} />
      </Grid>
      <Grid item>
        <MarkAsNotImportantAction compact={compact} selection={selection} />
      </Grid>
    </Grid>
  );
};

export default Actions;
