import { Stack, Typography } from "@mui/material";

interface CashFlowValueWithTitleProps {
  label: string;
  value: string;
  valueColor?: "textPrimary" | "textSecondary" | "primary.main";
  valueVariant?: "h6" | "body1";
}

const CashFlowValueWithTitle = ({ label, value, valueColor, valueVariant }: CashFlowValueWithTitleProps) => {
  return (
    <Stack spacing={1}>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <Typography variant={valueVariant || "body1"} color={valueColor || "textPrimary"}>
        {value}
      </Typography>
    </Stack>
  );
};

export default CashFlowValueWithTitle;
