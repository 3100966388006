import HelpIcon from "@mui/icons-material/HelpOutlineRounded";
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { Dispatch } from "react";
import UnavailableFundTooltip from "../../common/UnavailableFundTooltip";
import { Action, NewBankAccountState } from "./newBankAccountState";

interface Props {
  state: NewBankAccountState;
  dispatch: Dispatch<Action>;
}

const FundAssignmentsForm = ({ state, dispatch }: Props) => {
  return (
    <Stack spacing={1} py={2.5}>
      <Typography color="text.secondary">This bank account will be used to receive distributions from:</Typography>
      <List>
        {state.fundAssignments.map(({ fundInvestorId, fundName, selected, disabled }) => (
          <ListItem key={fundInvestorId} value={fundInvestorId} dense disablePadding>
            <UnavailableFundTooltip active={disabled}>
              <ListItemButton
                role={undefined}
                onClick={() => dispatch({ type: "toggle_fund_assignment", fundInvestorId })}
                disabled={disabled}
                dense
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox edge="start" tabIndex={-1} disableRipple checked={selected} />
                </ListItemIcon>
                <ListItemText>
                  <Stack direction="row" spacing={1} alignItems="center" component="span">
                    <Typography>{fundName}</Typography>
                    {disabled && <HelpIcon fontSize="small" color="secondary" />}
                  </Stack>
                </ListItemText>
              </ListItemButton>
            </UnavailableFundTooltip>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default FundAssignmentsForm;
