import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { MenuProps } from "@mui/material";
import FilterAutocomplete from "./FilterAutocomplete";

interface Props {
  values: string[];
  selectedValues: string | string[];
  onSelected: (values: string | string[]) => void;
  disabled?: boolean;
  menuProps?: Partial<MenuProps>;
  placeholder?: string;
}

const PaymentStatusFilter = ({ values, selectedValues, onSelected, disabled, menuProps, placeholder }: Props) => {
  return (
    <FilterAutocomplete
      disabled={disabled}
      values={values}
      selectedValues={selectedValues}
      onSelected={onSelected}
      startIcon={<AccountBalanceWalletOutlinedIcon color="secondary" />}
      placeholder={placeholder || "Status"}
      menuProps={menuProps}
    />
  );
};

export default PaymentStatusFilter;
