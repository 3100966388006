import { Box, Tab, Tabs } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useMemo, useState } from "react";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { Contact } from "../../../../../api/types/settingsTypes";
import { useSettingsContext } from "../../../SettingsContext";
import ContactsGrid from "./ContactsGrid";

interface Props {
  investorId: string;
  defaultFund?: string;
  columns: GridColDef<Contact>[];
  actionsEl?: JSX.Element;
  onFundInvestorSelected?: (investorId: string) => void;
}

const filterContacts = (searchPhrase: string, allContacts: Contact[]) => {
  if (!searchPhrase) {
    return allContacts;
  }

  const phrase = searchPhrase.toLocaleLowerCase();
  return allContacts.filter(
    (c) => c.name.toLocaleLowerCase().indexOf(phrase) !== -1 || c.email.toLocaleLowerCase().indexOf(phrase) !== -1
  );
};

const ContactsComponent = ({ investorId, defaultFund, columns, actionsEl, onFundInvestorSelected }: Props) => {
  const { fundInvestors } = useSettingsContext();

  const [fundName, setFundName] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");

  const investorFunds = useMemo(() => {
    const funds = fundInvestors
      .filter((fundInvestor) => fundInvestor.investorId === investorId)
      .flatMap(({ fund }) => fund)
      .filter(
        (f, index, self) =>
          self.findIndex((el) => {
            return el.name === f.name;
          }) === index
      );
    return funds;
  }, [fundInvestors, investorId]);

  const setCurrentFund = useCallback(
    (fundName: string) => {
      setFundName(fundName);
      const selectedInvestor = fundInvestors.find(
        (fundInvestor) => fundInvestor.investorId === investorId && fundInvestor.fund.name === fundName
      );

      if (selectedInvestor) {
        onFundInvestorSelected?.call(this, selectedInvestor.id);
      }
    },
    [fundInvestors, investorId, onFundInvestorSelected]
  );

  useEffect(() => {
    setCurrentFund(defaultFund || investorFunds[0]?.name || "");
  }, [defaultFund, investorId, investorFunds, setCurrentFund]);

  const fundInvestor = fundInvestors.find(
    (fundInvestor) => fundInvestor.investorId === investorId && fundInvestor.fund.name === fundName
  );

  const contacts = fundInvestor?.contacts ?? [];

  const filteredContacts = filterContacts(searchPhrase, contacts);

  return (
    <Box height="100%">
      <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1} mb={2}>
        <Box>{actionsEl}</Box>
        <SearchField onSearch={setSearchPhrase} placeholder="Search Member" debounceTimeMs={500} />
      </Box>
      {fundName && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={fundName} onChange={(_, value) => setCurrentFund(value)}>
            {investorFunds.map((fund) => (
              <Tab key={fund.name} value={fund.name} label={fund.name} sx={{ textTransform: "none" }}></Tab>
            ))}
          </Tabs>
        </Box>
      )}
      <ContactsGrid contacts={filteredContacts} columns={columns} />
    </Box>
  );
};

export default ContactsComponent;
