import { Stack, Typography, useTheme } from "@mui/material";
import { useLocalization } from "../../hooks/useLocalization";

interface Props {
  email?: string;
  phone?: string;
  isMobile?: boolean;
}

const SupportInfo = ({ email, phone, isMobile }: Props) => {
  const locale = useLocalization().supportInfo;
  const theme = useTheme();

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">{locale.title}</Typography>

      {email && (
        <Stack>
          <Typography color="text.secondary">{locale.email}</Typography>
          <a style={{ color: theme.palette.primary.main }} href={`mailto:${email}`}>
            {email}
          </a>
        </Stack>
      )}

      {phone && (
        <Stack>
          <Typography color="text.secondary">{locale.phone}</Typography>
          {isMobile ? (
            <a style={{ color: theme.palette.primary.main }} href={`tel:+${phone}`}>
              {phone}
            </a>
          ) : (
            <Typography>+{phone}</Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default SupportInfo;
