import { Divider, Stack, Typography } from "@mui/material";
import { formatMoney } from "../../../../../shared/utilities/formatters";
import { useLocalization } from "../../../../hooks/useLocalization";
import {
  CapCallOrDistributionTransaction,
  CapCallOrDistributionTransactionImpact,
} from "../../../../store/state/dashboard/types";

interface CashFlowTransactionDetailsProps {
  transaction: CapCallOrDistributionTransaction;
}

const CashFlowTransactionDetails = ({ transaction }: CashFlowTransactionDetailsProps) => {
  const locale = useLocalization();

  const impactTextMap: Record<CapCallOrDistributionTransactionImpact, string> = {
    None: locale.dashboard.cashFlows.impacts.no_impact,
    Increases: locale.dashboard.cashFlows.impacts.increases,
    Decreases: locale.dashboard.cashFlows.impacts.decreases,
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography color="textPrimary">{transaction.detailsDescription}</Typography>

        <Stack spacing={0.5} alignItems="flex-end">
          <Typography color="textPrimary">
            {formatMoney(transaction.netAmount * -1, transaction.currency, { currencySign: "accounting" })}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {impactTextMap[transaction.impact]}
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ borderStyle: "dashed" }} />
    </>
  );
};

export default CashFlowTransactionDetails;
