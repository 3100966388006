import TimeIcon from "@mui/icons-material/AccessTimeRounded";
import { Alert, AlertTitle, Button, Stack } from "@mui/material";
import useToggleState from "../../../../../shared/hooks/useToggleState";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { ChangeRequestDetails } from "../../../../api/types/changeRequestsTypes";
import PendingChangesAlert from "./PendingChangesAlert";

interface Props {
  pendingChangeRequests: ChangeRequestDetails[];
  onViewRequest: (pendingChangeRequest: ChangeRequestDetails) => void;
}

const PendingChangesSection = ({ pendingChangeRequests, onViewRequest }: Props) => {
  const [expanded, toggleExpanded] = useToggleState(false);

  if (pendingChangeRequests.length === 0) {
    return null;
  }

  if (pendingChangeRequests.length === 1) {
    return (
      <PendingChangesAlert pendingChangeRequest={defined(pendingChangeRequests[0])} onViewRequest={onViewRequest} />
    );
  }

  return (
    <Alert severity="warning" iconMapping={{ warning: <TimeIcon fontSize="inherit" /> }} sx={{ position: "relative" }}>
      <AlertTitle>{pendingChangeRequests.length} Pending Changes</AlertTitle>
      <Button
        variant="text"
        color="inherit"
        sx={{ position: "absolute", right: 12, top: 12, py: 0 }}
        onClick={toggleExpanded}
      >
        {expanded ? "Hide" : "View All"}
      </Button>
      {expanded && (
        <Stack spacing={2}>
          {pendingChangeRequests.map((cr) => (
            <PendingChangesAlert
              key={`${cr.workflowId}_${cr.referenceCode}`}
              pendingChangeRequest={cr}
              onViewRequest={onViewRequest}
              hideIcon
              alertSx={{ pl: 0 }}
            />
          ))}
        </Stack>
      )}
    </Alert>
  );
};

export default PendingChangesSection;
