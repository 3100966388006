import { SvgIcon, SvgIconProps } from "@mui/material";

const QuarterlyNewsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 20" {...props}>
      <path d="M10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6L10 0ZM6 17C4.343 17 3 15.657 3 14C3 12.343 4.343 11 6 11V14H9C8.969 15.641 7.657 17 6 17ZM8 12V9C9.641 9.031 11 10.343 11 12H8ZM9 7V1.5L14.5 7H9Z" />
    </SvgIcon>
  );
};

export default QuarterlyNewsIcon;
